import {
  UserAvatar,
  type UserAvatarProps,
} from "@ll-web/components/User/UserAvatar";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";

export type ActiveUserAvatarProps = Omit<UserAvatarProps, "user">;

export const ActiveUserAvatar = ({ ...props }: ActiveUserAvatarProps) => {
  const { activeUser } = useActiveUser();

  if (!activeUser) {
    return null;
  }

  return <UserAvatar {...props} user={activeUser} />;
};
