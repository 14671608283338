import type { ProjectNotesFormValues } from "@ll-web/features/internalProjects/projectForm/notes/projectNotesSchema";
import type { ProjectFormInput } from "@ll-web/features/internalProjects/types";

export const mapNotesFormToDto = ({
  projectNotes,
  videoReferences,
}: Partial<ProjectNotesFormValues>) => {
  return { notes: projectNotes ?? "", videoReferences: videoReferences ?? "" };
};

export const mapNotesDtoToForm = ({
  notes,
  videoReferences,
}: Pick<
  ProjectFormInput,
  "notes" | "videoReferences"
>): ProjectNotesFormValues => {
  return {
    projectNotes: notes ?? "",
    videoReferences: videoReferences ?? "",
  };
};
