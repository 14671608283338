import { Box, Stack, type StackProps } from "@mui/material";

type ProgressStepsProps<T extends string | number> = StackProps & {
  steps: T[];
  currentStep: T;
};

export const ProgressSteps = <T extends string | number>({
  steps,
  currentStep,
  ...stackProps
}: ProgressStepsProps<T>) => {
  const currentStepIndex = steps.indexOf(currentStep);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
      flexWrap="nowrap"
      gap={1}
      {...stackProps}
    >
      {steps.map((key, index) => {
        return (
          <ProgressStep
            key={key}
            activeOrCompleted={index <= currentStepIndex}
          />
        );
      })}
    </Stack>
  );
};

type ProgressStepProps = {
  activeOrCompleted?: boolean;
};

const ProgressStep = ({ activeOrCompleted }: ProgressStepProps) => (
  <Box
    height={4}
    width={32}
    borderRadius={999}
    sx={{
      background: (theme) =>
        activeOrCompleted ? theme.palette.primary.main : theme.palette.divider,
    }}
  />
);
