import { WrappedResponseKey } from "@ll-web/features/llm/const";
import {
  Prompt,
  type PromptModelOptions,
} from "@ll-web/features/llm/prompts/Prompt";
import {
  LlmResponseFormat,
  type AnyJsonOutput,
  type JsonExampleType,
  type JsonSchemaType,
  type MessageType,
  type PromptDumpType,
} from "@ll-web/features/llm/types";
import {
  JsonSchema,
  type OutputJsonSchema,
  type SchemaToDtoOptions,
} from "@ll-web/features/llm/utils/JsonSchema";

export abstract class JsonPrompt<
  OutputType extends AnyJsonOutput = AnyJsonOutput,
  _PayloadType = never, // used for readability and DX
> extends Prompt {
  static readonly format = "json";

  constructor(
    // For consistency, we require all new Json Schemas to be already wrapped in an object with the "response" key
    // If needed, we can remove this constraint by allowing to use the base JsonSchema class as well
    protected schema?:
      | JsonSchemaType<OutputType>
      | OutputJsonSchema<OutputType>,
    protected examples?: JsonExampleType<OutputType>[],
  ) {
    super();
  }

  public isSchemaNewFormat(schema: unknown): schema is JsonSchema<unknown> {
    return schema instanceof JsonSchema;
  }

  public getWrappedResponseKey(): string | undefined {
    if (this.isSchemaNewFormat(this.schema)) {
      return this.schema.wrappedResponseKey;
    }

    if (Array.isArray(this.schema)) {
      return WrappedResponseKey;
    }

    return undefined;
  }

  public getModelOptions({
    schemaToDtoOptions,
  }: { schemaToDtoOptions?: SchemaToDtoOptions } = {}): PromptModelOptions {
    if (this.isSchemaNewFormat(this.schema)) {
      return {
        responseFormat: LlmResponseFormat.JsonSchema,
        jsonSchema: this.schema.toDto(schemaToDtoOptions),
      };
    }

    return {
      responseFormat: LlmResponseFormat.JsonObject,
    };
  }

  public compile(
    messages: MessageType[],
    { includeExamples = true }: { includeExamples?: boolean } = {},
  ) {
    const compiledMessages = structuredClone(messages);

    if (includeExamples && this.examples) {
      compiledMessages.unshift(
        {
          role: "user",
          content: "Give me examples of past correct outputs.",
        },
        ...this.examples.map(
          (example) =>
            ({
              role: "assistant",
              content: this.stringifyJson(example),
            }) satisfies MessageType,
        ),
      );
    }

    let formatInstructionsWithSchema = this.getJsonFormatInstructions();
    // New schema format is passed as a separate parameter and doesn't need to be included in the prompt
    if (!this.isSchemaNewFormat(this.schema) && this.schema) {
      formatInstructionsWithSchema += `\n\n${this.getLegacyJsonSchema(this.schema as Record<string, unknown>)}`;
    }

    const existingSystemPromptIndex = compiledMessages.findIndex(
      (message) => message.role === "system",
    );

    if (existingSystemPromptIndex === -1) {
      compiledMessages.unshift({
        role: "system",
        content: formatInstructionsWithSchema,
      });
    } else {
      const existingSystemPrompt =
        compiledMessages[existingSystemPromptIndex].content;
      compiledMessages.splice(existingSystemPromptIndex, 1);
      compiledMessages.unshift({
        role: "system",
        content: `${existingSystemPrompt}\n\n${formatInstructionsWithSchema}`,
      });
    }

    return {
      messages: compiledMessages,
    };
  }

  protected getLegacyJsonSchema(
    schema: Record<string, unknown> | unknown[],
  ): string {
    return `Follow this schema. Arrays can have more items than specified in the schema. [Schema]\n${this.stringifyJson(
      schema,
    )}\n[End Schema]`;
  }

  protected getJsonFormatInstructions() {
    // No need for this instruction for the new schema format
    if (this.isSchemaNewFormat(this.schema)) {
      return ``;
    }

    // JSON Mode guarantees valid JSON and always uses 4 spaces for indentation
    return `OUTPUT FORMAT: No explanations. Output in JSON.`;
  }

  // Top-level arrays are not supported in JSON mode
  // They will be wrapped in objects and automatically unwrapped in LlmService
  // New schema format is always already wrapped
  protected stringifyJson(json: unknown) {
    const key = this.getWrappedResponseKey();
    const wrappedJson = key ? { [WrappedResponseKey]: json } : json;

    return JSON.stringify(wrappedJson);
  }

  public dump(): PromptDumpType {
    return {
      schema: this.isSchemaNewFormat(this.schema)
        ? this.schema.toDto()
        : this.schema,
    };
  }
}
