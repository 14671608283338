import type { AccountType } from "@ll-web/features/auth/types";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";

import { ReviewNotificationType } from "./types";

type WizardReviewNotificationTypeProps = {
  flowType: WizardFlowType;
  user: { accountType?: AccountType };
  isNewFlowEnabled: boolean;
};

export const wizardReviewNotificationType = ({
  flowType,
  user,
  isNewFlowEnabled,
}: WizardReviewNotificationTypeProps): ReviewNotificationType => {
  switch (flowType) {
    case WizardFlowType.CreativeBrief:
      return ReviewNotificationType.CreativeBriefInternal;
    case WizardFlowType.Generate:
      if (!isNewFlowEnabled && !isUserInternal(user)) {
        return ReviewNotificationType.WizardGenerateExternalLegacy;
      }

      return isUserInternal(user)
        ? ReviewNotificationType.WizardGenerateInternal
        : ReviewNotificationType.WizardGenerateExternal;
    case WizardFlowType.EditCreativeDeck:
      return isUserInternal(user)
        ? ReviewNotificationType.CreativeDeckEditInternal
        : ReviewNotificationType.CreativeDeckEditExternal;
    case WizardFlowType.EditCallSheet:
      return isUserInternal(user)
        ? ReviewNotificationType.CallSheetEditInternal
        : ReviewNotificationType.CallSheetEditExternal;
  }
};
