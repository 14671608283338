import { type ReactNode } from "react";

import { Stack, Typography, type StackProps, type Theme } from "@mui/material";

const variantStyles = {
  dark: {
    background: (theme: Theme) => theme.palette.blue[950],
    color: (theme: Theme) => theme.palette.primary.contrastText,
  },
  light: {
    background: (theme: Theme) => theme.palette.common.white,
    color: (theme: Theme) => theme.palette.text.primary,
    borderRight: (theme: Theme) => `1px solid ${theme.palette.divider}`,
  },
};

type SidebarProps = StackProps & {
  title: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  variant?: "dark" | "light";
};

export const Sidebar = ({
  title,
  subtitle,
  children,
  variant = "dark",
  ...props
}: SidebarProps) => {
  return (
    <Stack
      {...props}
      sx={{
        overflowY: "auto",
        width: "100%",
        maxWidth: [250, 250, 356],
        px: 3,
        py: 7,
        gap: 7,
        ...variantStyles[variant],
        ...props.sx,
      }}
    >
      <Stack gap={1}>
        <Typography variant="h5">{title}</Typography>
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
      </Stack>
      {children}
    </Stack>
  );
};
