import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData } from "@tanstack/react-query";

import { createQueryHook } from "@ll-web/utils/factories/createQueryHook";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";
import { mapUnknownToDate } from "@ll-web/utils/helpers/date";

import { internalProjectsService } from "./InternalProjectsService";

export const InternalProjectsQueries = createQueryKeys("internalProjects", {
  list: createQueryOption(
    internalProjectsService.list.bind(internalProjectsService),
  ),
  findByMsa: createQueryOption(
    internalProjectsService.findByMsa.bind(internalProjectsService),
  ),
});

export const useFindProjectsByMsa = createQueryHook(
  InternalProjectsQueries.findByMsa,
);

export const useInternalListProjects = createQueryHook(
  InternalProjectsQueries.list,
  {
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 1,
    select(data) {
      return {
        ...data,
        items: data.items.map((project) => {
          const productions = project.productions?.map((production) => ({
            ...production,
            dateTime: mapUnknownToDate(production.dateTime),
          }));

          return {
            ...project,
            productions,
          };
        }),
      };
    },
  },
);

export const useFindProjectsByBrandId = createQueryHook(
  InternalProjectsQueries.list,
);
