export const createQueryOption = <TData, TVariables extends object>(
  query: (
    queryArgs: TVariables,
    requestConfig: { signal?: AbortSignal },
  ) => Promise<TData>,
) => {
  return (args: TVariables) => ({
    queryKey: [args] as [TVariables],
    queryFn: (requestConfig: { signal?: AbortSignal }) => {
      return query(args, requestConfig);
    },
  });
};
