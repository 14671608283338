import { useMutation, useQueryClient } from "@tanstack/react-query";
import { merge } from "lodash-es";

import type { UniversalQueryOptions } from "@ll-web/core/api/reactQuerySetup";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { userSettingsService } from "@ll-web/features/auth/userSettings/async/UserSettingsService";
import { UserSettingsQueries } from "@ll-web/features/auth/userSettings/async/useUserSettingsQueries";
import type {
  UpdateUserSettingsArgs,
  UserSettings,
} from "@ll-web/features/auth/userSettings/types";
import { usersService } from "@ll-web/features/users/async/UsersService";
import { UsersQueries } from "@ll-web/features/users/async/useUsersQueries";
import { createMutationHook } from "@ll-web/utils/factories/createMutationHook";

const UserSettingsMutations = {
  updateUserSettings: (userId: UpdateUserSettingsArgs["userId"]) => ({
    mutationFn: (settingsData: UpdateUserSettingsArgs["settingsData"]) => {
      return userSettingsService.updateUserSettingsDocument({
        userId,
        settingsData,
      });
    },
  }),
};

export function useUpdateActiveUserSettings(options?: UniversalQueryOptions) {
  const { activeUser } = useActiveUser();
  const queryClient = useQueryClient();

  return useMutation({
    ...UserSettingsMutations.updateUserSettings(activeUser._id),
    ...options,
    onSuccess(_, updatedData) {
      // update cache as fast as possible
      queryClient.setQueriesData<UserSettings>(
        UserSettingsQueries.getActiveUserSettings({ userId: activeUser._id }),
        (oldData) => {
          return merge({}, oldData ?? {}, updatedData);
        },
      );
      queryClient.invalidateQueries({ queryKey: UserSettingsQueries._def });
    },
  });
}

export const useCreateUser = createMutationHook(
  usersService.create.bind(usersService),
  (queryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: UsersQueries._def,
      });
    },
  }),
);
