import {
  VideoReviewApprovalNotificationMap,
  type VideoReviewNotificationType,
} from "@ll-web/features/postProductionMedia/pages/VideoReview/components/VideoReviewApprovalMenu/useVideoReviewApproveFormData";
import type { CallSheetReviewNotificationType } from "@ll-web/features/projectSpace/pages/CallSheets/callSheetReviewConsts";
import { CallSheetApprovalNotificationMap } from "@ll-web/features/projectSpace/pages/CallSheets/useCallSheetPageReviewFormData";
import { type CreativeDeckReviewNotificationType } from "@ll-web/features/projectSpace/pages/CreativeDeck/creativeDeckReviewConsts";
import { CreativeDeckApprovaNotificationMap } from "@ll-web/features/projectSpace/pages/CreativeDeck/useCreativeDeckPageReviewFormData";
import { defined } from "@ll-web/utils/types/types";

import type { CreationNotificationType, ReviewNotificationType } from "./types";

type MakeReviewNotificationKeyArgs = {
  projectId: string;
  reviewNotificationType:
    | ReviewNotificationType
    | CreationNotificationType.LegacyFlowProjectCreated;
  resourceId?: string;
};

type MakeDocumentNotificationKeyArgs<T> = {
  projectId: string;
  reviewNotificationType: T;
  documentType: "creative-deck" | "call-sheet";
  resourceId?: string;
};

export const makeReviewNotificationKey = ({
  projectId,
  reviewNotificationType,
  resourceId,
}: MakeReviewNotificationKeyArgs): string => {
  return ["project", projectId, reviewNotificationType, resourceId]
    .filter(defined)
    .join(":");
};

export const makeDocumentReviewNotificationKey = ({
  projectId,
  reviewNotificationType,
  documentType,
}: MakeDocumentNotificationKeyArgs<CreativeDeckReviewNotificationType>) => {
  let actionKey = "";

  switch (documentType) {
    case "creative-deck":
      actionKey = CreativeDeckApprovaNotificationMap[reviewNotificationType];
      break;
    case "call-sheet":
      actionKey = CreativeDeckApprovaNotificationMap[reviewNotificationType];
      break;
    default:
      actionKey = "";
  }

  return ["project", projectId, actionKey].filter(defined).join(":");
};

export const makeCallSheetReviewNotificationKey = ({
  projectId,
  reviewNotificationType,
  documentType,
  resourceId,
}: MakeDocumentNotificationKeyArgs<CallSheetReviewNotificationType>) => {
  let actionKey = "";

  switch (documentType) {
    case "creative-deck":
      actionKey = CallSheetApprovalNotificationMap[reviewNotificationType];
      break;
    case "call-sheet":
      actionKey = CallSheetApprovalNotificationMap[reviewNotificationType];
      break;
    default:
      actionKey = "";
  }

  return ["project", projectId, actionKey, resourceId]
    .filter(defined)
    .join(":");
};

type MakeVideoReviewNotificationKeyArgs = {
  projectId: string;
  reviewNotificationType: VideoReviewNotificationType;
  resourceId?: string;
  version?: string;
};

export const makeVideoReviewNotificationKey = ({
  projectId,
  reviewNotificationType,
  resourceId,
  version,
}: MakeVideoReviewNotificationKeyArgs): string => {
  return [
    "project",
    projectId,
    VideoReviewApprovalNotificationMap[reviewNotificationType],
    resourceId,
    version,
  ]
    .filter(defined)
    .join(":");
};

type MakeContractNotificationKeyArgs = {
  contractId: string;
  reviewNotificationType: CreationNotificationType.ContractCreated;
  resourceId?: string;
};

export const makeContractNotificationKey = ({
  contractId,
  reviewNotificationType,
  resourceId,
}: MakeContractNotificationKeyArgs): string => {
  return ["contract", contractId, reviewNotificationType, resourceId]
    .filter(defined)
    .join(":");
};
