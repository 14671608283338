import * as yup from "yup";

import { cutDownSchema } from "@ll-web/features/internalProjects/projectForm/cutDown/cutDownSchema";
import {
  contextProjectDeliverables,
  contextProjectStyle,
} from "@ll-web/features/internalProjects/projectForm/validationContext";
import { InvisibleHeroVideoTypes } from "@ll-web/features/projects/consts/invisibleHeroVideoTypes";
import {
  FootageSourceEnum,
  HeroVideoAudioEnum,
  HeroVideoGraphicsEnum,
  HeroVideoTypeEnum,
  ProjectDeliverablesEnum,
  ProjectStyleEnum,
  PropsTypeEnum,
  WardrobeTypeEnum,
} from "@ll-web/features/projects/enums";

export const heroVideosFields = {
  videoLength: "videoLength",
  videoType: "videoType",
  footageSource: "footageSource", // Curated only
  audio: "audio",
  graphics: "graphics",
  aspectRatioCount: "aspectRatio",
  isInEditMode: "isInEditMode",
  alternateVersions: "alternateVersions",
  cutDowns: "cutDowns",
  // Scripted only
  wardrobeType: "wardrobeType",
  propsType: "propsType",
} as const;

export const heroVideoAlternateVersionSchema = yup.object().shape({
  [heroVideosFields.aspectRatioCount]: yup
    .number()
    .optional()
    .nullable()
    .default(null),
});

export const heroVideoSchema = yup
  .object({
    id: yup.string().optional().default(undefined),
    // In seconds
    [heroVideosFields.videoLength]: yup
      .number()
      .when(contextProjectDeliverables, {
        is: ProjectDeliverablesEnum.RawFootageOnly,
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required(),
      })
      .default(null),
    [heroVideosFields.videoType]: yup.lazy((_value, { context }) => {
      switch (context.projectDeliverables as ProjectDeliverablesEnum) {
        case ProjectDeliverablesEnum.RawFootageOnly:
          return yup
            .string()
            .oneOf(Object.values(HeroVideoTypeEnum))
            .required()
            .default(HeroVideoTypeEnum.RawFootage);
        default:
          return yup
            .string()
            .oneOf(
              Object.values(HeroVideoTypeEnum).filter(
                (videoType) => !InvisibleHeroVideoTypes.includes(videoType),
              ),
            )
            .required()
            .default(null);
      }
    }),
    [heroVideosFields.audio]: yup
      .string<HeroVideoAudioEnum>()
      .when(contextProjectStyle, {
        is: ProjectStyleEnum.DocStyle,
        then: (schema) =>
          schema
            .oneOf([HeroVideoAudioEnum.INTERVIEWS, HeroVideoAudioEnum.MUSIC])
            .when(contextProjectDeliverables, {
              is: ProjectDeliverablesEnum.RawFootageOnly,
              then: (schema) => schema.notRequired().default(null),
              otherwise: (schema) =>
                schema.required().default(HeroVideoAudioEnum.INTERVIEWS),
            }),
      })
      .when(contextProjectStyle, {
        is: ProjectStyleEnum.Curated,
        then: (schema) =>
          schema
            .oneOf([HeroVideoAudioEnum.VOICEOVER, HeroVideoAudioEnum.MUSIC])
            .required()
            .default(HeroVideoAudioEnum.VOICEOVER),
      })
      .when(contextProjectStyle, {
        is: ProjectStyleEnum.Animated,
        then: (schema) =>
          schema
            .oneOf([HeroVideoAudioEnum.VOICEOVER, HeroVideoAudioEnum.MUSIC])
            .required()
            .default(HeroVideoAudioEnum.VOICEOVER),
      })
      .when(contextProjectStyle, {
        is: ProjectStyleEnum.Scripted,
        then: (schema) =>
          schema
            .oneOf([
              HeroVideoAudioEnum.VOICEOVER,
              HeroVideoAudioEnum.DIALOGUE,
              HeroVideoAudioEnum.MIXED,
              HeroVideoAudioEnum.MUSIC,
            ])
            .required()
            .default(HeroVideoAudioEnum.VOICEOVER),
      }),
    [heroVideosFields.graphics]: yup
      .string()
      .oneOf(Object.values(HeroVideoGraphicsEnum))
      .required()
      .when(contextProjectDeliverables, {
        is: ProjectDeliverablesEnum.RawFootageOnly,
        then: (schema) => schema.notRequired().default(null),
        otherwise: (schema) => schema.default(HeroVideoGraphicsEnum.BASIC),
      }),
    [heroVideosFields.aspectRatioCount]: yup
      .number()
      .notRequired()
      .when(contextProjectDeliverables, {
        is: ProjectDeliverablesEnum.RawFootageOnly,
        then: (schema) => schema.notRequired().default(null),
        otherwise: (schema) => schema.required().default(1),
      }),
    [heroVideosFields.isInEditMode]: yup.boolean().default(true),
    [heroVideosFields.alternateVersions]: yup
      .array()
      .of(heroVideoAlternateVersionSchema)
      .optional()
      .default([]),
    [heroVideosFields.cutDowns]: yup
      .array()
      .of(cutDownSchema)
      .required()
      .default([]),
  })
  .when(contextProjectStyle, {
    is: ProjectStyleEnum.Curated,
    then: (schema) =>
      schema.concat(
        yup.object({
          [heroVideosFields.footageSource]: yup
            .array()
            .of(yup.string().oneOf(Object.values(FootageSourceEnum)))
            .min(1)
            .required()
            .default([]),
        }),
      ),
  })
  .when(contextProjectStyle, {
    is: ProjectStyleEnum.Scripted,
    then: (schema) =>
      schema.concat(
        yup.object({
          [heroVideosFields.wardrobeType]: yup
            .string()
            .oneOf(Object.values(WardrobeTypeEnum))
            .nullable()
            .default(null),
          [heroVideosFields.propsType]: yup
            .string()
            .oneOf(Object.values(PropsTypeEnum))
            .nullable()
            .default(null),
        }),
      ),
  });

export type HeroVideoFormValues = yup.InferType<typeof heroVideoSchema> & {
  // Typescript infer doesn't work correctly with conditional schema and strictNullChecks: false
  footageSource?: FootageSourceEnum[]; // Curated only
  // Scripted only
  wardrobeType?: WardrobeTypeEnum;
  propsType?: PropsTypeEnum;
};
