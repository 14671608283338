import { useGetProjectBaseDataById } from "@ll-web/features/projects/async/useProjectsQueries";
import type { ProjectDataWithId } from "@ll-web/features/projects/types";

import { useActiveProjectId } from "./useActiveProject";

export const useActiveProjectBaseData = (): {
  activeProject: ProjectDataWithId;
} => {
  const projectId = useActiveProjectId();
  const query = useGetProjectBaseDataById({
    id: projectId,
  });

  if (!query.isSuccess) {
    throw new Error(
      `Active Project is pending, missing active project id or guard in '${window.location.pathname}'`,
      {
        cause: query.error,
      },
    );
  }

  return { activeProject: query.data };
};
