import { useMemo } from "react";

import { useFormContext } from "react-hook-form";
import type { PartialDeep } from "type-fest";
import * as yup from "yup";

import {
  PROJECT_SCHEMA,
  type ProjectFormValues,
} from "@ll-web/features/internalProjects/projectForm/projectFormSchema";
import { useProjectStyle } from "@ll-web/features/internalProjects/projectForm/utils/useProjectStyle";
import type { ProjectValidationContextType } from "@ll-web/features/internalProjects/projectForm/validationContext";
import { yupExtractOptionsFromSchemaDescription } from "@ll-web/utils/helpers/yup";

import { useProjectDeliverables } from "./useProjectDeliverables";

export function useProjectValidationContext() {
  const projectDeliverables = useProjectDeliverables();
  const projectStyle = useProjectStyle();

  const validationContext = useMemo<ProjectValidationContextType>(() => {
    return { projectDeliverables, projectStyle };
  }, [projectDeliverables, projectStyle]);

  return validationContext;
}

function useProjectFormSchema(path: string = "") {
  const methods = useFormContext<ProjectFormValues>();
  const values = methods.watch();

  const validationContext = useProjectValidationContext();

  const schema = useMemo(() => {
    const schemaPartAtPath = yup.reach(
      PROJECT_SCHEMA,
      path,
      values,
      validationContext,
    );

    return schemaPartAtPath;
  }, [validationContext, values, path]);

  return schema;
}

function useProjectFormSchemaDescription(path: string = "") {
  const methods = useFormContext<ProjectFormValues>();
  const values = methods.watch();

  const validationContext = useProjectValidationContext();

  const schema = useProjectFormSchema(path);

  const schemaDescription = useMemo(
    () =>
      schema.describe({
        value: values,
        context: validationContext,
      }),
    [schema, values, validationContext],
  );

  return schemaDescription;
}

// Extracts `oneOf` options from schema
export function useProjectFormFieldOptions<T>(path: string): T[] {
  const schemaDescription = useProjectFormSchemaDescription(path);

  const options = useMemo<null | T[]>(() => {
    return yupExtractOptionsFromSchemaDescription<T>(schemaDescription);
  }, [schemaDescription]);

  return options ?? [];
}
export function getDefaultFormValues(
  initialValues: PartialDeep<ProjectFormValues> = {},
) {
  const projectDeliverables = initialValues.basicInfo?.deliverables;
  const projectStyle = initialValues.basicInfo?.style;

  const schema = PROJECT_SCHEMA;

  const defaultValues = schema.cast(initialValues, {
    stripUnknown: true,
    // Some default values are set to null
    assert: false,
    context: {
      projectDeliverables,
      projectStyle,
    } satisfies ProjectValidationContextType,
  });

  return defaultValues;
}
