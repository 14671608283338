import { BLOCKING_PHRASES } from "@ll-web/components/NotifyForReviewModal/consts";
import type { ProjectStyleEnum } from "@ll-web/config/featureFlags/consts";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { AccountType, type User } from "@ll-web/features/auth/types";
import {
  useGetBrandById,
  useGetBrandByProjectId,
} from "@ll-web/features/brands/async/useBrandsQueries";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import { ProjectSpacePages } from "@ll-web/features/projectSpace/enums";
import { makeProjectSpacePagePath } from "@ll-web/features/projectSpace/utils/navigation";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { UsersQueries } from "@ll-web/features/users/async/useUsersQueries";
import { signature } from "@ll-web/utils/helpers/emails";
import { useBatchQuery } from "@ll-web/utils/hooks/useBatchQueries";
import { defined } from "@ll-web/utils/types/types";

import { CallSheetReviewNotificationType } from "./callSheetReviewConsts";

enum CallSheetReviewApprovalKey {
  NotifyInternal = "callSheet:notifyInternal",
  Approve = "callSheet:approve",
}

export const CallSheetApprovalNotificationMap: Record<
  CallSheetReviewNotificationType,
  CallSheetReviewApprovalKey
> = {
  [CallSheetReviewNotificationType.ApproveExternal]:
    CallSheetReviewApprovalKey.Approve,
  [CallSheetReviewNotificationType.ApproveInternal]:
    CallSheetReviewApprovalKey.Approve,
  [CallSheetReviewNotificationType.SubmitForReviewExternal]:
    CallSheetReviewApprovalKey.NotifyInternal,
  [CallSheetReviewNotificationType.SubmitForReviewInternal]:
    CallSheetReviewApprovalKey.Approve,
};

export const callSheetExternalEditEmailTemplate = {
  title: ({ projectName }: { projectName: string }) =>
    `Call Sheet edited/submitted for review for ${projectName}`,
  message: ({
    defaultReceiverFirstName,
    projectId,
    senderName,
    projectName,
  }: {
    defaultReceiverFirstName: string;
    projectId: string;
    senderName: string | null;
    projectName: string;
  }) => {
    const callSheetLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}`;

    return `Hi ${defaultReceiverFirstName},

I have reviewed the Call Sheet for ${projectName} and applied some edits/comments to it.
Please take a moment to check the details.

You can view the Call Sheet here: ${callSheetLink}

Thanks${signature(senderName)}`;
  },
};

export const callSheetInternalEditEmailTemplate = {
  title: ({ projectName }: { projectName: string }) =>
    `Great news! Call Sheet for ${projectName} is ready for final approval`,
  message: ({
    defaultReceiverFirstName,
    projectId,
    projectName,
  }: {
    defaultReceiverFirstName: string;
    projectId: string;
    projectName: string;
  }) => {
    const callSheetLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}`;

    return `${defaultReceiverFirstName},

I'm excited to let you know that the Call Sheet for your ${projectName} project is now finalized. Please take a moment to review:

Call Sheet - ${callSheetLink}: Outlines the logistical details of the production day.

You can approve the documents by ${BLOCKING_PHRASES.date.text}. If you have any additional feedback before you approve, you may also leave comments directly in the platform.

Let us know if you have any questions. Looking forward to your feedback!

Best,
Lemonlight`;
  },
};

const notifyReviewEmailDefaults: Record<
  CallSheetReviewNotificationType,
  {
    title: (props: { projectName: string }) => string;
    message: (props: {
      projectName: string;
      projectStyle: ProjectStyleEnum;
      defaultReceiverFirstName: string;
      projectId: string;
      senderName: string | null;
      productionDayId: string;
    }) => string;
  }
> = {
  [CallSheetReviewNotificationType.ApproveExternal]: {
    title: ({ projectName }) => `Call Sheet approved for ${projectName}`,
    message: ({
      defaultReceiverFirstName,
      projectId,
      senderName,
      productionDayId,
      projectName,
    }) => {
      const callSheetLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}?productionDayId=${productionDayId}`;

      return `Hi ${defaultReceiverFirstName},

I've approved the Call Sheet for ${projectName}.
You can view it here: ${callSheetLink}.

We can proceed with the next steps as planned.

Thank you!
${senderName}`;
    },
  },
  [CallSheetReviewNotificationType.SubmitForReviewExternal]:
    callSheetExternalEditEmailTemplate,
  [CallSheetReviewNotificationType.ApproveInternal]: {
    title: ({ projectName }) =>
      `Call Sheet for ${projectName} project is Approved on Your Behalf`,
    message: ({
      defaultReceiverFirstName,
      projectId,
      senderName,
      projectName,
      productionDayId,
    }) => {
      const callSheetLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}?productionDayId=${productionDayId}`;

      return `Hi ${defaultReceiverFirstName},

The Call Sheet for the ${projectName} project has been approved on your behalf.

You can now view the final version of the Call Sheet here: ${callSheetLink}

Thanks,
${senderName}`;
    },
  },
  [CallSheetReviewNotificationType.SubmitForReviewInternal]:
    callSheetInternalEditEmailTemplate,
};

const getDefaultReceiverForNotificationType = {
  [CallSheetReviewNotificationType.ApproveExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [CallSheetReviewNotificationType.SubmitForReviewExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [CallSheetReviewNotificationType.ApproveInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [CallSheetReviewNotificationType.SubmitForReviewInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
};

export const useCallSheetPageReviewFormData = (
  notificationType: CallSheetReviewNotificationType,
  productionDayId: string,
): {
  people: string[];
  title: string;
  message: string;
  defaultAutocompleteUsers: {
    isFetching: boolean;
    data: User[];
  };
} => {
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();

  const brandByProjectQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });

  const brandId = brandByProjectQuery.data?._id;
  const brandByIdQuery = useGetBrandById(
    {
      brandId: brandId!,
    },
    { enabled: !!brandId },
  );

  const projectTeam =
    brandByIdQuery?.data?.team?.filter((teamMember) => !!teamMember.user) ?? [];

  const defaultReceivers =
    getDefaultReceiverForNotificationType[notificationType](projectTeam);

  const defaultReceiversIds = defaultReceivers
    .map((receiverProfile) => receiverProfile.user?._id)
    .filter(defined);

  const defaultUsersQuery = useBatchQuery(
    defaultReceiversIds.map((userId) => UsersQueries.getById({ userId })),
  );

  return {
    people: defaultReceiversIds ?? [],
    message: notifyReviewEmailDefaults[notificationType].message({
      projectName: activeProject.title,
      senderName: `${activeUser.firstName} ${activeUser.lastName}`,
      defaultReceiverFirstName:
        defaultReceivers.find(
          (userData) => userData?.user?._id === defaultReceivers[0].user?._id,
        )?.user?.firstName ?? "",
      projectId: activeProject.id,
      projectStyle: activeProject.style,
      productionDayId,
    }),
    title: notifyReviewEmailDefaults[notificationType].title({
      projectName: activeProject.title,
    }),
    defaultAutocompleteUsers: {
      isFetching: defaultUsersQuery.isFetching,
      data: defaultUsersQuery.data?.filter(defined) ?? [],
    },
  };
};
