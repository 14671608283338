import { type PropsWithChildren } from "react";

import { FormProvider } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";

import type { ProjectFormPageState } from "@ll-web/features/internalProjects/pages/ProjectForm/types";
import type { ProjectFormValues } from "@ll-web/features/internalProjects/projectForm/projectFormSchema";
import { useProjectForm } from "@ll-web/features/internalProjects/projectForm/utils/useProjectForm";

type ProjectFormRouteProviderProps = PropsWithChildren<{
  passedInitialValues?: ProjectFormValues;
}>;

export const ProjectFormRouteProvider = ({
  passedInitialValues,
  children,
}: ProjectFormRouteProviderProps) => {
  const [searchParams] = useSearchParams();
  const passedMsa = searchParams.get("msa");

  const passedValues = (useLocation().state ?? {}) as ProjectFormPageState;

  const initialValues = passedInitialValues || {
    ...passedValues,
    basicInfo: {
      ...passedValues.basicInfo,
      ...(passedMsa ? { msa: passedMsa } : {}),
    },
  };
  const { methods } = useProjectForm(initialValues);

  return <FormProvider {...methods}>{children}</FormProvider>;
};
