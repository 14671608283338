import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  type DialogProps,
} from "@mui/material";

import { Spinner } from "@ll-web/components/Spinner/Spinner";
import type { User } from "@ll-web/features/auth/types";
import type { VideoReviewNotificationType } from "@ll-web/features/postProductionMedia/pages/VideoReview/components/VideoReviewApprovalMenu/useVideoReviewApproveFormData";
import type { CallSheetReviewNotificationType } from "@ll-web/features/projectSpace/pages/CallSheets/callSheetReviewConsts";
import type { CreativeDeckReviewNotificationType } from "@ll-web/features/projectSpace/pages/CreativeDeck/creativeDeckReviewConsts";

import { notifyReviewModalTitleMap } from "./consts";
import type { NotifyForReviewFormValues } from "./notify-for-review.schema";
import { NotifyModalForm } from "./NotifyModalForm";
import { ReviewNotificationType, type CreationNotificationType } from "./types";

export type NotifyForReviewModalProps = DialogProps & {
  notificationType:
    | ReviewNotificationType
    | CreativeDeckReviewNotificationType
    | CallSheetReviewNotificationType
    | VideoReviewNotificationType
    | CreationNotificationType;
  close: () => void;
  onSubmitForReview: (payload: NotifyForReviewFormValues) => Promise<void>;
  submitButtonContent?: string;
  isLoading: boolean;
  formData: NotifyForReviewFormValues;
  defaultReceiversData: User[];
};

export const NotifyForReviewModal = ({
  submitButtonContent = "Submit for Review",
  formData,
  notificationType,
  close,
  onSubmitForReview,
  isLoading,
  defaultReceiversData,
  ...dialogProps
}: NotifyForReviewModalProps) => {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...dialogProps}
      PaperProps={{ sx: { minHeight: 600 } }}
    >
      <DialogTitle sx={{ py: 2, px: 3 }}>
        {notifyReviewModalTitleMap[notificationType]}
      </DialogTitle>
      {notificationType === ReviewNotificationType.CreativeBriefInternal && (
        <Alert severity="warning" sx={{ mx: 3 }}>
          You are about to submit the creative input for the producer’s review.
          Before submitting, please review all details with the client during
          your Account Setup Call.
        </Alert>
      )}
      <DialogContent
        sx={{
          py: 0,
        }}
      >
        {isLoading ? (
          <Stack
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            height="100%"
            width="100%"
          >
            <Spinner size={64} />
          </Stack>
        ) : (
          <NotifyModalForm
            defaultReceiversData={defaultReceiversData}
            formData={formData}
            notificationType={notificationType}
            onSubmitForReview={onSubmitForReview}
            submitButtonContent={submitButtonContent}
            onClose={close}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
