import type { SxProps, Theme } from "@mui/material";

const trackbarContainerSelector = ".vds-controls-group:nth-last-of-type(2)";
const bottomControlsSelector = ".vds-controls-group:nth-last-of-type(1)";

export const commentsStyleOverrides: SxProps<Theme> = {
  [trackbarContainerSelector]: {
    flexDirection: "column",
  },
  [bottomControlsSelector]: {
    // show menus above comment avatars
    zIndex: `11 !important`,
  },
  ".vds-time-slider": {
    marginBottom: "12px",
    "--media-slider-height": "12px",
  },
};
