import { useParams, useSearchParams } from "react-router-dom";

import { useGetProjectById } from "@ll-web/features/projects/async/useProjectsQueries";
import type { ProjectWithDeliverables } from "@ll-web/features/projects/types";
import { assertDefined } from "@ll-web/utils/types/types";

export const useActiveProject = (): {
  activeProject: ProjectWithDeliverables;
} => {
  const projectId = useActiveProjectId();
  const query = useGetProjectById({
    id: projectId,
  });

  if (!query.isSuccess) {
    throw new Error(
      `Active Project is pending, missing active project id or guard in '${window.location.pathname}'`,
      {
        cause: query.error,
      },
    );
  }

  return { activeProject: query.data };
};

export const useActiveProjectId = (): string => {
  const pathParams = useParams();
  const [queryParams] = useSearchParams();

  const projectId = pathParams.id ?? queryParams.get("projectId");
  assertDefined(projectId, "Missing active project id in path or query");

  return projectId;
};
