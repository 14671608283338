import type { SxProps, Theme } from "@mui/material";

const bottomControlsHeight = "92px";

export const controlsBelowPlayerStyleOverrides: SxProps<Theme> = {
  ":not([data-fullscreen])": {
    // trackbar and bottom controls
    ".vds-controls": {
      // hide gradient overlay
      background: "none",
      // always show controls, even while idle
      opacity: 1,
      visibility: "visible",
    },
    // make room for controls outside of the player
    paddingBottom: bottomControlsHeight,
  },
};
