import { Box } from "@mui/material";

import { Spinner } from "@ll-web/components/Spinner/Spinner";

function SuspenseLoader() {
  return (
    <Box
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner size={64} thickness={3} />
    </Box>
  );
}

export default SuspenseLoader;
