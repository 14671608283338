export enum ProjectSpacePages {
  Overview = "overview",
  Timeline = "timeline",
  ClientAssets = "client-assets",
  Intake = "intake",
  DeliverableSummary = "deliverable-summary",
  CreativeDeck = "creative-deck",
  CallSheets = "call-sheets",
  DeliverableVideos = "deliverable-videos",
}

export enum ProjectSpaceLinks {
  CreativeBriefInput = "creative-brief-input",
}
