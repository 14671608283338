import { type ReactNode } from "react";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useCreator } from "@ll-web/creator/auth/hooks/useCreator";
import { AuthRedirectController } from "@ll-web/creator/auth/pages/AuthRedirectController";

type IsLoggedInGuardProps = {
  children: ReactNode;
};

export const IsLoggedInGuard = ({ children }: IsLoggedInGuardProps) => {
  const { isLoading, isAuthorized } = useCreator();

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (!isAuthorized) {
    return <AuthRedirectController />;
  }

  return children;
};
