import { useMemo } from "react";

import { Avatar, type AvatarProps } from "@mui/material";

import type { Creator } from "@ll-web/creator/auth/types";
import { getPersonInitials } from "@ll-web/utils/helpers/helpers";
import {
  isUploadcareUrl,
  scaleUploadcareUrl,
} from "@ll-web/utils/helpers/uploadcare";

export type CreatorAvatarProps = AvatarProps & {
  creator: Creator | null | undefined;
  size?: number;
};

export const CreatorAvatar = ({
  creator,
  size = 32,
  ...rest
}: CreatorAvatarProps) => {
  const avatarUrl = useMemo<string | null>(() => {
    const url = creator?.avatar?.url;
    if (!url) {
      return null;
    }

    if (isUploadcareUrl(url)) {
      return scaleUploadcareUrl(url, size * 3);
    }

    return url;
  }, [creator, size]);

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        background: (theme) => theme.palette.avatarFill,
        color: (theme) => theme.palette.primary.main,
        fontWeight: 600,
        fontSize: Math.min(size / 2.25, 24),
        cursor: rest.onClick ? "pointer" : "default",
      }}
      color="primary"
      src={avatarUrl ?? undefined}
      slotProps={{
        img: {
          draggable: false,
        },
      }}
      {...rest}
    >
      {getPersonInitials(creator)}
    </Avatar>
  );
};
