import type { SetNonNullable } from "type-fest";

import { useGetDeliverableByKey } from "@ll-web/features/postProductionMedia/async/usePostProductionMediaQueries";
import { useDeliverablePathParams } from "@ll-web/features/postProductionMedia/hooks/useDeliverablePathParams";
import { type DeliverableVersion } from "@ll-web/features/postProductionMedia/types";

type ReturnType = {
  deliverable: DeliverableVersion | undefined;
  versions: DeliverableVersion[] | undefined;
  latestVersion: DeliverableVersion | undefined;
  isLoading: boolean;
};
type DefinedReturnType = SetNonNullable<ReturnType, keyof ReturnType>;

export function useActiveDeliverable<T extends boolean = true>({
  guard = true as T,
}: {
  guard?: T;
} = {}): T extends true ? DefinedReturnType : ReturnType {
  const params = useDeliverablePathParams();
  const query = useGetDeliverableByKey({
    projectId: params.projectId,
    deliverableKey: params.deliverableKey,
  });

  const selectedDeliverable =
    query.data?.versions.find(
      (deliverable) => deliverable.versionName === params.versionName,
    ) ?? query.data?.latestVersion;

  if (guard) {
    if (!query.isSuccess) {
      throw new Error(
        "Active deliverable query is not successful. You are missing a guard",
      );
    }

    return {
      deliverable: selectedDeliverable!,
      versions: query.data.versions,
      latestVersion: query.data.latestVersion,
      isLoading: false,
    };
  }

  return {
    deliverable: selectedDeliverable,
    versions: query.data?.versions,
    latestVersion: query.data?.latestVersion,
    isLoading: query.isPending,
  } as DefinedReturnType; // fix for typescript
}
