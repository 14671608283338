import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, Stack, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { SmallWhiteTextField } from "@ll-web/components/form/SmallWhiteTextField/SmallWhiteTextField";
import type { User } from "@ll-web/features/auth/types";
import type { VideoReviewNotificationType } from "@ll-web/features/postProductionMedia/pages/VideoReview/components/VideoReviewApprovalMenu/useVideoReviewApproveFormData";
import type { CallSheetReviewNotificationType } from "@ll-web/features/projectSpace/pages/CallSheets/callSheetReviewConsts";
import type { CreativeDeckReviewNotificationType } from "@ll-web/features/projectSpace/pages/CreativeDeck/creativeDeckReviewConsts";

import { notifyReviewModalContentMap } from "./consts";
import { MultiUserAutocomplete } from "./MultiUserAutocomplete";
import {
  notifyForReviewSchema,
  type NotifyForReviewFormValues,
} from "./notify-for-review.schema";
import type { CreationNotificationType, ReviewNotificationType } from "./types";

type NotifyModalFormProps = {
  defaultReceiversData: User[];
  notificationType:
    | ReviewNotificationType
    | CreativeDeckReviewNotificationType
    | CallSheetReviewNotificationType
    | VideoReviewNotificationType
    | CreationNotificationType;
  onSubmitForReview: (payload: NotifyForReviewFormValues) => Promise<void>;
  formData: {
    title: string;
    message: string;
    people: string[];
  };
  submitButtonContent: string;
  onClose: () => void;
};

export const NotifyModalForm = ({
  defaultReceiversData,
  notificationType,
  onSubmitForReview,
  formData,
  submitButtonContent,
  onClose,
}: NotifyModalFormProps) => {
  const { mutateAsync: doSubmit, isPending } = useMutation({
    mutationFn: onSubmitForReview,
    meta: {
      supressErrorToast: true,
    },
  });

  const methods = useForm<NotifyForReviewFormValues>({
    defaultValues: formData,
    resolver: yupResolver(notifyForReviewSchema),
    mode: "onBlur",
  });

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const handleSubmit = (data: NotifyForReviewFormValues) => {
    doSubmit(data);
  };

  return (
    <Stack component="form" onSubmit={methods.handleSubmit(handleSubmit)}>
      <FormProvider {...methods}>
        <Stack
          sx={{
            flexGrow: 1,
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography>
            {notifyReviewModalContentMap[notificationType]}
          </Typography>
          <MultiUserAutocomplete
            label="To"
            defaultReceiversData={defaultReceiversData}
          />
          <Controller
            control={methods.control}
            name="title"
            render={({ field: { ref: _, ...field }, fieldState }) => (
              <SmallWhiteTextField
                {...field}
                error={fieldState.error?.message}
                size="medium"
                label="Title"
              />
            )}
          />
          <Controller
            control={methods.control}
            name="message"
            render={({ field: { ref: _, ...field }, fieldState }) => (
              <SmallWhiteTextField
                {...field}
                error={fieldState.error?.message}
                size="medium"
                multiline
                label="Message"
              />
            )}
          />
        </Stack>
      </FormProvider>
      <DialogActions sx={{ py: 3, px: 0 }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color="inherit"
          disabled={isPending}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isPending}
        >
          {submitButtonContent}
        </Button>
      </DialogActions>
    </Stack>
  );
};
