import { type ProductionDayFormValues } from "@ll-web/features/internalProjects/projectForm/productionDays/productionDaysSchema";
import type { ProjectProductionDay } from "@ll-web/features/projects/types";
import { mapUnknownToDayjs } from "@ll-web/utils/helpers/date";
import { defined } from "@ll-web/utils/types/types";

export const mapProductionDayFormToDto = ({
  id,
  crewMembers,
  productionLength,
  tentativeCity,
  dateTime,
  isTimeSet,
  locationType,
}: ProductionDayFormValues): ProjectProductionDay => {
  return {
    id: id!,
    address: tentativeCity,
    crew: crewMembers.filter(defined),
    duration: productionLength,
    locationType,
    dateTime: dateTime?.toDate() ?? null,
    isTimeSet,
  };
};

export const mapProductionDaysDtoToForm = ({
  id,
  address,
  crew,
  duration,
  dateTime,
  isTimeSet,
  locationType,
  scheduleStatus,
}: ProjectProductionDay): ProductionDayFormValues => ({
  id,
  crewMembers: crew ?? [],
  productionLength: duration,
  tentativeCity:
    typeof address === "string" ? { description: address } : address ?? null,
  dateTime: mapUnknownToDayjs(dateTime),
  isTimeSet,
  locationType,
  scheduleStatus,
  isInEditMode: false,
});
