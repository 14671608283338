import { CustomStepper } from "@ll-web/components/CustomStepper/CustomStepper";
import { useProjectEmpty } from "@ll-web/features/internalProjects/pages/ProjectForm/hooks/useProjectFormEmpty";
import { useProjectFormRouteNavigation } from "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/hooks/useProjectFormRouteNavigation";

export const ProjectFormRouteStepper = () => {
  const { steps, currentStepIndex, goToStep, stepperSteps } =
    useProjectFormRouteNavigation();
  const isEmpty = useProjectEmpty();
  const stepIndex = isEmpty ? 0 : currentStepIndex;
  const activeStep = steps[stepIndex];

  return (
    <CustomStepper
      steps={stepperSteps}
      activeStep={activeStep}
      onClick={(step) => goToStep(step.key)}
      variant="light"
    />
  );
};
