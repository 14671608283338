import type { CharactersOutput } from "@ll-web/features/llm/prompts/Characters/types";
import type { InterviewsOutput } from "@ll-web/features/llm/prompts/Interviews/types";
import type { ScheduleOutput } from "@ll-web/features/llm/prompts/Schedule/types";
import type {
  ScriptedFrame,
  ScriptedScriptOutput,
} from "@ll-web/features/llm/prompts/ScriptedScript/types";
import type { ScriptedVideoSummaryOutput } from "@ll-web/features/llm/prompts/ScriptedVideoSummary/types";
import type { VideoSummaryOutput } from "@ll-web/features/llm/prompts/VideoSummary/types";
import type { VisualsOutput } from "@ll-web/features/llm/prompts/Visuals/types";
import type { VoiceoverOutput } from "@ll-web/features/llm/prompts/Voiceover/types";
import type { MessageType, PromptDumpType } from "@ll-web/features/llm/types";
import type { ScriptedStoryboardVisualStyleEnum } from "@ll-web/features/projects/enums";
import type { ProjectCharacter } from "@ll-web/features/projects/types";
import type { TtsOptions } from "@ll-web/features/tts/types";
import type { $Deprecated, UnknownDate } from "@ll-web/utils/types/types";

export type ProjectIdParams = {
  projectId: string;
};

export type ProjectIdAndVideoIdsParams = {
  projectId: string;
  videoIds: string[];
};

export type ProjectIdAndVideoIdParams = {
  projectId: string;
  videoId: string;
};

export type ProjectCharacterAndVideoIdParams = {
  projectId: string;
  characterId: string;
  videoId: string;
};

export type ProjectIdAndInterviewIdParams = {
  projectId: string;
  interviewId: string;
};

export type ProjectIdAndProductionDayIdParams = {
  projectId: string;
  productionDayId: string;
};

export type ProjectIdAndScheduleIdAndProductionDayIdParams = {
  projectId: string;
  scheduleId: string;
  productionDayId: string;
};

export type StoryboardFrameWithImage = ScriptedFrame & {
  imageUrl?: string;
  isLoading?: boolean;
  isServerError?: boolean;
  isClientError?: boolean;
};

export type OverrideStoryboardImagePayload = {
  projectId: string;
  scriptId: string;
  videoId: string;
  imageUrl: string;
  frameNumber: string;
  visualStyle: ScriptedStoryboardVisualStyleEnum;
};

export type StoryboardImageResponse = {
  imageUrl: string;
};

export type CharacterCombinedDescriptionParams = {
  projectId: string;
  character: ProjectCharacter;
};

export type LookAndFeelImagePayload = {
  projectId: string;
  videoId: string;
  videoSummaryOutputId: string;
  prompt: string;
  regenerate?: boolean;
};

type AiImageDto = {
  imageId: string;
  imageUrl: string;
  seed: number;
  hash: string;
};

export type LookAndFeelImageResponse = AiImageDto;

export type WardrobeImagePayload = {
  projectId: string;
  videoId: string;
  characterSummaryOutputId: string;
  prompt: string;
  regenerate?: boolean;
};

export type WardrobeImageResponse = AiImageDto;

export type CreateReviewDto = {
  reviewKey: string;
  brandId: string;
  isPingOnly?: true;
  email: {
    title: string;
    body: string;
    recipientUserIds: string[];
  };
};

export type CreateApprovalDto = {
  reviewKey: string;
  brandId: string;
};

export enum ReviewStatus {
  Requested = "requested",
  Approved = "approved",
  Revoked = "revoked",
  Pinged = "pinged",
}

export type ReviewResourceResponse = {
  id: string;
  reviewKey: string;
  brandId: string;
  status: ReviewStatus;
  approvedBy: string;
  approvedAt: UnknownDate;
  requestedAt: UnknownDate;
  requestedBy: string;
};

export type InterviewInfo = {
  id?: string;
  order: number;
  fullName?: string;
  description?: string;
  messages?: string;
  createdAt: Date;
};

export type AddInterviewArgs = {
  projectId: string;
} & Omit<InterviewInfo, "createdAt">;

export type UpdateInterviewArgs = ProjectIdAndInterviewIdParams & {
  updatePayload: Partial<InterviewInfo>;
};

export type VoiceoverInput = {
  takeaways: string[];
  ttsOptions?: TtsOptions;
};

export type VisualsInputs = {
  takeaways: string[];
};

export type GetReviewArgs = {
  reviewKey: string;
};

export type UpdateVoiceoverInputArgs = ProjectIdAndVideoIdParams &
  Partial<VoiceoverInput>;

export type UpdateVisualsInputsArgs = ProjectIdAndVideoIdParams &
  Partial<VisualsInputs>;

export type AddCharacterArgs = ProjectIdParams & Omit<ProjectCharacter, "id">;

export type UpdateCharacterArgs = Pick<ProjectCharacter, "id"> &
  ProjectIdParams &
  Partial<ProjectCharacter>;

export type RemoveCharacterArgs = Pick<ProjectCharacter, "id"> &
  ProjectIdParams;

export type GetVoiceoverOutputByIdArgs = ProjectIdParams & {
  voiceoverOutputId: string;
};

export enum ProjectAiOutputSubcollections {
  VideoSummary = "videoSummaryOutput",
  Interviews = "interviewsOutput",
  Schedule = "scheduleOutput",
  Voiceover = "voiceoverOutput",
  Visuals = "visualsOutput",
  ScriptedScript = "scriptedScriptOutput",
  Characters = "charactersOutput",
}

export enum OutputArchiveSourceEnum {
  AI = "ai",
  AIWithFeedback = "aiWithFeedback",
  ManualEdit = "manualEdit",
  DependencyUpdate = "dependencyUpdate",
}

type AIOutputArchiveDebugInfo = {
  messages?: MessageType[] | null;
  promptDump?: PromptDumpType;
};

export type OutputArchive<T> = {
  // Automatically generated document id
  id?: string;
  // WARN: Source may be undefined for archives before v1.17.0
  source: OutputArchiveSourceEnum;
  createdAt: Date;
  // Author user id (deprected: null if generated by AI)
  author: string | $Deprecated<null>;
  output: T;
  // Id of the previous archive if it "branched" from existing output using feedback
  // Null if it was generated from scratch
  previousId?: string | null;
  // Any additional metadata
  misc?: unknown;
  // Id of the output archive entry it was restored from
  restoredFromId?: string | null;
} & AIOutputArchiveDebugInfo;

export type VideoSummaryOutputArchive = OutputArchive<VideoSummaryOutput> & {
  videoId: string;
};

export type ScriptedVideoSummaryOutputArchive =
  OutputArchive<ScriptedVideoSummaryOutput> & {
    videoId: string;
  };

export type InterviewsOutputArchive = OutputArchive<InterviewsOutput> & {
  interviewId: string;
};

export type ScheduleOutputArchive = OutputArchive<ScheduleOutput> & {
  productionDayId: string;
};

export type VoiceoverOutputArchive = OutputArchive<VoiceoverOutput> & {
  videoId: string;
};

export type VisualsOutputArchive = OutputArchive<VisualsOutput> & {
  videoId: string;
  voiceoverOutputId?: string;
};

export type CharactersOutputArchive = OutputArchive<CharactersOutput> & {
  videoId: string;
  characterId: string;
  videoSummaryOutputId: string;
};

export type ScriptedScriptOutputArchive =
  OutputArchive<ScriptedScriptOutput> & {
    videoId: string;
  };

export type AnyOutput =
  | VideoSummaryOutput
  | ScriptedVideoSummaryOutput
  | InterviewsOutput
  | ScheduleOutput
  | VoiceoverOutput
  | VisualsOutput
  | ScriptedScriptOutput
  | CharactersOutput;

export type UpdatedOutputArchivePayload<T extends OutputArchive<AnyOutput>> =
  Pick<T, "id" | "output">;
