import { Navigate } from "react-router-dom";

import { Pages } from "@ll-web/core/router/pages";
import { useGetActiveUserSettings } from "@ll-web/features/auth/userSettings/async/useUserSettingsQueries";

export const InternalDashboardRedirectController = () => {
  const { data: userSettings } = useGetActiveUserSettings();

  return (
    <Navigate
      to={userSettings.internalDashboardDefaultPage ?? Pages.InternalProjects}
      replace
    />
  );
};
