import { useEffect, type PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { useGetProjectById } from "@ll-web/features/projects/async/useProjectsQueries";
import { useUpdateClientFirstViewedAtMutation } from "@ll-web/features/projectWizard/async/useProjectWizardMutations";
import Status404 from "@ll-web/features/statusPages/pages/Status404";

const ActiveProjectContainer = ({ children }: PropsWithChildren) => {
  const { id } = useParams();
  const { activeUser } = useActiveUser();

  const { data: activeProject, isPending } = useGetProjectById({ id });
  const { mutateAsync: updateClientFirstViewedAtMutateAsync } =
    useUpdateClientFirstViewedAtMutation();

  // analytics
  useEffect(() => {
    if (!activeProject) {
      return;
    }

    if (!activeProject.clientFirstViewedAt && !isUserInternal(activeUser)) {
      updateClientFirstViewedAtMutateAsync({
        projectId: activeProject.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUser, activeProject]);

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (!activeProject) {
    return <Status404 />;
  }

  return children;
};

export default ActiveProjectContainer;
