import type { ReactNode } from "react";

import { NavigationBar } from "@ll-web/components/NavigationBar";

type NavigationBarLayoutProps = {
  children: ReactNode;
};

export const NavigationBarLayout = ({ children }: NavigationBarLayoutProps) => {
  return (
    <>
      <NavigationBar />
      {children}
    </>
  );
};
