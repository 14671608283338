import { ProjectStyleEnum } from "@ll-web/features/projects/enums";

export const ProjectStyleCreativeBriefEnabledMap: Record<
  ProjectStyleEnum,
  boolean
> = {
  [ProjectStyleEnum.DocStyle]: true,
  [ProjectStyleEnum.Curated]: true,
  [ProjectStyleEnum.Animated]: true,
  [ProjectStyleEnum.Scripted]: false,
};
