import { Navigate } from "react-router-dom";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useCreator } from "@ll-web/creator/auth/hooks/useCreator";
import { CreatorPages } from "@ll-web/creator/pages";

export function AuthRedirectController() {
  const { isLoading, isAuthorized } = useCreator();

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (!isAuthorized) {
    return <Navigate to={CreatorPages.Login} replace />;
  }

  return <Navigate to={CreatorPages.CreatorDashboard} replace />;
}
