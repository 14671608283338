import { useState } from "react";

import {
  ActiveCreatorAvatar,
  type ActiveCreatorAvatarProps,
} from "@ll-web/creator/components/ActiveCreator/ActiveCreatorAvatar";
import {
  ActiveCreatorMenu,
  type ActiveCreatorMenuProps,
} from "@ll-web/creator/components/ActiveCreator/ActiveCreatorMenu";

type ActiveCreatorWithMenuProps = {
  avatarProps?: ActiveCreatorAvatarProps;
  menuProps?: ActiveCreatorMenuProps;
};

export const ActiveCreatorWithMenu = ({
  avatarProps,
  menuProps,
}: ActiveCreatorWithMenuProps) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  return (
    <>
      <ActiveCreatorAvatar
        size={40}
        {...avatarProps}
        onClick={(event) =>
          setAnchor((current) => (current ? null : event.currentTarget))
        }
      />
      <ActiveCreatorMenu
        {...menuProps}
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      />
    </>
  );
};
