import { useCallback, useMemo } from "react";

import { toast } from "react-toastify";

import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { useSubmitCreativeDeckEditMutation } from "@ll-web/features/projects/async/useProjectsMutations";
import { ProjectStyleCreativeBriefEnabledMap } from "@ll-web/features/projects/consts/projectStyleCreativeBriefEnabledMap";
import {
  useWizardNavigationContext,
  WizardFlowType,
} from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { useNavigateBackFromWizard } from "@ll-web/features/projectWizard/hooks/useWizardNavigationUtils";
import { wait } from "@ll-web/utils/helpers/helpers";
import { useEffectOnce } from "@ll-web/utils/hooks/useStrictModeEffect";
import { assertDefined } from "@ll-web/utils/types/types";

export const useFinalizeEdit = () => {
  const { flowType, setReasonForBlockedFinalize, setReasonForBlockedNextStep } =
    useWizardNavigationContext();
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();
  const { navigateBack, isFromState } = useNavigateBackFromWizard();
  const isInternalUser = isUserInternal(activeUser);
  const {
    mutateAsync: mutateSubmitScriptedCreativeDeckEditAsync,
    isPending,
    isSuccess,
  } = useSubmitCreativeDeckEditMutation();

  const finalizeEdit = useCallback(
    async (finalizeCallback?: () => Promise<void>) => {
      if (ProjectStyleCreativeBriefEnabledMap[activeProject.style]) {
        assertDefined(finalizeCallback);
        await finalizeCallback();
      } else {
        await mutateSubmitScriptedCreativeDeckEditAsync({
          projectId: activeProject.id,
        });
      }

      setReasonForBlockedNextStep(null);
      setReasonForBlockedFinalize(null);
      toast.success(
        `Changes successfully saved and ${isInternalUser ? "client" : "producer"} notified.`,
      );
      if (isFromState) {
        // Wait for success state to show and unblock the route blocker
        await wait(300);
        navigateBack();
      }
    },
    [
      activeProject.id,
      activeProject.style,
      isFromState,
      isInternalUser,
      mutateSubmitScriptedCreativeDeckEditAsync,
      navigateBack,
      setReasonForBlockedFinalize,
      setReasonForBlockedNextStep,
    ],
  );

  useEffectOnce(() => {
    if (
      [WizardFlowType.EditCreativeDeck, WizardFlowType.EditCallSheet].includes(
        flowType,
      )
    ) {
      setReasonForBlockedFinalize("You must submit the edits for review");
    }
  });

  return useMemo(
    () => ({ finalizeEdit, isPending, isSuccess }),
    [finalizeEdit, isPending, isSuccess],
  );
};
