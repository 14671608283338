import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";
import { isObject } from "lodash-es";

import { mapUnknownToDate } from "@ll-web/utils/helpers/date";
import type { $TSFixMe } from "@ll-web/utils/types/types";

export const dataWithIdConverterFactory = <T>() => ({
  toFirestore: (data: T) => data,
  fromFirestore(snap: QueryDocumentSnapshot<T>) {
    const data = snap.data();

    return {
      ...snap.data(),
      id: snap.id,
      ...(typeof data === "object" && data !== null && "createdAt" in data
        ? { createdAt: mapUnknownToDate(data.createdAt as Timestamp) }
        : {}),
    } as T & { id: string };
  },
});

// Maps document id and converts Timestamps to Date
export const defaultFirestoreConverterFactory = <
  T,
  P = Partial<Record<keyof T, unknown>>,
>(converter?: {
  toFirestore?: (data: T) => WithFieldValue<P>;
  fromFirestore?: (data: P & { id: string }) => T;
}) =>
  ({
    toFirestore: (data: T) => {
      if (converter?.toFirestore) {
        return converter.toFirestore(data);
      }

      return data;
    },
    fromFirestore(snap: QueryDocumentSnapshot<T>) {
      const data = snap.data();
      if (!isObject(data)) {
        return data;
      }

      const convertedData = Object.fromEntries(
        Object.entries(data).map(([key, value]) => {
          if (
            value &&
            typeof value === "object" &&
            "toDate" in value &&
            typeof value.toDate === "function"
          ) {
            return [key, value.toDate()];
          }

          return [key, value];
        }),
      );

      const toReturn = { ...convertedData, id: snap.id } as T & { id: string };

      if (converter?.fromFirestore) {
        return converter.fromFirestore(toReturn as $TSFixMe);
      }

      return toReturn;
    },
  }) as FirestoreDataConverter<T>;
