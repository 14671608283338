import { useCallback } from "react";

import { type SxProps, type Theme } from "@mui/material";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { CommentThread } from "@ll-web/features/projectComments/components/CommentThread";
import { useCommentNumberRegister } from "@ll-web/features/projectComments/contexts/CommentNumberRegisterProvider";
import { useProjectComments } from "@ll-web/features/projectComments/hooks/useProjectComments";
import {
  type CommentMessageType,
  type ProjectCommentMetadata,
} from "@ll-web/features/projectComments/types";
import { ThreadBubble } from "@ll-web/features/videoPlayer/comments/components/ThreadBubble";

type VideoThreadProps = {
  threadId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sx: SxProps<Theme>;
  metadata: ProjectCommentMetadata;
};

export const VideoThread = ({
  threadId,
  isOpen,
  setIsOpen,
  sx,
  metadata,
}: VideoThreadProps) => {
  const { onAdd, onUpdate, onDelete, onReopen, onResolve } = useProjectComments(
    {
      metadata,
    },
  );
  // this query should be already cached

  const { commentNumberRegister } = useCommentNumberRegister();
  const index = commentNumberRegister[threadId];

  const handleCancel = useCallback(async () => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleAddReply = ({ message }: { message: string }) => {
    activityTracker.log({
      type: ActivityType.WizardOutputRepliedToComment,
      metadata: metadata.analyticsMetadata,
    });

    return onAdd({
      threadId,
      message,
    });
  };

  const handleRemoveComment = useCallback(
    ({ id }: { id: string }) => {
      activityTracker.log({
        type: ActivityType.WizardOutputRemovedComment,
        metadata: metadata.analyticsMetadata,
      });

      return onDelete({ threadId, id });
    },
    [metadata.analyticsMetadata, onDelete, threadId],
  );
  const handleReopen = useCallback(() => {
    activityTracker.log({
      type: ActivityType.WizardOutputReopenedComment,
      metadata: metadata.analyticsMetadata,
    });

    return onReopen({ threadId, id: threadId });
  }, [metadata.analyticsMetadata, onReopen, threadId]);
  const handleResolve = useCallback(() => {
    activityTracker.log({
      type: ActivityType.WizardOutputResolvedComment,
      metadata: metadata.analyticsMetadata,
    });

    return onResolve({ threadId, id: threadId });
  }, [metadata.analyticsMetadata, onResolve, threadId]);
  const handleUpdate = useCallback(
    (data: { id: string } & Partial<CommentMessageType>) => onUpdate(data),
    [onUpdate],
  );

  return (
    <ThreadBubble
      threadId={threadId}
      index={index ?? 1}
      sx={sx}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <CommentThread
        editMode="viewComment"
        commentsConfig={{
          metadata,
        }}
        threadId={threadId}
        onCancel={handleCancel}
        onAddReply={handleAddReply}
        onUpdate={handleUpdate}
        onResolve={handleResolve}
        onReopen={handleReopen}
        onRemoveComment={handleRemoveComment}
      />
    </ThreadBubble>
  );
};
