import { Stack, Typography, type StackProps } from "@mui/material";

export const CommentQuote = ({ children, ...props }: StackProps) => {
  return (
    <Stack
      {...props}
      sx={{
        borderLeft: (theme) => `2px solid ${theme.palette.secondary.dark}`,
        pl: 1.5,
        ...props.sx,
      }}
    >
      <Typography variant="caption" color="text.secondary">
        {children}
      </Typography>
    </Stack>
  );
};
