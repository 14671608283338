import type { LocaleObject } from "yup";

export const FORM_ERRORS: LocaleObject = {
  mixed: {
    required: "This field is required",
    defined: "This field is required",
    notNull: "This field is required",
  },
  string: {
    email: "Enter a valid email",
    min: ({ min }) =>
      `This field must have min. ${min} character${min > 1 ? "s" : ""}`,
    max: ({ max }) =>
      `This field can have max. ${max} character${max > 1 ? "s" : ""}`,
  },
  array: {
    min: ({ min }) =>
      `This field must have at least ${min} item${min > 1 ? "s" : ""}`,
  },
};
