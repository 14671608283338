import { useMemo } from "react";

import { useGetDeliverableVideoReviewComments } from "@ll-web/features/projectComments/async/useProjectCommentsQueries";
import { useFilterCommentThreads } from "@ll-web/features/projectComments/hooks/useFilterCommentThreads";
import { videoCommentsComparator } from "@ll-web/features/videoPlayer/comments/utils/videoCommentsComparator";
import { defined } from "@ll-web/utils/types/types";

export function useGetVideoThreadIndexes({
  projectId,
  deliverableId,
}: {
  projectId: string;
  deliverableId: string;
}) {
  // this query should already be cached
  const commentsQuery = useGetDeliverableVideoReviewComments({
    projectId,
    deliverableId,
  });

  const threads = useFilterCommentThreads({
    comments: commentsQuery.data,
  });

  const indexes = useMemo(() => {
    const unresolvedPositionedThreads = threads?.filter(
      (thread) => !thread.isResolved && defined(thread.target.position),
    );

    unresolvedPositionedThreads?.sort(videoCommentsComparator);

    return Object.fromEntries(
      unresolvedPositionedThreads?.map((thread, i) => [thread.id, i]) ?? [],
    );
  }, [threads]);

  return { indexes };
}
