import {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
  type ForwardedRef,
} from "react";

import { CloseOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  type AutocompleteProps,
  type TextFieldProps,
} from "@mui/material";
import { debounce } from "lodash-es";
import { Controller, useFormContext } from "react-hook-form";

import { TeamMember } from "@ll-web/components/TeamMember/TeamMember";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import type { User } from "@ll-web/features/auth/types";
import { useListUsers } from "@ll-web/features/users/async/useUsersQueries";

import type { NotifyForReviewFormValues } from "./notify-for-review.schema";

const loadingPlaceholder = "Loading...";
const youSuffix = "(you)";

type MultiUserAutocompleteProps = {
  label: string;
  defaultReceiversData: User[];
} & Omit<
  Partial<AutocompleteProps<string, boolean, boolean, false>>,
  "value" | "onChange" | "ref" | "defaultValue"
> & {
    textFieldProps?: Pick<TextFieldProps, "InputProps">;
  };

const UserPill = ({
  user,
  onRemove,
}: {
  user: User;
  onRemove: (id: string) => void;
}) => (
  <Stack
    key={user._id}
    sx={{
      borderRadius: 12.5,
      padding: 0.5,
      background: (theme) => theme.palette.action.selected,
      flexDirection: "row",
    }}
  >
    <TeamMember user={user} size="sm" />
    <IconButton onClick={() => onRemove(user._id)} sx={{ py: 0 }}>
      <CloseOutlined />
    </IconButton>
  </Stack>
);

export const MultiUserAutocomplete = forwardRef(
  (
    {
      label,
      onInputChange,
      defaultReceiversData,
      textFieldProps = {},
      ...props
    }: MultiUserAutocompleteProps,
    ref?: ForwardedRef<HTMLDivElement>,
  ) => {
    const [searchTerms, setSearchTerms] = useState("");
    const lookedUpUsersCacheRef = useRef(defaultReceiversData);

    const usersQuery = useListUsers(
      {
        limit: 20,
        query: searchTerms,
      },
      {
        enabled: Boolean(
          searchTerms?.length &&
            !searchTerms?.includes(loadingPlaceholder) &&
            !searchTerms.includes(youSuffix),
        ),
      },
    );

    const newUsers =
      usersQuery.data?.filter(
        ({ _id }) =>
          !lookedUpUsersCacheRef.current.some(
            (cachedUser) => cachedUser._id === _id,
          ),
      ) ?? [];

    if (newUsers.length) {
      lookedUpUsersCacheRef.current = [
        ...lookedUpUsersCacheRef.current,
        ...newUsers,
      ];
    }

    const { activeUser } = useActiveUser();

    const { setValue, watch } = useFormContext<NotifyForReviewFormValues>();

    const people = watch("people");

    const getOptionLabel = useCallback(
      (userId: string) => {
        const user = lookedUpUsersCacheRef.current.find(
          (user) => user?._id === userId,
        );

        if (!user) {
          return loadingPlaceholder;
        }

        if (activeUser._id === userId) {
          return `${user.email} ${youSuffix}`;
        }

        return user.email;
      },
      [activeUser._id],
    );

    const debouncedSetSearchTerms = useMemo(
      () => debounce(setSearchTerms, 300),
      [setSearchTerms],
    );

    const onRemovePerson = (personId: string) => {
      setValue(
        "people",
        people.filter((id) => id !== personId),
      );
    };

    const optionWithoutDuplicates: string[] = useMemo(
      () =>
        (usersQuery.data ?? [])
          .filter((user) => {
            return !people.some(
              (selectedUserId: string) => selectedUserId === user._id,
            );
          })
          .map((user) => user._id),
      [people, usersQuery.data],
    );

    return (
      <Controller
        name="people"
        render={({ field: { ref: _, ...field }, fieldState }) => {
          return (
            <Autocomplete
              {...field}
              loading={usersQuery.isFetching}
              getOptionLabel={getOptionLabel}
              ref={ref}
              multiple={true}
              disableClearable={true}
              // Workaround MUI async options bug https://github.com/mui/material-ui/issues/20068#issuecomment-822583362
              filterOptions={(x) => x}
              options={optionWithoutDuplicates}
              onChange={(_ev, value) => {
                field.onChange(value);
              }}
              onInputChange={(event, value, reason) => {
                debouncedSetSearchTerms(value);
                onInputChange?.(event, value, reason);
              }}
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                },
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    label={label}
                    {...params}
                    {...textFieldProps}
                    InputProps={{
                      ...params.InputProps,
                      ...textFieldProps.InputProps,
                      sx: {
                        ...textFieldProps.InputProps?.sx,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        "& > .MuiInputBase-input": {
                          minWidth: "20%!important",

                          "&:hover": {
                            minWidth: "20%!important",
                          },
                        },
                      },
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                );
              }}
              renderTags={(value) => {
                return (
                  <Stack direction="row" gap={1} flexWrap="wrap">
                    {value.map((userId) => {
                      const userData = lookedUpUsersCacheRef.current.find(
                        ({ _id }) => _id === userId,
                      );

                      return userData ? (
                        <UserPill
                          key={userId}
                          user={userData}
                          onRemove={onRemovePerson}
                        />
                      ) : null;
                    })}
                  </Stack>
                );
              }}
              {...props}
            />
          );
        }}
      />
    );
  },
);

MultiUserAutocomplete.displayName = "MultiUserAutocomplete";
