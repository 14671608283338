import type { ReactNode } from "react";

import { ContentCopy, LaunchOutlined } from "@mui/icons-material";
import { Link } from "@mui/material";
import { toast } from "react-toastify";
import { useCopyToClipboard } from "react-use";

import { prependProtocolToURL } from "@ll-web/utils/helpers/prependProtocolToURL";

type TextLinkProps = {
  url: string;
  children: ReactNode;
  newTab?: boolean;
  withCopy?: boolean | string;
};

export const TextLink = ({
  url,
  children,
  newTab,
  withCopy,
}: TextLinkProps) => {
  const [_, copyToClipboard] = useCopyToClipboard();

  return (
    <Link
      href={prependProtocolToURL(url)}
      underline="hover"
      color="inherit"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "3px",
        "&:hover": {
          color: (theme) => theme.palette.primary.main,
          "& > .MuiSvgIcon-root": {
            opacity: 1,
          },
        },
      }}
      target={newTab ? "_blank" : undefined}
      rel={newTab ? "noopener" : undefined}
    >
      {children}
      {newTab && (
        <LaunchOutlined
          fontSize="inherit"
          color="inherit"
          sx={{
            opacity: 0,
          }}
        />
      )}
      {withCopy && (
        <ContentCopy
          fontSize="inherit"
          color="inherit"
          sx={{
            cursor: "pointer",
            opacity: 0,
          }}
          onClick={(event) => {
            event.preventDefault();
            copyToClipboard(typeof withCopy === "string" ? withCopy : url);
            toast.success("Copied to clipboard!", {
              autoClose: 1000,
            });
          }}
        />
      )}
    </Link>
  );
};
