import { useEffect, useState } from "react";

import { useVideoPlayerRef } from "@ll-web/features/videoPlayer/contexts/VideoPlayerRefContext";
import { getPlayer } from "@ll-web/features/videoPlayer/helpers/videoPlayer";
import { ignoreErrors } from "@ll-web/utils/helpers/helpers";

export function useRoundedTime() {
  const { videoPlayerRef, isReady } = useVideoPlayerRef();
  const [roundedTime, setRoundedTime] = useState(0);
  useEffect(() => {
    if (!isReady) {
      return;
    }
    const player = getPlayer(videoPlayerRef);

    const dispose = player.subscribe(({ currentTime }) => {
      setRoundedTime(Math.floor(currentTime * 2) / 2); // rounds to a half second
    });

    return () => {
      setRoundedTime(0);
      ignoreErrors(() => dispose());
    };
  }, [isReady, videoPlayerRef]);

  return { roundedTime };
}
