import { useCallback, useMemo } from "react";

import { merge } from "lodash-es";

import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import {
  useAddCommentMutation,
  useDeleteCommentMutation,
  useReopenCommentMutation,
  useResolveCommentMutation,
  useUpdateCommentMutation,
} from "@ll-web/features/projectComments/async/useProjectCommentsMutations";
import type {
  CommentMessageType,
  ProjectCommentMetadata,
} from "@ll-web/features/projectComments/types";
import { useActiveProjectId } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { assertDefined } from "@ll-web/utils/types/types";

export const useProjectComments = ({
  metadata,
}: {
  metadata: ProjectCommentMetadata;
}) => {
  const { activeUser } = useActiveUser();
  const projectId = useActiveProjectId();

  const { mutateAsync: mutateAddCommentAsync } = useAddCommentMutation();
  const { mutateAsync: mutateUpdateCommentAsync } = useUpdateCommentMutation();
  const { mutateAsync: mutateDeleteCommentAsync } = useDeleteCommentMutation();
  const { mutateAsync: mutateResolveCommentAsync } =
    useResolveCommentMutation();
  const { mutateAsync: mutateReopenCommentAsync } = useReopenCommentMutation();

  const addComment = useCallback(
    async (
      data: Pick<CommentMessageType, "message" | "quote"> &
        Partial<ProjectCommentMetadata> & {
          threadId?: string;
        },
    ) => {
      assertDefined(projectId);

      const mergedData = merge({}, metadata, data);

      return mutateAddCommentAsync({
        userId: activeUser._id,
        projectId,
        ...mergedData,
        outputSubcollection: mergedData.target.outputSubcollection,
      });
    },
    [projectId, mutateAddCommentAsync, activeUser._id, metadata],
  );

  const updateComment = useCallback(
    async (data: { id: string } & Partial<CommentMessageType>) => {
      assertDefined(projectId);

      return mutateUpdateCommentAsync({
        projectId,
        ...data,
        ...metadata,
        outputSubcollection: metadata.target.outputSubcollection,
      });
    },
    [projectId, mutateUpdateCommentAsync, metadata],
  );

  const deleteComment = useCallback(
    async ({ id, threadId }: { id: string; threadId: string }) => {
      assertDefined(projectId);

      return mutateDeleteCommentAsync({
        id,
        projectId,
        threadId,
        outputSubcollection: metadata.target.outputSubcollection,
      });
    },
    [projectId, mutateDeleteCommentAsync, metadata],
  );

  const resolveComment = useCallback(
    async ({ id, threadId }: { id: string; threadId: string }) => {
      assertDefined(projectId);

      return mutateResolveCommentAsync({
        id,
        projectId,
        threadId,
        outputSubcollection: metadata.target.outputSubcollection,
      });
    },
    [projectId, mutateResolveCommentAsync, metadata],
  );

  const reopenComment = useCallback(
    async ({ id, threadId }: { id: string; threadId: string }) => {
      assertDefined(projectId);

      return mutateReopenCommentAsync({
        id,
        projectId,
        threadId,
        outputSubcollection: metadata.target.outputSubcollection,
      });
    },
    [projectId, mutateReopenCommentAsync, metadata],
  );

  return useMemo(
    () => ({
      onAdd: addComment,
      onUpdate: updateComment,
      onDelete: deleteComment,
      onResolve: resolveComment,
      onReopen: reopenComment,
    }),
    [addComment, updateComment, deleteComment, resolveComment, reopenComment],
  );
};
