import { mapUnknownToDayjs } from "@ll-web/utils/helpers/date";

import type { DroneInput } from "./droneProductionDto";
import type { DroneProductionFormValues } from "./droneProductionSchema";

export const mapDroneProductionFormToDto = ({
  id,
  droneOperator,
  productionLength,
  tentativeCity,
  dateTime,
  isTimeSet,
}: DroneProductionFormValues): DroneInput => {
  return {
    id: id!,
    dateTime: dateTime?.toDate() ?? null,
    isTimeSet,
    droneOperator,
    duration: productionLength,
    location: tentativeCity ?? undefined,
  };
};

export const mapDroneProductionDtoToForm = ({
  id,
  droneOperator,
  duration,
  location,
  dateTime,
  isTimeSet,
}: DroneInput): DroneProductionFormValues => ({
  id,
  droneOperator: droneOperator!,
  productionLength: duration,
  tentativeCity:
    typeof location === "string" ? { description: location } : location ?? null,
  dateTime: mapUnknownToDayjs(dateTime),
  isTimeSet,
  isInEditMode: false,
});
