import {
  useMutation,
  useQueryClient,
  type DefaultError,
  type MutationFunction,
  type MutationOptions,
  type QueryClient,
} from "@tanstack/react-query";

// factory function to create a mutation hook without additional options
export function createMutationHook<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>(
  mutationFunction: MutationFunction<TData, TVariables>,
  getMutationOptions?: (
    qc: QueryClient,
  ) => MutationOptions<TData, TError, TVariables, TContext>,
) {
  return (config?: MutationOptions<TData, TError, TVariables, TContext>) => {
    const queryClient = useQueryClient();

    return useMutation({
      mutationFn: mutationFunction,
      ...getMutationOptions?.(queryClient),
      ...config,
    });
  };
}
