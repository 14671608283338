import { useEffect } from "react";

import { useAnyUser } from "@ll-web/core/hooks/useAnyUser";
import { klaviyoService } from "@ll-web/integrations/klaviyo/async/KlaviyoService";

import { activityTracker } from "./activityTracker";
import { buildEventName, isEventOnChannel } from "./eventUtils";
import { EventChannel, type TrackingEvent } from "./types";

const EVENT_NAME_SEPARATOR = " " as const;

export const useKlaviyo = () => {
  const activeUser = useAnyUser();

  useEffect(() => {
    // Klaviyo BE endpoint requires authorization
    if (!activeUser) {
      return;
    }

    return activityTracker.subscribe("Klaviyo", (event: TrackingEvent) => {
      // klaviyo processes events on engagement channel
      if (!isEventOnChannel(event, EventChannel.EngagementTriggers)) {
        return;
      }
      klaviyoService.makeCreateEvent({
        eventName: buildEventName(event.type, EVENT_NAME_SEPARATOR),
        eventService: "frontend",
        metadata: event.metadata,
      });
    });
  }, [activeUser]);
};
