import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

import { APP_CONFIG } from "@ll-web/config/app.config";

export function setupNewRelic() {
  // New Relic agent should only be enabled in production builds
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  const config = {
    accountId: APP_CONFIG.REACT_APP_NEW_RELIC_ACCOUNT_ID,
    trustKey: APP_CONFIG.REACT_APP_NEW_RELIC_TRUST_KEY,
    agentId: APP_CONFIG.REACT_APP_NEW_RELIC_AGENT_ID,
    licenseKey: APP_CONFIG.REACT_APP_NEW_RELIC_LICENSE_KEY,
    applicationId: APP_CONFIG.REACT_APP_NEW_RELIC_APPLICATION_ID,
  };

  if (Object.values(config).some((value) => !value)) {
    console.error("New Relic config is incomplete, skipping setup");

    return;
  }

  /* eslint-disable camelcase */
  const options = {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ["bam.nr-data.net"] },
    },
    info: {
      beacon: "bam.nr-data.net",
      errorBeacon: "bam.nr-data.net",
      licenseKey: config.licenseKey,
      applicationID: config.applicationId,
      sa: 1,
    },
    loader_config: {
      accountID: config.accountId,
      trustKey: config.trustKey,
      agentID: config.agentId,
      licenseKey: config.licenseKey,
      applicationID: config.applicationId,
    },
  };
  /* eslint-enable camelcase */

  return new BrowserAgent(options);
}
