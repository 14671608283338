import type {
  ProjectDeliverablesEnum,
  ProjectStyleEnum,
} from "@ll-web/features/projects/enums";

const projectValidationContextFields = {
  projectDeliverables: "projectDeliverables",
  projectStyle: "projectStyle",
} as const;

export type ProjectValidationContextType = {
  [projectValidationContextFields.projectDeliverables]:
    | ProjectDeliverablesEnum
    | null
    | undefined;
  [projectValidationContextFields.projectStyle]:
    | ProjectStyleEnum
    | null
    | undefined;
};

// Used to access the projectDeliverables value from the yup context
export const contextProjectDeliverables = `$${projectValidationContextFields.projectDeliverables}`;

// Used to access the projectStyle value from the yup context
export const contextProjectStyle = `$${projectValidationContextFields.projectStyle}`;
