import { INTERNAL_EMAIL_DOMAIN } from "@ll-web/consts/lemonlight";
import {
  InternalAccountTypes,
  type AccountType,
} from "@ll-web/features/auth/types";

export function isUserInternal(user: { accountType?: AccountType }) {
  return user.accountType && InternalAccountTypes.includes(user.accountType);
}

export function isEmailInternal(email: string) {
  return email.endsWith(`@${INTERNAL_EMAIL_DOMAIN}`);
}
