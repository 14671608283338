export function isUploadcareUrl(url: string | null | undefined): boolean {
  return url?.startsWith("https://ucarecdn.com/") || false;
}

// dimensions param format: https://uploadcare.com/docs/transformations/image/resize-crop/#operation-preview
export function scaleUploadcareUrl(url: string, dimensions: string | number) {
  if (!isUploadcareUrl(url)) {
    console.warn(
      "[scaleUploadcareUrl] Blocked attempt to apply transformations to non-Uploadcare url ",
    );

    return url;
  }

  const formattedDimensions =
    typeof dimensions === "number" ? `${dimensions}x${dimensions}` : dimensions;

  const prefix = typeof dimensions === "number" ? "-/preview/" : "-/resize/";

  let newUrl = url;

  // Url can already have default preview param applied from user transformations
  if (!newUrl.endsWith(prefix)) {
    if (!newUrl.endsWith("/")) {
      newUrl += "/";
    }

    newUrl += prefix;
  }

  newUrl += formattedDimensions;
  newUrl += "/";

  return newUrl;
}
