import { type Dayjs } from "dayjs";
import * as yup from "yup";

import { contextProjectStyle } from "@ll-web/features/internalProjects/projectForm/validationContext";
import {
  CrewMemberEnum,
  LocationTypeEnum,
  ProductionScheduleStatusEnum,
  ProjectStyleEnum,
} from "@ll-web/features/projects/enums";
import type { PlaceDetailsType } from "@ll-web/features/projects/types";
import { yupTestNotDefined } from "@ll-web/utils/helpers/yup";

export const productionDaysFields = {
  locationType: "locationType",
  tentativeCity: "tentativeCity",
  dateTime: "dateTime",
  isTimeSet: "isTimeSet",
  crewMembers: "crewMembers",
  productionLength: "productionLength",
  isInEditMode: "isInEditMode",
  scheduleStatus: "scheduleStatus",
} as const;

const untypedProductionDaySchema = yup.object().shape({
  id: yup.string().optional().default(undefined),
  [productionDaysFields.locationType]: yup
    .string()
    .oneOf(Object.values(LocationTypeEnum))
    .when(contextProjectStyle, {
      is: ProjectStyleEnum.Scripted,
      then: (schema: yup.StringSchema) =>
        schema.required().default(LocationTypeEnum.STUDIO),
      otherwise: (schema: yup.StringSchema) =>
        schema.notRequired().test(yupTestNotDefined),
    }),
  // In seconds
  [productionDaysFields.productionLength]: yup
    .number()
    .required()
    .default(null),
  [productionDaysFields.tentativeCity]: yup
    .mixed<PlaceDetailsType>()
    .nullable(),
  [productionDaysFields.dateTime]: yup
    .mixed<Dayjs>()
    .dayjs()
    .nullable()
    .default(null),
  [productionDaysFields.isTimeSet]: yup.boolean().default(false),
  [productionDaysFields.crewMembers]: yup
    .array()
    .of(yup.string().oneOf(Object.values(CrewMemberEnum)))
    .min(1)
    .required()
    .when(contextProjectStyle, {
      is: ProjectStyleEnum.Scripted,
      then: (schema) =>
        schema.default([
          CrewMemberEnum.Director,
          CrewMemberEnum.DirectorOfPhotography,
          CrewMemberEnum.Gaffer,
          CrewMemberEnum.Grip,
          CrewMemberEnum.ProductionAssistant,
        ]),
    })
    .default([CrewMemberEnum.DirectorOfPhotography, CrewMemberEnum.Grip]),
  [productionDaysFields.isInEditMode]: yup.boolean().default(true),
  [productionDaysFields.scheduleStatus]: yup
    .string()
    .optional()
    .oneOf(Object.values(ProductionScheduleStatusEnum))
    .default(undefined),
});

export type ProductionDayFormValues = yup.InferType<
  typeof untypedProductionDaySchema
>;

export const productionDaySchema =
  untypedProductionDaySchema as yup.ObjectSchema<ProductionDayFormValues>;
