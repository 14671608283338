import { useMemo } from "react";

import { useParams } from "react-router-dom";

import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { useGetCharactersByProjectId } from "@ll-web/features/projects/async/useProjectsQueries";
import { ProjectStyleEnum } from "@ll-web/features/projects/enums";
import { getWizardStepsForProject } from "@ll-web/features/projectWizard/consts/wizardSteps";
import type { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";

export const useProjectWizardSteps = () => {
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();
  const isProjectWithCharacters =
    activeProject.style === ProjectStyleEnum.Scripted;
  const { data: characters, isFetched } = useGetCharactersByProjectId(
    { id: activeProject.id },
    { enabled: isProjectWithCharacters },
  );
  const { flowType } = useParams<{
    flowType: WizardFlowType;
  }>();

  const wizardSteps = useMemo(() => {
    return getWizardStepsForProject({
      project: activeProject,
      user: activeUser,
      characters,
      flowType,
    });
  }, [activeProject, activeUser, characters, flowType]);

  const isLoadingSteps = isProjectWithCharacters && !isFetched;

  return { wizardSteps, isLoadingSteps };
};
