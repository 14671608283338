import { CheckCircle, CheckCircleOutlineOutlined } from "@mui/icons-material";
import { IconButton, Tooltip, type IconButtonProps } from "@mui/material";

type CommentResolveButtonProps = IconButtonProps & {
  isResolved: boolean;
};

export const CommentResolveButton = ({
  isResolved,
  ...props
}: CommentResolveButtonProps) => {
  const componentsProps = isResolved
    ? {
        title: "Re-open",
        icon: <CheckCircle color="success" />,
      }
    : {
        title: "Resolve comment",
        icon: <CheckCircleOutlineOutlined color="inherit" />,
      };

  return (
    <Tooltip title={componentsProps.title} arrow placement="top">
      <IconButton {...props}>{componentsProps.icon}</IconButton>
    </Tooltip>
  );
};
