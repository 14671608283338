import { Navigate } from "react-router-dom";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { Pages } from "@ll-web/core/router/pages";
import { useUser } from "@ll-web/features/auth/hooks/useUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { makeNextParamForCurrent } from "@ll-web/utils/helpers/navigation";

type AuthRedirectControllerProps = {
  unauthorizedRedirectPage?: string;
  emailHint?: string;
};

export function AuthRedirectController({
  unauthorizedRedirectPage = Pages.Login,
  emailHint,
}: AuthRedirectControllerProps) {
  const { isLoading, isAuthorized, activeUser } = useUser();

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (!isAuthorized) {
    return (
      <Navigate
        to={`${unauthorizedRedirectPage}?${makeNextParamForCurrent()}${
          emailHint ? `&${new URLSearchParams({ email: emailHint })}` : ""
        }`}
        replace
      />
    );
  }

  if (isUserInternal(activeUser)) {
    return <Navigate to={Pages.InternalDashboardRedirect} replace />;
  }

  return <Navigate to={Pages.DashboardRedirect} replace />;
}
