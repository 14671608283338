import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { mapProjectToAnalytics } from "@ll-web/core/analytics/eventUtils";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";

import {
  sentimentSchema,
  type SentimentFormValues,
} from "./sentiment-dialog-schema";
import { SentimentQuestion } from "./SentimentQuestion";

type SentimentDialogProps = {
  isOpen: boolean;
  onSubmit: VoidFunction;
};

export const SentimentDialog = ({
  isOpen,
  onSubmit: onSubmitDialog,
}: SentimentDialogProps) => {
  const { activeProject } = useActiveProject();

  const onSubmit = (values: SentimentFormValues) => {
    activityTracker.log({
      type: ActivityType.WizardSentimentScoreSubmitted,
      metadata: {
        ...mapProjectToAnalytics(activeProject),
        ...values,
      },
    });
    onSubmitDialog();
    toast.success("Feedback submitted. Thank you!");
  };

  const methods = useForm({
    defaultValues: {
      easyToUse: 0,
      accuracyOfQuestions: 0,
      recommendationLikehood: 0,
      opinion: "",
    },
    resolver: yupResolver(sentimentSchema),
    mode: "onBlur",
  });

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <FormProvider {...methods}>
        <Box
          component="form"
          noValidate
          width="100%"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <DialogTitle sx={{ p: 3 }}>
            Share your experience to help us improve.
          </DialogTitle>
          <DialogContent
            sx={{ gap: 3, display: "flex", flexDirection: "column" }}
          >
            <SentimentQuestion
              name="easyToUse"
              question="How easy was it to navigate the steps of this process?"
              text="Easy to Use"
            />
            <SentimentQuestion
              name="accuracyOfQuestions"
              question="How well did the questions capture your project vision?"
              text="Accuracy of Questions"
            />
            <SentimentQuestion
              name="recommendationLikehood"
              question="How likely are you to recommend this experience to others?"
              text="Recommendation Likelihood"
            />
            <Divider />
            <Stack gap={1}>
              <Typography variant="body1" fontWeight={600}>
                Share more about your experience
              </Typography>
              <Controller
                name="opinion"
                control={methods.control}
                render={({
                  field: { onChange, ref: _, ...field },
                  fieldState,
                }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label={"Optional"}
                    placeholder="Optional"
                    input_bg="white"
                    name={"opinion"}
                    error={!!fieldState.error?.message}
                    helperText={fieldState.error?.message}
                    onChange={(ev) => onChange(ev.target.value)}
                  />
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" type="submit">
              Submit Feedback
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
