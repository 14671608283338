import { useState } from "react";

import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import {
  useWizardNavigationContext,
  WizardFlowType,
} from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";

export const useIsSentimentDialogAvailable = () => {
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();
  const [projectHasBeenFinishedBefore] = useState(() => {
    return activeProject.clientFirstFinishedPreproductionAt;
  });
  const { flowType } = useWizardNavigationContext();

  return (
    !isUserInternal(activeUser) &&
    flowType === WizardFlowType.Generate &&
    !projectHasBeenFinishedBefore
  );
};
