import type {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
  WithFieldValue,
} from "firebase/firestore";

import type { InterviewInfo } from "@ll-web/features/projectWizard/types";
import { mapUnknownToDate } from "@ll-web/utils/helpers/date";

export const interviewInputConverter: FirestoreDataConverter<
  InterviewInfo,
  InterviewInfo
> = {
  toFirestore: (data: WithFieldValue<InterviewInfo>) => data,
  fromFirestore(snap: QueryDocumentSnapshot) {
    return {
      ...snap.data(),
      id: snap.id,
      createdAt: mapUnknownToDate(snap.data().createdAt as Timestamp),
    } as InterviewInfo;
  },
};
