import * as yup from "yup";

import { BLOCKING_PHRASES } from "./consts";

export const notifyForReviewSchema = yup
  .object()
  .shape({
    title: yup.string().required(),
    message: yup
      .string()
      .required()
      .test((value, test) => {
        if (!value) {
          return true;
        }

        for (const key in BLOCKING_PHRASES) {
          if (BLOCKING_PHRASES.hasOwnProperty(key)) {
            const phrase =
              BLOCKING_PHRASES[key as keyof typeof BLOCKING_PHRASES];
            if (value.includes(phrase.text)) {
              return test.createError({ message: phrase.errorMessage });
            }
          }
        }

        return true;
      }),
    people: yup.array().required().of(yup.string().required()).min(1),
  })
  .required();

export type NotifyForReviewFormValues = yup.InferType<
  typeof notifyForReviewSchema
>;
