import { useCallback } from "react";

import { useMutationState } from "@tanstack/react-query";

import { makeReviewNotificationKey } from "@ll-web/components/NotifyForReviewModal/makeReviewNotificationKey";
import type { NotifyForReviewFormValues } from "@ll-web/components/NotifyForReviewModal/notify-for-review.schema";
import { wizardReviewNotificationType } from "@ll-web/components/NotifyForReviewModal/reviewNotificationKey";
import { useNotifyForReviewDialog } from "@ll-web/components/NotifyForReviewModal/useNotifyForReviewModal";
import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { mapProjectToAnalytics } from "@ll-web/core/analytics/eventUtils";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { useGetBrandByProjectId } from "@ll-web/features/brands/async/useBrandsQueries";
import {
  useNotifyProjectFinalizationMutation,
  useNotifyProjectFinalizationMutationKey,
} from "@ll-web/features/productionDocs/async/useProductionDocsMutations";
import { useUpdateProjectFields } from "@ll-web/features/projects/async/useProjectsMutations";
import { ProjectStyleCreativeBriefEnabledMap } from "@ll-web/features/projects/consts/projectStyleCreativeBriefEnabledMap";
import {
  ExternalProjectStatus,
  ProjectStatus,
  ProjectStyleEnum,
} from "@ll-web/features/projects/enums";
import {
  useRequestReview,
  useUpdateClientFirstFinishedPreproductionAtMutation,
  useUpdateSuccessfullyFirstFinalizedPreproductionAtMutation,
} from "@ll-web/features/projectWizard/async/useProjectWizardMutations";
import {
  useWizardNavigationContext,
  WizardFlowType,
} from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { useSentimentDialog } from "@ll-web/features/projectWizard/pages/steps/finalize/sentimentDialog/useSentimentDialog";
import { useFinalizeEdit } from "@ll-web/features/projectWizard/pages/steps/finalizeEdit/useFinalizeEdit";
import type { CreateReviewDto } from "@ll-web/features/projectWizard/types";
import { mapUnknownToDate } from "@ll-web/utils/helpers/date";
import { assertDefined } from "@ll-web/utils/types/types";

type UseFinalizeWizardArgs = {
  onFinalize?: () => void;
};

export const useFinalizeWizard = ({
  onFinalize,
}: UseFinalizeWizardArgs = {}) => {
  const { flowType } = useWizardNavigationContext();
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProject();
  const isInternalUser = isUserInternal(activeUser);
  const flags = useAppFlags();

  const notificationType = wizardReviewNotificationType({
    flowType,
    user: activeUser,
    isNewFlowEnabled: !!flags[FeatureFlagName.NewWizardFlow],
  });

  const getBrandByProjectIdQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });
  const brandId = getBrandByProjectIdQuery.data?._id;

  const { mutateAsync: mutateUpdateProjectFieldsAsync } =
    useUpdateProjectFields();
  const updateClientFirstFinishedPreproductionAtMutation =
    useUpdateClientFirstFinishedPreproductionAtMutation();
  const { mutateAsync: updateSuccessfullyFirstFinalizedPreproductionAt } =
    useUpdateSuccessfullyFirstFinalizedPreproductionAtMutation();
  const {
    mutateAsync: mutateRequestReviewAsync,
    isSuccess: isRequestReviewSuccess,
  } = useRequestReview();
  const {
    mutateAsync: mutateNotifyScriptedFinalizationMutationAsync,
    reset: resetNotifyScriptedFinalization,
    isSuccess: isNotifyScriptedFinalizationSuccess,
  } = useNotifyProjectFinalizationMutation({
    meta: {
      supressErrorToast: true,
    },
  });
  // subscribe to mutation state to make the state available in all components using the hook even if the mutation was called by a different component
  const finalizeNonScriptedRequestState = useMutationState({
    filters: { mutationKey: useNotifyProjectFinalizationMutationKey },
  }).at(-1);
  const finalizeWithoutCreativeBriefRequestState = useMutationState({
    filters: { mutationKey: useNotifyProjectFinalizationMutationKey },
  }).at(-1);
  const finalizeRequestState =
    activeProject.style === ProjectStyleEnum.Scripted
      ? finalizeWithoutCreativeBriefRequestState
      : finalizeNonScriptedRequestState;

  const { finalizeEdit } = useFinalizeEdit();
  const sentimentDialog = useSentimentDialog({
    modalTriggerFlag:
      activeProject.style === ProjectStyleEnum.Scripted
        ? isNotifyScriptedFinalizationSuccess
        : isRequestReviewSuccess,
    onFinalize,
  });

  const handleFinishedAnalytics = useCallback(() => {
    if (
      !isInternalUser &&
      activeProject.clientFirstViewedAt &&
      !activeProject.clientFirstFinishedPreproductionAt
    ) {
      updateClientFirstFinishedPreproductionAtMutation.mutateAsync({
        projectId: activeProject.id,
      });

      const firstViewedAt = mapUnknownToDate(activeProject.clientFirstViewedAt);
      const duration = (Date.now() - firstViewedAt!.getTime()) / 1000;

      activityTracker.log({
        type: ActivityType.WizardFinishedPreproSteps,
        metadata: {
          duration,
          ...mapProjectToAnalytics(activeProject),
        },
      });
    }
  }, [
    activeProject,
    updateClientFirstFinishedPreproductionAtMutation,
    isInternalUser,
  ]);

  const approveAndSendScriptedNotification = useCallback(async () => {
    await mutateNotifyScriptedFinalizationMutationAsync({
      projectId: activeProject.id,
    });
  }, [mutateNotifyScriptedFinalizationMutationAsync, activeProject.id]);

  const finalizeCreativeBriefFlow = useCallback(
    async ({ message, people, title }: NotifyForReviewFormValues) => {
      assertDefined(brandId, "brandId");
      const projectId = activeProject.id;
      const requestPayload = {
        brandId,
        isPingOnly: true,
        email: {
          body: message,
          title,
          recipientUserIds: people,
        },
        reviewKey: makeReviewNotificationKey({
          projectId,
          reviewNotificationType: notificationType,
        }),
      } satisfies CreateReviewDto;

      switch (flowType) {
        case WizardFlowType.EditCreativeDeck:
        case WizardFlowType.EditCallSheet: {
          const finalizeCallback = async () => {
            await mutateRequestReviewAsync(requestPayload);
          };

          return await finalizeEdit(finalizeCallback);
        }

        case WizardFlowType.CreativeBrief: {
          await mutateRequestReviewAsync(requestPayload);

          await mutateUpdateProjectFieldsAsync({
            id: projectId,
            data: {
              isCreativeBriefInputFilled: true,
              status: ProjectStatus.VideoSummary,
            },
          });

          break;
        }

        case WizardFlowType.Generate: {
          await mutateRequestReviewAsync(requestPayload);

          if (
            !activeProject.successfullyFirstFinalizedPreproductionAt &&
            !isInternalUser
          ) {
            updateSuccessfullyFirstFinalizedPreproductionAt({
              projectId,
            });
          }

          if (!activeProject.externalStatus) {
            await mutateUpdateProjectFieldsAsync({
              id: projectId,
              data: {
                externalStatus: ExternalProjectStatus.VideoSummary,
              },
            });
          } else {
            // for legacy projects started before Creative Brief flow
            await mutateUpdateProjectFieldsAsync({
              id: projectId,
              data: {
                externalStatus: ExternalProjectStatus.VideoSummary,
                isCreativeBriefInputFilled: true, // get legacy projects on track with the new flow
              },
            });
          }

          break;
        }
      }

      if (!sentimentDialog.isEnabled) {
        onFinalize?.();
      } // otherwise onFinalize is called by sentimentDialog
    },
    [
      isInternalUser,
      activeProject,
      flowType,
      finalizeEdit,
      updateSuccessfullyFirstFinalizedPreproductionAt,
      mutateUpdateProjectFieldsAsync,
      onFinalize,
      sentimentDialog.isEnabled,
      brandId,
      notificationType,
      mutateRequestReviewAsync,
    ],
  );

  const finalizeWithoutCreativeBrief = useCallback(async () => {
    const projectId = activeProject.id;

    switch (flowType) {
      case WizardFlowType.EditCreativeDeck:
      case WizardFlowType.EditCallSheet: {
        return await finalizeEdit();
      }

      case WizardFlowType.CreativeBrief: {
        throw new Error("Creative Brief input for Scripted is not supported");
      }

      case WizardFlowType.Generate: {
        await approveAndSendScriptedNotification();

        if (
          !activeProject.successfullyFirstFinalizedPreproductionAt &&
          !isInternalUser
        ) {
          updateSuccessfullyFirstFinalizedPreproductionAt({
            projectId,
          });
        }

        if (!activeProject.externalStatus) {
          await mutateUpdateProjectFieldsAsync({
            id: projectId,
            data: {
              externalStatus: ExternalProjectStatus.ScriptedVideoSummary,
            },
          });
        }

        break;
      }
    }

    if (!sentimentDialog.isEnabled) {
      onFinalize?.();
    } // otherwise onFinalize is called by sentimentDialog
  }, [
    isInternalUser,
    activeProject,
    flowType,
    finalizeEdit,
    updateSuccessfullyFirstFinalizedPreproductionAt,
    mutateUpdateProjectFieldsAsync,
    approveAndSendScriptedNotification,
    onFinalize,
    sentimentDialog.isEnabled,
  ]);

  const notifyForReviewDialog = useNotifyForReviewDialog({
    onSubmit: finalizeCreativeBriefFlow,
    project: activeProject,
    dialogProps: {
      notificationType,
    },
  });

  const handleClickFinalize = useCallback(async () => {
    if (flowType === WizardFlowType.Generate) {
      activityTracker.log({
        type: ActivityType.WizardFinalizeClickFinalize,
        metadata: mapProjectToAnalytics(activeProject),
      });

      handleFinishedAnalytics();
    }

    if (ProjectStyleCreativeBriefEnabledMap[activeProject.style]) {
      notifyForReviewDialog.openNotifyModal();
    } else {
      await finalizeWithoutCreativeBrief();
    }
  }, [
    notifyForReviewDialog,
    activeProject,
    flowType,
    handleFinishedAnalytics,
    finalizeWithoutCreativeBrief,
  ]);

  return {
    onClickFinalize: handleClickFinalize,
    sentimentDialog,
    notifyForReviewDialog,
    resetNotifyScriptedFinalization,
    finalizeRequestState,
  };
};
