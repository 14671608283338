import { EventSourceParserStream } from "eventsource-parser/stream";

import { APP_CONFIG } from "@ll-web/config/app.config";
import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import {
  MAX_BACKEND_STREAM_TIMEOUT,
  MAX_BACKEND_TIMEOUT,
} from "@ll-web/core/api/consts";
import { httpClient } from "@ll-web/core/api/HttpClient";
import { StaticFeatureFlags } from "@ll-web/core/featureFlags/FeatureFlagProvider";
import { AbortError } from "@ll-web/features/llm/async/errors";
import { JsonPrompt } from "@ll-web/features/llm/prompts/JsonPrompt";
import type { Prompt } from "@ll-web/features/llm/prompts/Prompt";
import {
  type CreateTextGenerationDto,
  type JsonStreamCallbacks,
  type LlmOptions,
  type MessageType,
  type ModelOption,
  type TextGenerationChunkDto,
  type TextGenerationResponseDto,
  type TextStreamCallbacks,
} from "@ll-web/features/llm/types";
import { parseJsonResponse } from "@ll-web/features/llm/utils/parseJson";
import { assertDefined } from "@ll-web/utils/types/types";

const STREAM_COMPLETE_EVENT_DATA = "[DONE]";

type OptionsType = {
  modelOptions?: LlmOptions;
  useCache?: boolean;
  abortSignal?: AbortSignal;
  metadata?: Record<string, string>;
  wrappedResponseKey?: string;
};

type CompletionReturnType<T extends { fullText: string }> = {
  output: T;
  messages: MessageType[];
  mergedOptions: Omit<OptionsType, "abortSignal">;
};

type TextCompletionReturnType = CompletionReturnType<{ fullText: string }>;
type JsonCompletionReturnType = CompletionReturnType<{
  fullText: string;
  json: unknown;
}>;

class LlmService {
  public async textCompletion(
    prompt: Prompt,
    options: OptionsType = {},
  ): Promise<TextCompletionReturnType> {
    const { messages } = prompt.compile();

    const mergedOptions = this.makeMergedOptions(prompt, options);

    const payload: CreateTextGenerationDto = {
      messages,
      model: mergedOptions.modelOptions.model,
      temperature: mergedOptions.modelOptions.temperature,
      jsonSchema: mergedOptions.modelOptions.jsonSchema,
      responseFormat: mergedOptions.modelOptions.responseFormat,
      useCache: mergedOptions.useCache,
      metadata: mergedOptions.metadata,
    };

    const response = await this.makeTextGeneration(
      payload,
      options.abortSignal,
    );

    const responseMessage: MessageType = {
      role: "assistant",
      content: response.fullText,
    };

    delete mergedOptions.abortSignal;

    return {
      output: { fullText: response.fullText },
      messages: [...messages, responseMessage],
      mergedOptions,
    };
  }

  public async jsonCompletion(
    prompt: JsonPrompt,
    options: OptionsType = {},
  ): Promise<JsonCompletionReturnType> {
    const mergedOptions = this.makeMergedOptions(prompt, options);

    const returnValue = await this.textCompletion(prompt, options);

    const parsedJson = this.processJsonResponse(
      returnValue.output.fullText,
      mergedOptions,
    );

    return {
      ...returnValue,
      output: { ...returnValue.output, json: parsedJson },
    };
  }

  public async streamTextCompletion(
    prompt: Prompt,
    callbacks: TextStreamCallbacks,
    options: OptionsType = {},
  ): Promise<TextCompletionReturnType> {
    const { messages } = prompt.compile();

    const mergedOptions = this.makeMergedOptions(prompt, options);

    const payload: Omit<CreateTextGenerationDto, "useCache" | "metadata"> = {
      messages,
      model: mergedOptions.modelOptions.model,
      temperature: mergedOptions.modelOptions.temperature,
      jsonSchema: mergedOptions.modelOptions.jsonSchema,
      responseFormat: mergedOptions.modelOptions.responseFormat,
    };

    const response = this.makeStreamTextGeneration(
      payload,
      options.abortSignal,
    );

    const responseMessage: MessageType = {
      role: "assistant",
      content: "",
    };

    for await (const chunk of response) {
      responseMessage.content += chunk.text;
      callbacks?.onUpdate?.({
        fullText: responseMessage.content,
        newText: chunk.text,
        messages: [...messages, responseMessage],
      });
    }

    delete mergedOptions.abortSignal;

    return {
      output: { fullText: responseMessage.content },
      messages: [...messages, responseMessage],
      mergedOptions,
    };
  }

  public async streamJsonCompletion(
    prompt: JsonPrompt,
    callbacks: JsonStreamCallbacks,
    options: OptionsType = {},
  ): Promise<JsonCompletionReturnType> {
    const mergedOptions = this.makeMergedOptions(prompt, options);

    const returnValue = await this.streamTextCompletion(
      prompt,
      {
        onUpdate: (data) => {
          try {
            const parsedJson = this.processJsonResponse(
              data.fullText,
              mergedOptions,
            );
            callbacks.onUpdate?.({
              ...data,
              json: parsedJson,
            });
          } catch (e) {
            // FIXME: handle errors and auto retry and fix
          }
        },
      },
      mergedOptions,
    );

    const parsedJson = this.processJsonResponse(
      returnValue.output.fullText,
      mergedOptions,
    );

    return {
      ...returnValue,
      output: {
        ...returnValue.output,
        json: parsedJson,
      },
    };
  }

  protected makeMergedOptions(prompt: Prompt, options: OptionsType) {
    const mergedOptions = {
      ...options,
      modelOptions: {
        model: StaticFeatureFlags[
          FeatureFlagName.OpenaiDefaultModel
        ] as ModelOption,
        ...prompt.getModelOptions?.(),
        ...options.modelOptions,
      },
      metadata: {
        ...options.metadata,
        ...prompt.dump().metadata,
      },
      wrappedResponseKey:
        prompt instanceof JsonPrompt
          ? prompt.getWrappedResponseKey()
          : undefined,
    } satisfies OptionsType;

    return mergedOptions;
  }

  protected processJsonResponse(
    json: string,
    { wrappedResponseKey }: Pick<OptionsType, "wrappedResponseKey">,
  ) {
    const parsedJson = parseJsonResponse(json);

    if (!parsedJson || typeof parsedJson !== "object") {
      throw new Error("Invalid JSON output format");
    }
    if (wrappedResponseKey) {
      if (!(wrappedResponseKey in parsedJson)) {
        throw new Error(
          `JSON output missing wrapped response key ${wrappedResponseKey}`,
        );
      }

      return parsedJson[wrappedResponseKey as keyof typeof parsedJson];
    }

    return parsedJson;
  }

  private async makeTextGeneration(
    payload: CreateTextGenerationDto,
    signal?: AbortSignal,
  ): Promise<TextGenerationResponseDto> {
    return await httpClient.unwrappedHttpRequest<TextGenerationResponseDto>({
      config: {
        method: "POST",
        url: "/v1/llm/generate",
        data: payload,
        timeout: MAX_BACKEND_TIMEOUT,
        signal,
      },
    });
  }

  private async *makeStreamTextGeneration(
    payload: CreateTextGenerationDto,
    signal?: AbortSignal,
  ): AsyncGenerator<TextGenerationChunkDto, { fullText: string }> {
    const abortController = new AbortController();
    signal?.addEventListener("abort", (event) =>
      abortController.abort((event.target as AbortSignal).reason),
    );
    const timeoutId = setTimeout(
      () => abortController.abort("timeout"),
      MAX_BACKEND_STREAM_TIMEOUT,
    );

    try {
      const response = await fetch(
        `${APP_CONFIG.REACT_APP_API_URL}/v1/llm/stream`,
        {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            ...(await httpClient.getAuthHeaders()),
          },
        },
      );

      if (!response.ok) {
        if (response.status === 401) {
          await httpClient.defaultHandleUnauthorized();
        }
        throw new Error("Network error");
      }

      assertDefined(response.body, "response.body");

      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .pipeThrough(new EventSourceParserStream())
        .getReader();

      let fullText = "";
      while (true) {
        if (abortController.signal?.aborted) {
          throw new AbortError(abortController.signal.reason);
        }
        const { done, value } = await reader.read();

        if (value?.data === STREAM_COMPLETE_EVENT_DATA) {
          break;
        }

        if (done) {
          throw new Error("Stream ended without confirmation");
        }

        const chunkData = JSON.parse(value.data) as TextGenerationChunkDto;
        if (!chunkData.text?.length) {
          continue;
        }

        yield chunkData;
        fullText += chunkData.text;
      }

      return {
        fullText,
      };
    } finally {
      clearTimeout(timeoutId);
    }
  }
}

export const llmService = new LlmService();
