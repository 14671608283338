import { cloneDeep, isObject } from "lodash-es";
import type { SetRequired } from "type-fest";

import { WrappedResponseKey } from "@ll-web/features/llm/const";
import type {
  AnyJsonOutput,
  JsonSchemaDefinition,
  JsonSchemaDto,
  WrappedOutputType,
} from "@ll-web/features/llm/types";

type JsonSchemaProps<T extends AnyJsonOutput | unknown> = {
  name: string;
  description: string;
  schema: JsonSchemaDefinition<T>;
  wrappedResponseKey?: string;
};

type MakePartialSchemaOptions = {
  placeholderType: Exclude<
    NonNullable<JsonSchemaDefinition["type"]>,
    "object" | "array"
  >;
  levels?: number;
};

export type SchemaToDtoOptions = {
  partialOptions?: MakePartialSchemaOptions;
};

// Note that the schema type safety is not enforced for complex schemas
// Also, not all options are supported by OpenAI
// https://platform.openai.com/docs/guides/structured-outputs/supported-schemas
export class JsonSchema<T extends AnyJsonOutput | unknown> {
  public readonly name: string;
  public readonly description: string;
  public readonly schema: JsonSchemaDefinition<T>;
  public readonly wrappedResponseKey?: string;

  constructor(props: JsonSchemaProps<T>) {
    this.name = props.name;
    this.description = props.description;
    this.schema = props.schema;
    this.wrappedResponseKey = props.wrappedResponseKey;
  }

  toDto({ partialOptions }: SchemaToDtoOptions = {}): JsonSchemaDto {
    const processedSchema = partialOptions
      ? this.makePartialSchema(this.schema, partialOptions)
      : this.schema;
    const finalSchema = this.makeStrictSchema(processedSchema);

    return {
      name: this.name,
      description: this.description,
      schema: finalSchema,
      strict: true,
    };
  }

  // Mark all properties as required and disable additionalPropeties
  private makeStrictSchema(schema: JsonSchemaDefinition): JsonSchemaDefinition {
    const temp = cloneDeep(schema);

    const recursive = (obj: unknown): void => {
      if (Array.isArray(obj)) {
        return obj.forEach(recursive);
      }

      if (!isObject(obj)) {
        return;
      }

      if (this.doesLeafContainProperties(obj)) {
        const keys = Object.keys(obj.properties);
        obj.required = keys;
        obj.additionalProperties = false;

        // Don't return early to also traverse for example the $defs
      }

      return recursive(Object.values(obj));
    };

    recursive(temp);

    return temp;
  }

  // We don't support marking properties as optional in $defs yet
  private makePartialSchema(
    schema: JsonSchemaDefinition,
    options: MakePartialSchemaOptions,
  ): JsonSchemaDefinition {
    const temp = cloneDeep(schema);

    const recursive = (
      obj: Record<string, unknown>,
      key: string,
      level = -1, // to account for the first recursive call with wrapped object
    ): void => {
      const value = obj[key];

      if (!isObject(value)) {
        return;
      }

      if (this.doesLeafContainProperties(value)) {
        Object.entries(value.properties).forEach(([key]) => {
          recursive(
            value.properties as Record<string, unknown>,
            key,
            level + 1,
          );
        });
      }
      if (this.doesLeafContainArrayItems(value)) {
        recursive(value, "items", level + 1);
      }

      // Level 0 is the original schema root, which must not be partial
      if (level === 0 || (options.levels && level > options.levels)) {
        return;
      }

      obj[key] = {
        anyOf: [
          {
            type: options.placeholderType,
            description:
              "Use this as a placeholder for unchanged values according to the instructions",
          },
          value,
        ],
      } satisfies Pick<JsonSchemaDefinition, "anyOf">;
    };

    recursive({ root: temp }, "root");

    return temp;
  }

  private doesLeafContainProperties(
    obj: object,
  ): obj is SetRequired<JsonSchemaDefinition, "properties"> {
    if (
      "type" in obj &&
      obj.type === "object" &&
      "properties" in obj &&
      isObject(obj.properties)
    ) {
      return true;
    }

    return false;
  }

  private doesLeafContainArrayItems(
    obj: object,
  ): obj is SetRequired<JsonSchemaDefinition, "items"> {
    if (
      "type" in obj &&
      obj.type === "array" &&
      "items" in obj &&
      isObject(obj.items)
    ) {
      return true;
    }

    return false;
  }
}

export class OutputJsonSchema<T extends AnyJsonOutput> extends JsonSchema<
  WrappedOutputType<T>
> {
  constructor(props: Omit<JsonSchemaProps<T>, "wrappedResponseKey">) {
    const key = WrappedResponseKey;
    const { $defs } = props.schema;
    delete props.schema.$defs;
    // Wrap the schema in an object to support top-level arrays
    const wrappedSchema: JsonSchemaDefinition<WrappedOutputType<T>> = {
      type: "object",
      properties: {
        [key]: props.schema,
      },
      // We have to hoist the $defs to the top level to not mess the reference paths
      $defs,
    };
    super({
      ...props,
      schema: wrappedSchema,
      wrappedResponseKey: key,
    });
  }
}
