import type { ReactNode } from "react";

import { Stack } from "@mui/material";

type AuthLayoutProps = {
  children: ReactNode;
  topBar?: ReactNode;
};

export const AuthLayout = ({ children, topBar }: AuthLayoutProps) => {
  return (
    <Stack alignItems="center">
      <Stack
        component="main"
        direction="row"
        sx={{
          minHeight: "100vh",
          width: "100%",
          flexWrap: ["wrap", "nowrap"],
        }}
      >
        <Stack flex={4} alignItems="center" sx={{ p: 5 }} gap={8}>
          <Stack sx={{ minHeight: 32, width: "100%" }}>{topBar}</Stack>
          <Stack flex={1} sx={{ maxWidth: 456, width: "100%", minWidth: 300 }}>
            {children}
          </Stack>
        </Stack>
        <Stack
          component="aside"
          flex={5}
          sx={{
            height: "100vh",
            width: 758,
            minWidth: 300,
            position: ["relative", "sticky"],
            top: 0,
          }}
        >
          <video
            poster="/static/video/auth/poster.jpg"
            width="100%"
            height="100%"
            style={{ objectFit: "cover", pointerEvents: "none" }}
            autoPlay
            muted
            loop
            controls={false}
            disablePictureInPicture
            disableRemotePlayback
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
