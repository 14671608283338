import {
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  type ReactNode,
} from "react";

import { Stack, Typography } from "@mui/material";
import { pick, uniq } from "lodash-es";

import { Spinner } from "@ll-web/components/Spinner/Spinner";
import ForumIcon from "@ll-web/features/projectComments/assets/icons/forum.svg";
import type {
  ProjectComment,
  ProjectCommentMetadata,
} from "@ll-web/features/projectComments/types";
import type { TextEditorEditCommentMode } from "@ll-web/features/textEditor/comments/types";
import { type TextEditorEditMode } from "@ll-web/features/textEditor/types";

import { CommentSidebarThread } from "./CommentSidebarThread";
import { CommentsSidebarHeader } from "./CommentsSidebarHeader";

const commentSidebarWidth = 400;

type CommentsSidebarProps = {
  threads: ProjectComment[] | undefined | null;
  title?: string;
  close?: () => void;
  _onToggleShowResolved?: (action: "enable" | "disable") => void;
  sidebarMarginFromTop?: string;
  sidebarMarginFromBottom?: string;
  bottomSlot?: ReactNode;
};

export const CommentsSidebar = ({
  threads,
  close,
  _onToggleShowResolved,
  title = "All comments",
  sidebarMarginFromTop = "64px",
  sidebarMarginFromBottom = "64px",
  bottomSlot,
}: CommentsSidebarProps) => {
  const [isShowingResolved, setIsShowingResolved] = useState(false);
  const [loadedThreads, setLoadedThreads] = useState<string[]>([]);
  const sidebarRef = useRef<HTMLDivElement>(null);

  const relevantThreads = threads?.filter(
    (thread) => !thread.isResolved || isShowingResolved,
  );

  const [editMode, setEditMode] =
    useState<TextEditorEditCommentMode>("viewComment");

  const handleFinishEdit = useCallback(() => {
    setEditMode("viewComment");
  }, []);

  const handleEditModeChange = useCallback(
    (editMode: TextEditorEditMode) => {
      setEditMode(editMode === "hover" ? "viewComment" : editMode);
    },
    [setEditMode],
  );

  const handleThreadLoad = useCallback(
    (threadId: string) => {
      setLoadedThreads((prevThreads) => uniq([...prevThreads, threadId]));
    },
    [setLoadedThreads],
  );

  const handleToggleResolved = useCallback(() => {
    _onToggleShowResolved?.(isShowingResolved ? "disable" : "enable");
    setIsShowingResolved(!isShowingResolved);
  }, [_onToggleShowResolved, isShowingResolved]);

  useLayoutEffect(() => {
    // keep scrolled to the top since most recent comment is at the top
    if (
      sidebarRef.current &&
      loadedThreads.length <= (relevantThreads?.length ?? -1)
    ) {
      sidebarRef.current.scrollTop = 0;
    }
  }, [loadedThreads.length, relevantThreads?.length]);

  return (
    <Stack
      component="aside"
      sx={{
        minWidth: commentSidebarWidth,
        width: commentSidebarWidth,
      }}
    >
      <Stack
        sx={{
          boxShadow: `0px 1px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)`,
          height: "100%",
          width: commentSidebarWidth,
          position: "fixed",
          right: 0,
          paddingBottom: `calc(${sidebarMarginFromTop} + ${sidebarMarginFromBottom})`,
          backgroundColor: (theme) => theme.palette.backgroundPrimary,
        }}
      >
        <CommentsSidebarHeader
          title={title}
          close={close}
          isShowingResolved={isShowingResolved}
          onToggleResolved={handleToggleResolved}
        />

        <Stack
          ref={sidebarRef}
          sx={{
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          {relevantThreads?.length ? (
            relevantThreads.map((threadFoundingComment, index) => {
              const threadId = threadFoundingComment.id;
              const threadMetadata: ProjectCommentMetadata = pick(
                threadFoundingComment,
                ["analyticsMetadata", "target"],
              );

              return (
                <CommentSidebarThread
                  key={threadId}
                  threadId={threadId}
                  threadMetadata={threadMetadata}
                  editMode={editMode}
                  onEditModeChange={handleEditModeChange}
                  onFinish={handleFinishEdit}
                  onThreadLoad={handleThreadLoad}
                  showBorderTop={index !== 0}
                />
              );
            })
          ) : (
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {!relevantThreads ? (
                <Spinner />
              ) : (
                <>
                  <ForumIcon />
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    No comments
                  </Typography>
                </>
              )}
            </Stack>
          )}
        </Stack>
        {bottomSlot}
      </Stack>
    </Stack>
  );
};
