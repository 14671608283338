import type { BaseEditor, BaseRange, BaseText } from "slate";
import type { HistoryEditor } from "slate-history";
import type { ReactEditor } from "slate-react";

import type {
  CommentElement,
  TextEditorCommentsPluginConfig,
  TextEditorEditCommentMode,
} from "./comments/types";

export type TextEditorEditMode = "hover" | TextEditorEditCommentMode;

type DecoratorProps = {
  keepSelected?: boolean;
};

export enum TextEditorPluginsEnum {
  History = "history",
  Comments = "comments",
}

export enum TextEditorElementEnum {
  Paragraph = "paragraph",
  Comment = "comment",
}

type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

type CustomText = BaseText & DecoratorProps;

type CustomRange = BaseRange & DecoratorProps;

export type ParagraphElement = {
  type: TextEditorElementEnum.Paragraph;
  children: CustomText[];
};

export type TextEditorConfig = {
  plugins: Partial<Record<TextEditorPluginsEnum, boolean>>;
};

export type TextEditorPluginsConfig = {
  [TextEditorPluginsEnum.Comments]: TextEditorCommentsPluginConfig;
};

type CustomElement = ParagraphElement | CommentElement;

declare module "slate" {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText;
    Range: CustomRange;
  }
}

export type TextEditorInputType = "plaintext" | "pseudoMarkdown";
