import { useCallback } from "react";

import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { userGroupSwitch } from "@ll-web/features/auth/utils/userGroupSwitch";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { makeWizardPath } from "@ll-web/features/projectWizard/utils/navigation";

import { DashboardUserGroupMap, Pages } from "./pages";

export const useHomepagePagePath = () => {
  const { activeUser } = useActiveUser();
  const projectsPagePath = userGroupSwitch({
    user: activeUser,
    map: DashboardUserGroupMap,
  });

  return projectsPagePath;
};

export const useProjectHomepagePath = () => {
  const projectSpacePagePath = useCallback((id: string) => {
    return Pages.ProjectSpace.replace(":id", id);
  }, []);

  return projectSpacePagePath;
};

export const useProjectDetailsPath = () => {
  const flags = useAppFlags();

  const projectDetailsPath = useCallback(
    (id: string, isCreativeBriefInputFilled?: boolean) => {
      const path = (() => {
        if (
          isCreativeBriefInputFilled === false &&
          flags[FeatureFlagName.NewWizardFlow]
        ) {
          return makeWizardPath(id, WizardFlowType.CreativeBrief);
        }

        if (!flags[FeatureFlagName.ProjectSpace]) {
          return makeWizardPath(id);
        }

        return Pages.ProjectSpace;
      })();

      return path.replace(":id", id);
    },
    [flags],
  );

  return projectDetailsPath;
};
