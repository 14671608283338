import { useLayoutEffect, useRef } from "react";

import { Stack, type StackProps } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { ErrorMessage } from "@ll-web/components/errors/ErrorMessage";

export const CommentErrorInfo = (props: StackProps) => {
  const { formState } = useFormContext();

  const errorContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (formState.errors.message?.message && errorContainerRef.current) {
      errorContainerRef.current.scrollIntoView(false);
    }
  }, [formState.errors.message?.message]);

  return formState.isDirty && !formState.isValid ? (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-forcefocus={true}
      ref={errorContainerRef}
      {...props}
    >
      <ErrorMessage error={formState.errors.message?.message} />
    </Stack>
  ) : null;
};
