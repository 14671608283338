import type { AxiosRequestConfig } from "axios";
import {
  addDoc,
  arrayRemove,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { groupBy, maxBy, omit } from "lodash-es";

import { MAX_BACKEND_TIMEOUT } from "@ll-web/core/api/consts";
import { httpClient } from "@ll-web/core/api/HttpClient";
import { dataWithIdConverterFactory } from "@ll-web/core/firebase/converters";
import { firestore } from "@ll-web/core/firebase/firebaseService";
import { FirestoreCollections } from "@ll-web/core/firebase/types";
import { AbortError } from "@ll-web/features/llm/async/errors";
import { llmService } from "@ll-web/features/llm/async/LlmService";
import { CharacterCombinedDescriptionPrompt } from "@ll-web/features/llm/prompts/CharacterCombinedDescription/prompt";
import type { CharacterCombinedDescriptionOutput } from "@ll-web/features/llm/prompts/CharacterCombinedDescription/types";
import type { VideoSummaryOutput } from "@ll-web/features/llm/prompts/VideoSummary/types";
import { ProjectSubCollections } from "@ll-web/features/projects/enums";
import type { ProjectCharacter } from "@ll-web/features/projects/types";
import {
  ProjectAiOutputSubcollections,
  type AddCharacterArgs,
  type AddInterviewArgs,
  type CharacterCombinedDescriptionParams,
  type CharactersOutputArchive,
  type CreateApprovalDto,
  type CreateReviewDto,
  type GetReviewArgs,
  type GetVoiceoverOutputByIdArgs,
  type InterviewInfo,
  type InterviewsOutputArchive,
  type LookAndFeelImagePayload,
  type LookAndFeelImageResponse,
  type OverrideStoryboardImagePayload,
  type ProjectCharacterAndVideoIdParams,
  type ProjectIdAndInterviewIdParams,
  type ProjectIdAndProductionDayIdParams,
  type ProjectIdAndScheduleIdAndProductionDayIdParams,
  type ProjectIdAndVideoIdParams,
  type ProjectIdAndVideoIdsParams,
  type ProjectIdParams,
  type RemoveCharacterArgs,
  type ReviewResourceResponse,
  type ScheduleOutputArchive,
  type ScriptedScriptOutputArchive,
  type ScriptedVideoSummaryOutputArchive,
  type StoryboardImageResponse,
  type UpdateCharacterArgs,
  type UpdatedOutputArchivePayload,
  type UpdateInterviewArgs,
  type UpdateVisualsInputsArgs,
  type UpdateVoiceoverInputArgs,
  type VideoSummaryOutputArchive,
  type VisualsInputs,
  type VisualsOutputArchive,
  type VoiceoverInput,
  type VoiceoverOutputArchive,
  type WardrobeImagePayload,
  type WardrobeImageResponse,
} from "@ll-web/features/projectWizard/types";
import { stripHiddenOutputContent } from "@ll-web/features/projectWizard/utils/outputText";
import { assertDefined, defined } from "@ll-web/utils/types/types";

import { interviewInputConverter } from "./converters";

// Project Wizard data operations (wizard form inputs and AI outputs)
class ProjectWizardService {
  public async getVideoSummaryOutputsForProject({
    projectId,
    videoIds,
  }: ProjectIdAndVideoIdsParams): Promise<VideoSummaryOutput[] | null> {
    const subcollectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.VideoSummary,
    );

    const result = await getDocs(
      query(subcollectionRef, where("videoId", "in", videoIds)),
    );
    const videoSummaries = result.docs.map((doc) => ({
      ...(doc.data() as VideoSummaryOutputArchive),
      id: doc.id,
    }));

    const latestVideoOutputsByVideoId = Object.values(
      groupBy(videoSummaries, "videoId"),
    )
      .map((videoOutput) => maxBy(videoOutput, (v) => v.createdAt))
      .filter(defined)
      .map((v) => v.output);

    return latestVideoOutputsByVideoId;
  }

  public async getLatestVideoSummaryVideoId({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<
    VideoSummaryOutputArchive | ScriptedVideoSummaryOutputArchive | null
  > {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.VideoSummary,
    );

    // NOTE: This query requires an index
    const result = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        orderBy("createdAt", "desc"),
        limit(1),
      ),
    );

    const [latestVideoSummary] = result.docs;

    if (!latestVideoSummary?.exists()) {
      return null;
    }

    const updatedData = latestVideoSummary.data() as VideoSummaryOutputArchive;

    return {
      ...updatedData,
      id: latestVideoSummary.id,
    };
  }

  public async getLatestCharacterOutputByCharacterAndVideoId({
    projectId,
    videoId,
    characterId,
  }: ProjectCharacterAndVideoIdParams): Promise<CharactersOutputArchive | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Characters,
    ).withConverter(dataWithIdConverterFactory<CharactersOutputArchive>());

    const result = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        where("characterId", "==", characterId),
        orderBy("createdAt", "desc"),
        limit(1),
      ),
    );

    const [latestCharacterOutput] = result.docs;

    if (!latestCharacterOutput?.exists()) {
      return null;
    }

    return latestCharacterOutput.data();
  }

  public async getVideoSummaryOutputs({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<VideoSummaryOutputArchive[]> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.VideoSummary,
    ).withConverter(dataWithIdConverterFactory<VideoSummaryOutputArchive>());

    const result = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        orderBy("createdAt", "desc"),
      ),
    );

    return result.docs.map((doc) => doc.data());
  }

  public async getLatestInterviewOutputByInterviewId({
    projectId,
    interviewId,
  }: ProjectIdAndInterviewIdParams): Promise<InterviewsOutputArchive | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Interviews,
    );

    const result = await getDocs(
      query(
        collectionRef,
        where("interviewId", "==", interviewId),
        orderBy("createdAt", "desc"),
        limit(1),
      ),
    );

    const [lastestInterview] = result.docs;

    if (!lastestInterview?.exists()) {
      return null;
    }

    const updatedData = lastestInterview.data() as InterviewsOutputArchive;

    return {
      ...updatedData,
      id: lastestInterview.id,
    };
  }

  public async getInterviewsOutputs({
    projectId,
    interviewId,
  }: ProjectIdAndInterviewIdParams): Promise<InterviewsOutputArchive[]> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Interviews,
    ).withConverter(dataWithIdConverterFactory<InterviewsOutputArchive>());

    const result = await getDocs(
      query(
        collectionRef,
        where("interviewId", "==", interviewId),
        orderBy("createdAt", "desc"),
      ),
    );

    return result.docs.map((doc) => doc.data());
  }

  public async getLatestScheduleOutputByProjectId({
    projectId,
    productionDayId,
  }: ProjectIdAndProductionDayIdParams): Promise<ScheduleOutputArchive | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Schedule,
    );

    const result = await getDocs(
      query(
        collectionRef,
        where("productionDayId", "==", productionDayId),
        orderBy("createdAt", "desc"),
        limit(1),
      ),
    );

    const [latestSchedule] = result.docs;

    if (!latestSchedule?.exists()) {
      return null;
    }

    const updatedData = latestSchedule.data() as ScheduleOutputArchive;

    return {
      ...updatedData,
      id: latestSchedule.id,
    };
  }

  public async getScheduleOutputs({
    projectId,
    productionDayId,
  }: ProjectIdAndProductionDayIdParams): Promise<ScheduleOutputArchive[]> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Schedule,
    ).withConverter(dataWithIdConverterFactory<ScheduleOutputArchive>());

    const result = await getDocs(
      query(
        collectionRef,
        where("productionDayId", "==", productionDayId),
        orderBy("createdAt", "desc"),
      ),
    );

    return result.docs.map((doc) => doc.data() as ScheduleOutputArchive);
  }

  public async saveVideoSummaryOutput<
    T extends VideoSummaryOutputArchive | ScriptedVideoSummaryOutputArchive,
  >({ projectId, ...archive }: T & ProjectIdAndVideoIdParams): Promise<T> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.VideoSummary,
    );

    const docRef = await addDoc(collectionRef, archive);

    const data = (await getDoc(docRef)).data() as T;

    return {
      ...data,
      id: docRef.id,
    };
  }

  public async updateVideoSummaryOutput({
    projectId,
    id: outputId,
    ...archive
  }: UpdatedOutputArchivePayload<
    VideoSummaryOutputArchive | ScriptedVideoSummaryOutputArchive
  > &
    ProjectIdParams) {
    assertDefined(outputId);

    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.VideoSummary,
      outputId,
    );

    await updateDoc(docRef, {
      output: archive.output,
    });
  }

  public async saveInterviewOutput({
    projectId,
    ...archive
  }: InterviewsOutputArchive &
    ProjectIdAndInterviewIdParams): Promise<InterviewsOutputArchive> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Interviews,
    );

    const docRef = await addDoc(collectionRef, archive);

    const data = (await getDoc(docRef)).data() as InterviewsOutputArchive;

    return {
      ...data,
      id: docRef.id,
    };
  }

  public async updateInterviewOutput<T extends InterviewsOutputArchive>({
    projectId,
    id: outputId,
    interviewId: _,
    ...archive
  }: UpdatedOutputArchivePayload<T> & {
    interviewId: string;
  } & ProjectIdParams) {
    assertDefined(outputId);

    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Interviews,
      outputId,
    );

    await updateDoc(docRef, {
      output: archive.output,
    });
  }

  public async saveScheduleOutput({
    projectId,
    ...archive
  }: ScheduleOutputArchive &
    ProjectIdAndProductionDayIdParams): Promise<ScheduleOutputArchive> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Schedule,
    );

    const docRef = await addDoc(collectionRef, archive);

    const data = (await getDoc(docRef)).data() as ScheduleOutputArchive;

    return {
      ...data,
      id: docRef.id,
    };
  }

  public async updateScheduleOutput<T extends ScheduleOutputArchive>({
    projectId,
    scheduleId,
    ...archive
  }: UpdatedOutputArchivePayload<T> &
    ProjectIdAndScheduleIdAndProductionDayIdParams) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Schedule,
      scheduleId,
    );

    await updateDoc(docRef, {
      output: archive.output,
    });
  }

  public async saveVoiceoverOutput({
    projectId,
    ...archive
  }: VoiceoverOutputArchive &
    ProjectIdAndVideoIdParams): Promise<VoiceoverOutputArchive> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Voiceover,
    );

    const docRef = await addDoc(collectionRef, archive);

    const data = (await getDoc(docRef)).data() as VoiceoverOutputArchive;

    return {
      ...data,
      id: docRef.id,
    };
  }

  public async updateVoiceoverOutput({
    projectId,
    id: outputId,
    ...archive
  }: UpdatedOutputArchivePayload<VoiceoverOutputArchive> &
    ProjectIdAndVideoIdParams): Promise<VoiceoverOutputArchive> {
    assertDefined(outputId, "outputId");

    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Voiceover,
      outputId,
    );

    await updateDoc(docRef, {
      output: archive.output,
    });

    const data = (await getDoc(docRef)).data() as VoiceoverOutputArchive;

    return {
      ...data,
      id: docRef.id,
    };
  }

  public async saveVisualsOutput({
    projectId,
    ...archive
  }: VisualsOutputArchive &
    ProjectIdAndVideoIdParams): Promise<VisualsOutputArchive> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Visuals,
    );

    const docRef = await addDoc(collectionRef, archive);

    const data = (await getDoc(docRef)).data() as VisualsOutputArchive;

    return {
      ...data,
      id: docRef.id,
    };
  }

  public async updateVisualsOutput({
    projectId,
    id: outputId,
    ...archive
  }: UpdatedOutputArchivePayload<VisualsOutputArchive> &
    ProjectIdAndVideoIdParams) {
    assertDefined(outputId, "outputId");

    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Visuals,
      outputId,
    );

    await updateDoc(docRef, {
      output: archive.output,
    });
  }

  public async saveScriptedScriptOutput({
    projectId,
    ...archive
  }: ScriptedScriptOutputArchive &
    ProjectIdAndVideoIdParams): Promise<ScriptedScriptOutputArchive> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.ScriptedScript,
    );

    const docRef = await addDoc(collectionRef, archive);

    const data = (await getDoc(docRef)).data() as ScriptedScriptOutputArchive;

    return {
      ...data,
      id: docRef.id,
    };
  }

  public async updateScriptedScriptOutput({
    projectId,
    id: outputId,
    ...archive
  }: UpdatedOutputArchivePayload<ScriptedScriptOutputArchive> &
    ProjectIdParams) {
    assertDefined(outputId, "outputId");

    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.ScriptedScript,
      outputId,
    );

    await updateDoc(docRef, {
      output: archive.output,
    });
  }

  public async saveCharactersOutput({
    projectId,
    ...archive
  }: CharactersOutputArchive &
    ProjectCharacterAndVideoIdParams): Promise<CharactersOutputArchive> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Characters,
    );

    const docRef = await addDoc(collectionRef, archive);

    const data = (await getDoc(docRef)).data() as CharactersOutputArchive;

    return {
      ...data,
      id: docRef.id,
    };
  }

  public async updateCharactersOutput({
    projectId,
    id: outputId,
    ...archive
  }: UpdatedOutputArchivePayload<CharactersOutputArchive> & ProjectIdParams) {
    assertDefined(outputId, "outputId");

    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Characters,
      outputId,
    );

    await updateDoc(docRef, {
      output: archive.output,
    });
  }

  public async addInterview({
    projectId,
    ...payload
  }: AddInterviewArgs): Promise<InterviewInfo> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.InterviewsInputs,
    ).withConverter(interviewInputConverter);

    const defaultInterview: InterviewInfo = {
      fullName: "",
      description: "",
      messages: "",
      createdAt: new Date(),
      ...payload,
    };

    const docRef = await addDoc(collectionRef, defaultInterview);

    const data = (await getDoc(docRef)).data();

    assertDefined(data, "data");

    return data;
  }

  public async getInterviews({
    projectId,
  }: ProjectIdParams): Promise<InterviewInfo[]> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.InterviewsInputs,
    ).withConverter(interviewInputConverter);

    const result = await getDocs(
      query(
        collectionRef,
        orderBy("createdAt", "asc"),
        orderBy("order", "asc"),
      ),
    );

    return result.docs.map((doc) => doc.data());
  }

  public async updateInterview({
    projectId,
    interviewId,
    updatePayload,
  }: UpdateInterviewArgs) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.InterviewsInputs,
      interviewId,
    ).withConverter(interviewInputConverter);
    await setDoc(docRef, omit(updatePayload, "id"), { merge: true });

    const data = (await getDoc(docRef)).data();

    return data;
  }

  public async removeInterview({
    projectId,
    interviewId,
  }: ProjectIdAndInterviewIdParams): Promise<void> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.InterviewsInputs,
      interviewId,
    ).withConverter(interviewInputConverter);

    await deleteDoc(docRef);
  }

  public async removeIntervieweesFromProductionDays({
    projectId,
    interviewId,
  }: ProjectIdAndInterviewIdParams): Promise<void> {
    const subcollectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.ProductionDays,
    );

    const snapshot = await getDocs(query(subcollectionRef));

    const docsWithInterviewIdInQuestion = snapshot.docs.filter((doc) =>
      doc.data()?.interviewees?.includes(interviewId),
    );

    const docsUpdatePromises = docsWithInterviewIdInQuestion.map(({ id }) => {
      const docRef = doc(
        firestore,
        FirestoreCollections.Projects,
        projectId,
        ProjectSubCollections.ProductionDays,
        id,
      );

      return updateDoc(docRef, {
        interviewees: arrayRemove(interviewId),
      });
    });

    await Promise.all(docsUpdatePromises);
  }

  public async getLatestVoiceoverByVideoId({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<VoiceoverOutputArchive | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Voiceover,
    );

    // NOTE: This query requires an index
    const result = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        orderBy("createdAt", "desc"),
        limit(1),
      ),
    );

    const [latestVoiceover] = result.docs;

    if (!latestVoiceover?.exists()) {
      return null;
    }

    const updatedData = latestVoiceover.data() as VoiceoverOutputArchive;

    return {
      ...updatedData,
      id: latestVoiceover.id,
    };
  }

  public async getVoiceoverOutputById({
    projectId,
    voiceoverOutputId,
  }: GetVoiceoverOutputByIdArgs): Promise<VoiceoverOutputArchive> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Voiceover,
    ).withConverter(dataWithIdConverterFactory<VoiceoverOutputArchive>());

    const docRef = doc(collectionRef, voiceoverOutputId);
    const data = (await getDoc(docRef)).data();

    if (!data) {
      throw new Error("We are sorry, but the voiceover was not found");
    }

    return data;
  }

  public async getVoiceoverOutputs({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<VoiceoverOutputArchive[]> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Voiceover,
    ).withConverter(dataWithIdConverterFactory<VoiceoverOutputArchive>());

    const result = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        orderBy("createdAt", "desc"),
      ),
    );

    return result.docs.map((doc) => doc.data() as VoiceoverOutputArchive);
  }

  public async getVoiceoverInputByVideoId({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<VoiceoverInput | null> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.VoiceoverInputs,
      videoId,
    );

    const data = (await getDoc(docRef)).data() as VoiceoverInput;
    if (!data) {
      return null;
    }

    return data;
  }

  public async updateVoiceoverInput({
    projectId,
    videoId,
    ...payload
  }: UpdateVoiceoverInputArgs) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.VoiceoverInputs,
      videoId,
    );
    await setDoc(docRef, { ...payload }, { merge: true });

    const data = (await getDoc(docRef)).data();

    return data;
  }

  public async getLatestVisualsOutputByVideoId({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<VisualsOutputArchive | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Visuals,
    );

    // NOTE: This query requires an index
    const result = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        orderBy("createdAt", "desc"),
        limit(1),
      ),
    );

    const [latestVisuals] = result.docs;

    if (!latestVisuals?.exists()) {
      return null;
    }

    const updatedData = latestVisuals.data() as VisualsOutputArchive;

    return {
      ...updatedData,
      id: latestVisuals.id,
    };
  }

  public async getVisualsOutputs({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<VisualsOutputArchive[]> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.Visuals,
    ).withConverter(dataWithIdConverterFactory<VisualsOutputArchive>());

    const result = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        orderBy("createdAt", "desc"),
      ),
    );

    return result.docs.map((doc) => doc.data());
  }

  public async getVisualsInputsByVideoId({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<VisualsInputs | null> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.VisualsInputs,
      videoId,
    );

    const data = (await getDoc(docRef)).data() as VisualsInputs;
    if (!data?.takeaways) {
      return null;
    }

    return data;
  }

  public async updateVisualsInputs({
    projectId,
    videoId,
    ...payload
  }: UpdateVisualsInputsArgs) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.VisualsInputs,
      videoId,
    );
    await setDoc(docRef, { ...payload }, { merge: true });

    const data = (await getDoc(docRef)).data();

    return data;
  }

  public async getLatestScriptedScriptOutputByVideoId({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<ScriptedScriptOutputArchive | null> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.ScriptedScript,
    );

    const result = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        orderBy("createdAt", "desc"),
        limit(1),
      ),
    );

    const [latestArchive] = result.docs;

    if (!latestArchive?.exists()) {
      return null;
    }

    const updatedData = latestArchive.data() as ScriptedScriptOutputArchive;

    return {
      ...updatedData,
      id: latestArchive.id,
    };
  }

  public async getScriptedScriptOutputs({
    projectId,
    videoId,
  }: ProjectIdAndVideoIdParams): Promise<ScriptedScriptOutputArchive[]> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectAiOutputSubcollections.ScriptedScript,
    ).withConverter(dataWithIdConverterFactory<ScriptedScriptOutputArchive>());

    const querySnapshot = await getDocs(
      query(
        collectionRef,
        where("videoId", "==", videoId),
        orderBy("createdAt", "desc"),
      ),
    );

    return querySnapshot.docs.map((doc) => doc.data());
  }

  public async addCharacter({
    projectId,
    ...character
  }: AddCharacterArgs): Promise<void> {
    const collectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Characters,
    );

    await addDoc(collectionRef, {
      ...character,
    });
  }

  public async updateCharacter({
    projectId,
    id,
    ...character
  }: UpdateCharacterArgs): Promise<void> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Characters,
      id,
    );

    return await updateDoc(docRef, character);
  }

  public async removeCharacter({
    id,
    projectId,
  }: RemoveCharacterArgs): Promise<void> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.Characters,
      id,
    ).withConverter(dataWithIdConverterFactory<ProjectCharacter>());

    await deleteDoc(docRef);
  }

  async overrideStoryboardImage(
    args: OverrideStoryboardImagePayload,
    requestConfig?: AxiosRequestConfig,
  ): Promise<StoryboardImageResponse> {
    return await httpClient.unwrappedHttpRequest<StoryboardImageResponse>({
      config: {
        ...requestConfig,
        method: "POST",
        url: `/v1/storyboards/frames/overrides`,
        data: args,
        timeout: MAX_BACKEND_TIMEOUT,
      },
    });
  }

  async getLookAndFeelImage(
    args: LookAndFeelImagePayload,
    requestConfig?: AxiosRequestConfig,
  ): Promise<LookAndFeelImageResponse> {
    return await httpClient.unwrappedHttpRequest<LookAndFeelImageResponse>({
      config: {
        ...requestConfig,
        method: "POST",
        url: `/v1/look-and-feel/images`,
        data: args,
        timeout: MAX_BACKEND_TIMEOUT,
      },
    });
  }

  async getWardrobeImage(
    args: WardrobeImagePayload,
    requestConfig?: AxiosRequestConfig,
  ): Promise<WardrobeImageResponse> {
    return await httpClient.unwrappedHttpRequest<WardrobeImageResponse>({
      config: {
        ...requestConfig,
        method: "POST",
        url: `/v1/wardrobe/images`,
        data: args,
        timeout: MAX_BACKEND_TIMEOUT,
      },
    });
  }

  public async updateClientFirstViewedAt({ projectId }: ProjectIdParams) {
    const docRef = doc(firestore, FirestoreCollections.Projects, projectId);

    await updateDoc(docRef, {
      clientFirstViewedAt: serverTimestamp(),
    });
  }

  public async updateClientFirstFinishedPreproductionAt({
    projectId,
  }: ProjectIdParams) {
    const docRef = doc(firestore, FirestoreCollections.Projects, projectId);

    await updateDoc(docRef, {
      clientFirstFinishedPreproductionAt: serverTimestamp(),
    });
  }

  public async updateSuccessfullyFirstFinalizedPreproductionAt({
    projectId,
  }: ProjectIdParams) {
    const docRef = doc(firestore, FirestoreCollections.Projects, projectId);

    await updateDoc(docRef, {
      successfullyFirstFinalizedPreproductionAt: serverTimestamp(),
    });
  }

  public async getCharacterCombinedDescription(
    { projectId, character }: CharacterCombinedDescriptionParams,
    { signal }: { signal?: AbortSignal } = {},
  ): Promise<CharacterCombinedDescriptionOutput> {
    if (!character.videoIds?.length) {
      throw new Error(
        "We are sorry, but this character is not assigned to any videos",
      );
    }

    const characterOutputs = (
      await Promise.all(
        character.videoIds.map((videoId) =>
          this.getLatestCharacterOutputByCharacterAndVideoId({
            projectId,
            videoId,
            characterId: character.id,
          }),
        ),
      )
    )
      .filter((archive) => archive?.output?.summary)
      .map((archive) => archive!.output)
      .map((output) => stripHiddenOutputContent(output));

    if (!characterOutputs.length) {
      throw new Error(
        "We are sorry, but this character doesn's have any role descriptions generated",
      );
    }

    if (signal?.aborted) {
      throw new AbortError(signal.reason);
    }

    const prompt = new CharacterCombinedDescriptionPrompt({
      characterSummaries: characterOutputs,
    });

    const result = await llmService.jsonCompletion(prompt, {
      abortSignal: signal,
      useCache: true,
    });
    const output = result.output.json as CharacterCombinedDescriptionOutput;
    if (!output.keyInfo || !output.summary) {
      throw new Error("We are sorry, but something went wrong");
    }

    return output;
  }

  public async getReview(
    args: GetReviewArgs,
    requestConfig?: AxiosRequestConfig,
  ): Promise<ReviewResourceResponse> {
    return await httpClient.unwrappedHttpRequest<ReviewResourceResponse>({
      config: {
        ...requestConfig,
        method: "GET",
        url: `/v1/reviews/${args.reviewKey}`,
        data: args,
      },
    });
  }

  public async requestReview(
    args: CreateReviewDto,
    requestConfig?: AxiosRequestConfig,
  ): Promise<ReviewResourceResponse> {
    return await httpClient.unwrappedHttpRequest<ReviewResourceResponse>({
      config: {
        ...requestConfig,
        method: "POST",
        url: `/v1/reviews/requests`,
        data: args,
      },
    });
  }

  public async approveReview(
    args: CreateApprovalDto,
    requestConfig?: AxiosRequestConfig,
  ): Promise<ReviewResourceResponse> {
    return await httpClient.unwrappedHttpRequest<ReviewResourceResponse>({
      config: {
        ...requestConfig,
        method: "POST",
        url: `/v1/reviews/approvals`,
        data: args,
      },
    });
  }
}

export const projectWizardService = new ProjectWizardService();
