import type { ProjectComment } from "@ll-web/features/projectComments/types";
import { mapUnknownToDayjs } from "@ll-web/utils/helpers/date";
import { defined } from "@ll-web/utils/types/types";

export const videoCommentsComparator = (
  a: ProjectComment,
  b: ProjectComment,
) => {
  if (defined(a.target.timestamp) && defined(b.target.timestamp)) {
    return Math.sign(a.target.timestamp - b.target.timestamp);
  }

  if (!a.target.timestamp && !b.target.timestamp) {
    const dateA = mapUnknownToDayjs(a.createdAt);
    const dateB = mapUnknownToDayjs(b.createdAt);
    if (dateA?.isSame(dateB)) {
      return 0;
    } else {
      return dateA?.isBefore(dateB) ? -1 : 1;
    }
  }

  if (a.target.timestamp) {
    return -1;
  }
  if (b.target.timestamp) {
    return 1;
  }

  return 0;
};
