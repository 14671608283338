import { httpClient } from "@ll-web/core/api/HttpClient";
import type {
  CreateDeliverableDto,
  Deliverable,
  DeliverableByKeyParams,
  Deliverables,
  DeliverableVersion,
} from "@ll-web/features/postProductionMedia/types";

class PostProductionMediaService {
  async getDeliverableByKey(
    params: DeliverableByKeyParams,
  ): Promise<Deliverable> {
    return httpClient.unwrappedHttpRequest<Deliverable>({
      config: {
        method: "GET",
        url: `/v1/projects/${params.projectId}/deliverables/${params.deliverableKey}`,
      },
    });
  }

  async getDeliverables(params: { projectId: string }): Promise<Deliverables> {
    return httpClient.unwrappedHttpRequest<Deliverables>({
      config: {
        method: "GET",
        url: `/v1/projects/${params.projectId}/deliverables`,
      },
    });
  }

  async createDeliverableVersion({
    projectId,
    deliverable,
  }: {
    projectId: string;
    deliverable: CreateDeliverableDto;
  }) {
    return httpClient.unwrappedHttpRequest<DeliverableVersion>({
      config: {
        method: "POST",
        url: `/v1/projects/${projectId}/deliverables`,
        data: deliverable,
      },
    });
  }

  async createPublicDeliverableVersion({
    projectId,
    versionId,
    versionName,
  }: {
    projectId: string;
    versionId: string;
    deliverableKey: string;
    versionName: string;
  }) {
    return httpClient.unwrappedHttpRequest<DeliverableVersion>({
      config: {
        method: "POST",
        url: `/v1/projects/${projectId}/deliverables/${versionId}/public`,
        data: {
          versionName,
        },
      },
    });
  }

  async deleteDeliverable(params: DeliverableByKeyParams): Promise<void> {
    return httpClient.unwrappedHttpRequest<void>({
      config: {
        method: "DELETE",
        url: `/v1/projects/${params.projectId}/deliverables/${params.deliverableKey}`,
      },
    });
  }
}

export const postProductionMediaService = new PostProductionMediaService();
