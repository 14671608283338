import { useMemo } from "react";

import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { useBrandUsers } from "@ll-web/features/brands/async/useBrandsQueries";

import { useActiveBrand } from "./useActiveBrand";

export function useActiveBrandUser() {
  const { activeUser } = useActiveUser();
  const activeBrandData = useActiveBrand();
  const brandUsersQuery = useBrandUsers(
    {
      brandId: activeBrandData?.activeBrand?._id ?? "",
    },
    {
      enabled: !!activeBrandData?.activeBrand?._id,
    },
  );

  const activeBrandUser = useMemo(() => {
    return {
      activeBrandUser:
        brandUsersQuery.data?.items?.find(
          (user) => user.userId === activeUser._id,
        ) ?? null,
      isFetched:
        !!activeUser &&
        (!activeBrandData || !!activeBrandData?.isFetched) &&
        brandUsersQuery.isFetched,
      isLoading:
        !activeUser ||
        !!activeBrandData?.isLoading ||
        brandUsersQuery.isPending,
    };
  }, [
    activeUser,
    activeBrandData,
    brandUsersQuery.data,
    brandUsersQuery.isFetched,
    brandUsersQuery.isPending,
  ]);

  if (isUserInternal(activeUser)) {
    return null;
  }

  if (
    !activeBrandData ||
    (activeBrandData.isFetched && !activeBrandData.activeBrand)
  ) {
    return null;
  }

  return activeBrandUser;
}
