import { memo, useCallback, type MouseEvent } from "react";

import { Box, Tooltip } from "@mui/material";

type CommentsOverlayClickTargetProps = {
  onAddTrigger: ({ position }: { position: [number, number] }) => void;
};

export const CommentsOverlayClickTarget = memo(
  ({ onAddTrigger }: CommentsOverlayClickTargetProps) => {
    const handleClick = useCallback(
      (ev: MouseEvent<HTMLDivElement>) => {
        const target = ev.currentTarget;
        const rect = target.getBoundingClientRect();
        const x = (ev.clientX - rect.left) / rect.width;
        const y = (ev.clientY - rect.top) / rect.height;

        onAddTrigger({ position: [x, y] });
      },
      [onAddTrigger],
    );

    return (
      <Tooltip
        title="Click to add comment"
        followCursor
        placement="bottom-start"
      >
        <Box
          className="comments-click-target"
          sx={{
            position: "absolute",
            inset: 0,
          }}
          onClick={handleClick}
        />
        {/* TODO: bubble with arrow */}
      </Tooltip>
    );
  },
);
CommentsOverlayClickTarget.displayName = "CommentsOverlayClickTarget";
