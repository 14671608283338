import type { ElementType, PropsWithChildren } from "react";

import { Stack, type BoxProps } from "@mui/material";

import { NavigationBar } from "@ll-web/components/NavigationBar";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { InternalDashboardLayout } from "@ll-web/features/internalDashboard/components/layout/InternalDashboardLayout";

export const DashboardLayoutContentWrapper = ({
  children,
  ...props
}: BoxProps) => {
  return (
    <Stack
      flexDirection="column"
      alignItems="center"
      {...props}
      sx={{
        width: "100%",
        maxWidth: "100%",
        flexGrow: 1,
        px: "5vw",
        overflowY: "auto",
        ...props.sx,
      }}
      component={"main" as ElementType}
    >
      {children}
    </Stack>
  );
};

export const ExternalDashboardLayout = ({ children, ...props }: BoxProps) => {
  return (
    <>
      <NavigationBar />
      <DashboardLayoutContentWrapper {...props}>
        {children}
      </DashboardLayoutContentWrapper>
    </>
  );
};

// Use this component for pages accessible by both external and internal users (like settings)
export const DashboardLayout = ({ children }: PropsWithChildren) => {
  const { activeUser } = useActiveUser();

  if (isUserInternal(activeUser)) {
    return <InternalDashboardLayout>{children}</InternalDashboardLayout>;
  }

  return (
    <ExternalDashboardLayout sx={{ py: 2 }}>{children}</ExternalDashboardLayout>
  );
};
