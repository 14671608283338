import { ProjectTalentTypeEnum } from "@ll-web/features/projects/enums";
import type { ProjectCharacter } from "@ll-web/features/projects/types";

import type { CharactersInput } from "./talentsInfoDto";
import type { TalentsFormValues } from "./talentsInfoSchema";

export const mapCharactersToTalentsFormValues = (
  characters: ProjectCharacter[],
): CharactersInput => {
  return characters.reduce(
    (acc, character) => {
      const { talentType } = character;

      return {
        ...acc,
        [talentType]: acc[talentType as keyof typeof acc] + 1,
      };
    },
    {
      [ProjectTalentTypeEnum.FEATURE]: 0,
      [ProjectTalentTypeEnum.BACKGROUND]: 0,
      [ProjectTalentTypeEnum.SPEAKING]: 0,
      [ProjectTalentTypeEnum.SPECIALTY]: 0,
    },
  );
};

export const mapTalentsFormValuesToCharacters = (
  talentsFormValues: CharactersInput,
): ProjectCharacter[] => {
  const talentTypes: (keyof CharactersInput)[] = [
    ProjectTalentTypeEnum.FEATURE,
    ProjectTalentTypeEnum.BACKGROUND,
    ProjectTalentTypeEnum.SPEAKING,
    ProjectTalentTypeEnum.SPECIALTY,
  ];

  return talentTypes.flatMap((type) =>
    Array.from({ length: talentsFormValues[type] }, () => {
      return {
        talentType: type,
      } as ProjectCharacter;
    }),
  );
};

export const mapCharacterDtoToForm = (
  characters: ProjectCharacter[],
): TalentsFormValues | undefined => {
  if (!characters) {
    return undefined;
  }

  return mapCharactersToTalentsFormValues(characters);
};
