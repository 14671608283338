import { Pages } from "@ll-web/core/router/pages";
import type { WizardSteps } from "@ll-web/features/projectWizard/consts/wizardSteps";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";

export function makeWizardPath(
  projectId: string,
  flowType: WizardFlowType = WizardFlowType.Generate,
  step?: WizardSteps,
): string {
  return Pages.ProjectWizard.replace(":id", projectId)
    .replace(":flowType", flowType)
    .replace(":step", step ?? "");
}
