import { difference, isEqual, omitBy } from "lodash-es";

import { httpClient } from "@ll-web/core/api/HttpClient";
import {
  BrandRoleEnum,
  type AddProjectToBrand,
  type Brand,
  type BrandAcceptInvitePayload,
  type BrandByIdInvitesArgs,
  type BrandByIdQuery,
  type BrandByIdUsersArgs,
  type BrandCreateInvitePayload,
  type BrandCreateInviteResponse,
  type BrandDeleteInvitePayload,
  type BrandDeleteInviteResponse,
  type BrandInvitation,
  type BrandInviteByTokenArgs,
  type BrandInvitesResponse,
  type BrandProjectData,
  type BrandUser,
  type BrandUserDeletePayload,
  type BrandUsersResponse,
  type BrandWithTeam,
  type CreateBrand,
  type CreateBrandPayload,
  type FindAllBrandsDto,
  type GetBrandsByProjectId,
  type UpdateBrandArg,
  type UpdateBrandPayload,
} from "@ll-web/features/brands/types";
import { defined, type ByIdParams } from "@ll-web/utils/types/types";

class BrandsService {
  async create(createBrand: CreateBrand): Promise<Brand> {
    const {
      name,
      website,
      clientMainContactId,
      producerId,
      companyType,
      accountExecutiveId,
      creativeProducerId,
      editorId,
    } = createBrand;

    const brand = await this.makeCreate({
      name,
      website,
      companyType,
      users: [
        clientMainContactId,
        producerId,
        accountExecutiveId,
        creativeProducerId,
        editorId,
      ].filter(defined),
    });

    return brand;
  }

  private async makeCreate(
    createBrandPayload: CreateBrandPayload,
  ): Promise<Brand> {
    return await httpClient.unwrappedHttpRequest<Brand>({
      config: {
        method: "POST",
        url: "/v1/brands",
        data: createBrandPayload,
      },
    });
  }

  async update({
    id,
    keepExistingEditors = true,
    ...payload
  }: UpdateBrandArg): Promise<Brand> {
    const existingBrand = await this.getBrand({
      brandId: id,
    });

    const patch = omitBy(payload, (value, key) =>
      isEqual(value, existingBrand[key as keyof typeof existingBrand]),
    ) as UpdateBrandPayload;

    if (patch.users) {
      const existingOwner = existingBrand.team.find(
        (brandUser) => brandUser.role === BrandRoleEnum.Owner,
      )?.userId;
      const existingInternalUsers = existingBrand.team
        .filter((brandUser) => brandUser.role === BrandRoleEnum.Internal)
        .map((brandUser) => brandUser.userId);

      const existingUsersToCompare = [
        existingOwner,
        ...existingInternalUsers,
      ].filter(defined);
      const usersDiff = difference(patch.users, existingUsersToCompare);

      if (
        patch.users.length === existingUsersToCompare.length &&
        usersDiff.length === 0
      ) {
        delete patch.users;
      }

      if (patch.users && keepExistingEditors) {
        const existingEditors = existingBrand.team
          .filter((brandUser) => brandUser.role === BrandRoleEnum.Editor)
          .map((brandUser) => brandUser.userId);

        patch.users = [
          ...patch.users,
          ...existingEditors.filter((userId) => !patch.users!.includes(userId)),
        ];
      }
    }

    if (!Object.keys(patch).length) {
      return existingBrand;
    }

    const updatedBrand = await this.makeUpdate({
      id,
      ...patch,
    });

    return updatedBrand;
  }

  private async makeUpdate({
    id,
    ...payload
  }: UpdateBrandPayload & ByIdParams): Promise<Brand> {
    return await httpClient.unwrappedHttpRequest<Brand>({
      config: {
        method: "PATCH",
        url: `/v1/brands/${id}`,
        data: payload,
      },
    });
  }

  // This method is idempotent
  async addProjectToBrand(args: AddProjectToBrand): Promise<BrandProjectData> {
    return await httpClient.unwrappedHttpRequest<BrandProjectData>({
      config: {
        method: "PUT",
        url: `/v1/brands/${args.brandId}/projects/${args.projectId}`,
        data: {},
      },
    });
  }

  async getBrandByProjectId({
    projectId,
  }: GetBrandsByProjectId): Promise<Brand> {
    const brands = await this.findAll({ projectId });

    return brands[0];
  }

  async getBrand(args: BrandByIdQuery): Promise<BrandWithTeam> {
    const brandData = await httpClient.unwrappedHttpRequest<BrandWithTeam>({
      config: {
        method: "GET",
        url: `/v1/brands/${args.brandId}`,
      },
    });

    return brandData;
  }

  async findAll(args: FindAllBrandsDto): Promise<Brand[]> {
    const brands = await httpClient.unwrappedHttpRequest<Brand[]>({
      config: {
        method: "GET",
        url: "/v1/brands",
        params: args,
      },
    });

    return brands;
  }

  async getBrandInvitations(args: BrandByIdInvitesArgs) {
    return await httpClient.unwrappedHttpRequest<BrandInvitesResponse>({
      config: {
        method: "GET",
        url: `/v1/brands/${args.brandId}/invites`,
      },
    });
  }

  async brandInvite({ brandId, ...args }: BrandCreateInvitePayload) {
    return await httpClient.unwrappedHttpRequest<BrandCreateInviteResponse>({
      config: {
        method: "POST",
        url: `/v1/brands/${brandId}/invites`,
        data: args,
      },
    });
  }

  async deleteBrandInvite(args: BrandDeleteInvitePayload) {
    return await httpClient.unwrappedHttpRequest<BrandDeleteInviteResponse>({
      config: {
        method: "DELETE",
        url: `/v1/brands/invites/${args.inviteId}`,
      },
    });
  }

  async getBrandInviteByToken(
    args: BrandInviteByTokenArgs,
  ): Promise<BrandInvitation> {
    return await httpClient.unwrappedHttpRequest<BrandInvitation>({
      config: {
        method: "GET",
        url: `/v1/brands/invites/by-token/${args.token}`,
      },
      withAuth: false,
    });
  }

  async acceptBrandInvite(args: BrandAcceptInvitePayload): Promise<BrandUser> {
    return await httpClient.unwrappedHttpRequest<BrandUser>({
      config: {
        method: "POST",
        url: "/v1/brands/invites/accept",
        data: args,
      },
    });
  }

  async getBrandUsers(args: BrandByIdUsersArgs): Promise<BrandUsersResponse> {
    return await httpClient.unwrappedHttpRequest<BrandUsersResponse>({
      config: {
        method: "GET",
        url: `/v1/brands/${args.brandId}/users`,
      },
    });
  }

  async deleteBrandUser(args: BrandUserDeletePayload) {
    await httpClient.unwrappedHttpRequest<void>({
      config: {
        method: "DELETE",
        url: `/v1/brands/${args.brandId}/users/${args.brandUserId}`,
      },
    });
  }
}

export const brandsService = new BrandsService();
