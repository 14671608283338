import {
  ProjectStatus,
  type ExternalProjectStatus,
} from "@ll-web/features/projects/enums";
import { typedKeys } from "@ll-web/utils/types/types";

import { WizardSteps } from "./wizardSteps";

export const ProjectStatusStepMap: Record<
  ExternalProjectStatus | ProjectStatus,
  WizardSteps
> = {
  [ProjectStatus.VideoSummary]: WizardSteps.VideoSummary,
  [ProjectStatus.Interviews]: WizardSteps.Interviews,
  [ProjectStatus.Schedule]: WizardSteps.Schedule,
  [ProjectStatus.Voiceover]: WizardSteps.Voiceover,
  [ProjectStatus.VoiceoverTts]: WizardSteps.VoiceoverTts,
  [ProjectStatus.Visuals]: WizardSteps.Visuals,
  [ProjectStatus.Finalize]: WizardSteps.Finalize,
  [ProjectStatus.ScriptedVideoSummary]: WizardSteps.ScriptedVideoSummary,
  [ProjectStatus.RoleCasting]: WizardSteps.RoleCasting,
  [ProjectStatus.Characters]: WizardSteps.Characters,
  [ProjectStatus.ScriptedScript]: WizardSteps.ScriptedScript,
  [ProjectStatus.ScriptedTts]: WizardSteps.ScriptedTts,
  [ProjectStatus.Storyboard]: WizardSteps.Storyboard,
  [ProjectStatus.SubmitForReview]: WizardSteps.SubmitForReview,
};

export const ProjectStatusIgnoredSteps: WizardSteps[] = [
  WizardSteps.FinalizeEdit,
];

export const findProjectStatusByWizardStep = (
  wizardStep: WizardSteps,
): ExternalProjectStatus | ProjectStatus | undefined =>
  typedKeys(ProjectStatusStepMap).find(
    (key) => ProjectStatusStepMap[key] === wizardStep,
  ) as ExternalProjectStatus | ProjectStatus | undefined;
