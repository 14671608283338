import type { JSONSchema7 } from "json-schema";

import type { WrappedResponseKey } from "@ll-web/features/llm/const";

export type MessageType = {
  role: "system" | "user" | "assistant";
  content: string;
};

export type TextStreamCallbacks = {
  onUpdate?: (data: {
    fullText: string;
    newText: string;
    messages: MessageType[];
  }) => void;
};

export type JsonStreamCallbacks = Omit<TextStreamCallbacks, "onUpdate"> & {
  onUpdate?: (
    data: Parameters<Required<TextStreamCallbacks>["onUpdate"]>[0] & {
      json: unknown | null;
    },
  ) => void;
};

export type AnyJsonOutput = Record<string, unknown> | unknown[];

export type JsonExampleType<
  T extends AnyJsonOutput = Record<string, unknown> | unknown[],
> = T;

/**
 * @deprecated Please use the new strict JsonSchema with json_schema response format
 */
export type JsonSchemaType<
  T extends AnyJsonOutput = Record<string, unknown> | unknown[],
> = T extends unknown[] // Unknown to allow for additional comments as strings
  ? {
      [K in keyof T[number]]: undefined extends T[number][K]
        ? undefined | unknown
        : unknown;
    }[]
  : { [K in keyof T]: undefined extends T[K] ? undefined | unknown : unknown };

export type MockPayloadType<T> = {
  name: string;
  payload: T;
};

export type ResponseFeedbackType<T = unknown> = {
  // Any context what this feedback is for
  // e.g full AI response, JSON output, modified output.
  // Should contain all information needed to recreate the full original response.
  context: T;
  feedback: string;
};

export type PromptDumpType = {
  metadata?: {
    name?: string;
    version?: string;
    description?: string;
    format?: string;
  };
  state?: {
    payload?: unknown;
    feedback?: unknown;
  };
  schema?: unknown;
};

export type TextGenerationChunkDto = {
  text: string;
};

export type TextGenerationResponseDto = {
  fullText: string;
};

export enum LlmResponseFormat {
  Text = "text",
  JsonObject = "json_object",
  JsonSchema = "json_schema",
}

// From OpenAI SDK https://github.com/openai/openai-node/blob/82439072952ebac9fdc82f895063c7996676b1e1/src/resources/chat/chat.ts#L11
export type ModelOption =
  | "gpt-4o"
  | "gpt-4o-2024-05-13"
  | "gpt-4o-2024-08-06"
  | "gpt-4o-mini"
  | "gpt-4o-mini-2024-07-18"
  | "gpt-4-turbo"
  | "gpt-4-turbo-2024-04-09"
  | "gpt-4-0125-preview"
  | "gpt-4-turbo-preview"
  | "gpt-4-1106-preview"
  | "gpt-4-vision-preview"
  | "gpt-4"
  | "gpt-4-0314"
  | "gpt-4-0613"
  | "gpt-4-32k"
  | "gpt-4-32k-0314"
  | "gpt-4-32k-0613"
  | "gpt-3.5-turbo"
  | "gpt-3.5-turbo-16k"
  | "gpt-3.5-turbo-0301"
  | "gpt-3.5-turbo-0613"
  | "gpt-3.5-turbo-1106"
  | "gpt-3.5-turbo-0125"
  | "gpt-3.5-turbo-16k-0613";

// OpenAI uses a subset of the official specification and doesn't specify the version
// Using latest, most likely version
export type JsonSchemaDefinition<T extends AnyJsonOutput | unknown = unknown> =
  Omit<JSONSchema7, "type" | "properties" | "items"> &
    (T extends unknown[]
      ? {
          type: "array";
          items: JsonSchemaDefinition<T[number]>;
          properties?: never;
        }
      : T extends Record<string, unknown>
        ? {
            type: "object";
            properties: {
              [K in keyof T]: JsonSchemaDefinition<T[K]>;
            };
          }
        : Pick<JSONSchema7, "type" | "properties" | "items">);

export type JsonSchemaDto = {
  name: string;
  description: string;
  schema: JsonSchemaDefinition;
  // OpenAI requires schema to be strict
  strict: true;
};

export type LlmOptions = {
  model: ModelOption;
  temperature?: number;
  responseFormat?: LlmResponseFormat;
  jsonSchema?: JsonSchemaDto;
};

export type CreateTextGenerationDto = LlmOptions & {
  messages: MessageType[];
  useCache?: boolean; // Currently only supported for non-streaming endpoint
  metadata?: Record<string, string>;
};

export type WrappedOutputType<T extends AnyJsonOutput> = {
  // We can include a reasoning/notes field here in the future
  [WrappedResponseKey]: T;
};
