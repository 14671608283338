import { Box, type SxProps, type Theme } from "@mui/material";

import {
  CommentEditMessage,
  type CommentMessageValues,
} from "@ll-web/features/projectComments/components/CommentEditMessage";
import { ThreadBubble } from "@ll-web/features/videoPlayer/comments/components/ThreadBubble";

type NewVideoThreadBubbleProps = {
  cancel: () => void;
  onAddThread: (values: CommentMessageValues) => Promise<void>;
  sx: SxProps<Theme>;
};

export const NewVideoThreadBubble = ({
  cancel,
  onAddThread,
  sx,
}: NewVideoThreadBubbleProps) => {
  return (
    <ThreadBubble
      threadId={null}
      index={null}
      sx={sx}
      isOpen={true}
      setIsOpen={cancel}
    >
      <Box sx={{ width: 350 }}>
        <CommentEditMessage
          editMode="newComment"
          onSubmit={onAddThread}
          onCancel={cancel}
          sx={{ p: 2 }}
        />
      </Box>
    </ThreadBubble>
  );
};
