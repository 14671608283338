import {
  mapCharactersToTalentsFormValues,
  mapTalentsFormValuesToCharacters,
} from "@ll-web/features/internalProjects/projectForm/talents/mapTalentsInfoFormToDto";
import type { CharactersInput } from "@ll-web/features/internalProjects/projectForm/talents/talentsInfoDto";
import type { ProjectCharacter } from "@ll-web/features/projects/types";
import { typedEntries, typedFromEntries } from "@ll-web/utils/types/types";

export function charactersCollectionDiff(
  previous: ProjectCharacter[],
  talents: CharactersInput,
): {
  added: Omit<ProjectCharacter, "id">[];
  removed: ProjectCharacter[];
} {
  const previousTalents = mapCharactersToTalentsFormValues(previous);
  const diffCounts = typedEntries(talents).map(
    ([talentType, talentCount]) =>
      [talentType, talentCount - previousTalents[talentType]] satisfies [
        keyof CharactersInput,
        number,
      ],
  );
  const addedTalents = typedFromEntries(
    diffCounts.filter(([, talentCount]) => talentCount > 0),
  ) satisfies Record<keyof CharactersInput, number>;

  const removed = typedEntries(talents).flatMap(([talentType]) =>
    previous
      .filter((character) => character.talentType === talentType)
      .filter((_, i) => i >= talents[talentType]),
  );

  const added = mapTalentsFormValuesToCharacters(addedTalents);

  return { added, removed };
}
