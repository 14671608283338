import type { BasicInfoInput } from "@ll-web/features/internalProjects/projectForm/basicInfo/basicInfoDto";
import type { BasicInfoFormValues } from "@ll-web/features/internalProjects/projectForm/basicInfo/basicInfoSchema";

export const mapBasicInfoFormToDto = ({
  dontUseForMarketing,
  msa,
  style,
  timeline,
  title,
  deliverables,
  rawFootageType,
  footageType,
}: BasicInfoFormValues) => {
  return {
    isMarketingCampaign: !dontUseForMarketing,
    msa,
    style,
    timeline,
    title,
    deliverables,
    rawFootageType,
    footageType,
  };
};

export const mapBasicInfoDtoToForm = ({
  isMarketingCampaign,
  msa,
  style,
  timeline,
  title,
  deliverables,
  rawFootageType,
  footageType,
}: BasicInfoInput): BasicInfoFormValues => {
  return {
    dontUseForMarketing: !isMarketingCampaign,
    msa,
    style,
    timeline,
    title,
    deliverables,
    rawFootageType,
    footageType,
  };
};
