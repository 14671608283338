import { useEffect, useRef, type EffectCallback } from "react";

// Triggers the effect only on the first strict mode render
export function useEffectOnce(effect: EffectCallback) {
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      return;
    }

    isFirstRenderRef.current = false;

    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

// Triggers the unmount destructor only on the second strict mode render
export function useUnmountOnce(destructor: ReturnType<EffectCallback>) {
  const isFirstRenderRef = useRef(true);

  useEffect(() => {
    if (import.meta.env.MODE === "development" && isFirstRenderRef.current) {
      isFirstRenderRef.current = false;

      return;
    }

    return destructor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
