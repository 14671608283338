import { useMemo, type PropsWithChildren } from "react";

import { useParams } from "react-router-dom";

import { ErrorMessage } from "@ll-web/components/errors/ErrorMessage";
import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { mapProjectDtoToForm } from "@ll-web/features/internalProjects/projectForm/utils/mapFormToDto";
import {
  useGetCharactersByProjectId,
  useGetProjectById,
} from "@ll-web/features/projects/async/useProjectsQueries";
import { assertDefined } from "@ll-web/utils/types/types";

import { ProjectFormRouteProvider } from "./ProjectFormRouteProvider";

export const EditProjectFormProvider = ({ children }: PropsWithChildren) => {
  const { id } = useParams<{
    id: string;
  }>();

  assertDefined(id);

  const { data, isPending, error } = useGetProjectById({ id });
  const {
    data: characters,
    isPending: isCharactersPending,
    error: charactersError,
  } = useGetCharactersByProjectId({ id });

  const initialValues = useMemo(() => {
    if (!data) {
      return null;
    }

    return mapProjectDtoToForm({ ...data, characters });
  }, [data, characters]);

  if (isPending || isCharactersPending) {
    return <SuspenseLoader />;
  }

  if (error || charactersError || !data) {
    return <ErrorMessage error={error} />;
  }

  assertDefined(initialValues);

  return (
    <ProjectFormRouteProvider passedInitialValues={initialValues}>
      {children}
    </ProjectFormRouteProvider>
  );
};
