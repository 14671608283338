export function prependProtocolToURL<T extends string | undefined | null>(
  value: T,
) {
  if (!value) {
    return value;
  }

  const protocols = [
    "http://",
    "https://",
    "file://",
    "webcal://",
    "mailto:",
    "tel:",
    "sms:",
    "//", // Protocol-relative URL
  ];

  const hasProtocol = protocols.some((protocol) =>
    value.toLowerCase().startsWith(protocol),
  );

  if (hasProtocol) {
    return value;
  }

  return `https://${value}`;
}
