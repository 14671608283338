import { useMemo } from "react";

import { Avatar, type AvatarProps } from "@mui/material";

import {
  isUploadcareUrl,
  scaleUploadcareUrl,
} from "@ll-web/utils/helpers/uploadcare";

type PersonAvatarProps = AvatarProps & {
  size?: number;
};

export const PersonAvatar = ({
  src,
  size = 32,
  ...props
}: PersonAvatarProps) => {
  const avatarUrl = useMemo<string | null>(() => {
    const url = src;
    if (!url) {
      return null;
    }

    if (isUploadcareUrl(url)) {
      return scaleUploadcareUrl(url, size * 3);
    }

    return url;
  }, [src, size]);

  return (
    <Avatar
      color="primary"
      src={avatarUrl ?? undefined}
      slotProps={{
        img: {
          draggable: false,
        },
      }}
      {...props}
      sx={{
        width: size,
        height: size,
        background: (theme) => theme.palette.avatarFill,
        color: (theme) => theme.palette.primary.main,
        fontWeight: 600,
        fontSize: Math.min(size / 2.25, 24),
        cursor: props.onClick ? "pointer" : "default",
        ...props.sx,
      }}
    />
  );
};
