import { memo, useMemo } from "react";

import { CircularProgress, type CircularProgressProps } from "@mui/material";

type SpinnerProps = Omit<CircularProgressProps, "variant" | "value"> & {
  syncAnimation?: boolean;
};

const animationDuration = 1400; // in milliseconds, MUI default

export const Spinner = memo(
  ({ syncAnimation = true, ...props }: SpinnerProps) => {
    const animationOffset = useMemo(() => {
      if (!syncAnimation) {
        return 0;
      }

      return -1 * (Date.now() % animationDuration);
    }, [syncAnimation]);

    return (
      <CircularProgress
        {...props}
        key={animationOffset}
        variant="indeterminate"
        sx={{
          ...props.sx,
          "&, .MuiCircularProgress-circle": {
            animationDelay: `${animationOffset}ms`,
            animationDuration: `${animationDuration}ms`,
          },
        }}
      />
    );
  },
);

Spinner.displayName = "Spinner";
