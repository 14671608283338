import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

import { httpClient } from "@ll-web/core/api/HttpClient";
import type { PaginatedResponse } from "@ll-web/core/api/pagination/pagination.types";
import {
  dataWithIdConverterFactory,
  defaultFirestoreConverterFactory,
} from "@ll-web/core/firebase/converters";
import { firestore } from "@ll-web/core/firebase/firebaseService";
import { FirestoreCollections } from "@ll-web/core/firebase/types";
import type {
  ByProjectIdVideoIdParams,
  ProjectsArgs,
  SubmitCreativeDeckEditDto,
  SubmitForReviewDto,
  UpdateDroneProductionDayDto,
  UpdateHeroVideoDto,
  UpdateProductionDayDto,
} from "@ll-web/features/internalProjects/types";
import { ProjectSubCollections } from "@ll-web/features/projects/enums";
import type {
  ProjectCharacter,
  ProjectData,
  ProjectDataWithId,
  ProjectDrone,
  ProjectHeroVideo,
  ProjectProductionDay,
  ProjectWithDeliverables,
  ProjectWithDeliverablesAndBrand,
  UpdateProductionStatusDto,
} from "@ll-web/features/projects/types";
import type { ByIdParams } from "@ll-web/utils/types/types";

// Project related operations accessible by Client or Internal users
class ProjectsService {
  async findAll(args: ProjectsArgs) {
    const response = await httpClient.unwrappedHttpRequest<
      PaginatedResponse<ProjectWithDeliverablesAndBrand>
    >({
      config: {
        method: "GET",
        url: "/v1/projects",
        params: args,
      },
    });

    return response.items;
  }

  async submitProjectForReview(args: SubmitForReviewDto) {
    await httpClient.unwrappedHttpRequest<void>({
      config: {
        method: "POST",
        url: "/v1/projects/review",
        data: args,
      },
    });
  }

  async submitCreativeDeckEdit(args: SubmitCreativeDeckEditDto) {
    await httpClient.unwrappedHttpRequest<void>({
      config: {
        method: "POST",
        url: "/v1/projects/creative-deck/submit-edit",
        data: args,
      },
    });
  }

  async getById({ id }: ByIdParams): Promise<ProjectWithDeliverables> {
    const subCollectionData = await Promise.all([
      {
        subcollection: ProjectSubCollections.HeroVideos,
        result: await this.getSubCollection(
          id,
          ProjectSubCollections.HeroVideos,
        ),
      },
      {
        subcollection: ProjectSubCollections.ProductionDays,
        result: await this.getProductionDaysByProjectId({ id }),
      },
      {
        subcollection: ProjectSubCollections.DroneProductionDays,
        result: await this.getDroneProductionDaysByProjectId({ id }),
      },
    ]);

    const projectData = await this.getBaseDataById({ id });

    const formattedSubCollectionData = Object.fromEntries(
      subCollectionData.map((item) => [item.subcollection, item.result ?? []]),
    );

    return {
      ...projectData,
      ...formattedSubCollectionData,
    } as ProjectWithDeliverables;
  }

  async getBaseDataById({ id }: ByIdParams): Promise<ProjectDataWithId> {
    const projectData = (
      await getDoc(doc(firestore, FirestoreCollections.Projects, id))
    ).data();

    // Checking these required properties because there might be cases where data with lastUpdated may still exist after a project has been deleted
    if (!projectData?.title || !projectData?.style) {
      throw new Error("Project not found");
    }

    return {
      id,
      ...projectData,
    } as ProjectDataWithId;
  }

  async getHeroVideosByProjectId({
    id,
  }: ByIdParams): Promise<ProjectHeroVideo[]> {
    const heroesRef = collection(
      firestore,
      FirestoreCollections.Projects,
      id,
      ProjectSubCollections.HeroVideos,
    ).withConverter(defaultFirestoreConverterFactory<ProjectHeroVideo>());
    const result = await getDocs(query(heroesRef));

    return result.docs.map((doc) => doc.data());
  }

  async getHeroVideoById({
    projectId,
    videoId,
  }: ByProjectIdVideoIdParams): Promise<ProjectHeroVideo> {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.HeroVideos,
      videoId,
    );
    const result = await getDoc(docRef);

    if (!result.exists()) {
      throw new Error("Hero video does not exist");
    }

    const video: ProjectHeroVideo = {
      ...(result.data() as ProjectHeroVideo),
      id: result.id,
    };

    return video;
  }

  async getProductionDaysByProjectId({
    id,
  }: ByIdParams): Promise<ProjectProductionDay[]> {
    const productionsRef = collection(
      firestore,
      FirestoreCollections.Projects,
      id,
      ProjectSubCollections.ProductionDays,
    ).withConverter(defaultFirestoreConverterFactory<ProjectProductionDay>());
    const result = await getDocs(
      query(
        productionsRef,
        orderBy("dateTime" satisfies keyof ProjectProductionDay, "asc"),
      ),
    );

    return result.docs.map((doc) => {
      return doc.data();
    });
  }

  async getDroneProductionDaysByProjectId({
    id,
  }: ByIdParams): Promise<ProjectDrone[]> {
    const productionsRef = collection(
      firestore,
      FirestoreCollections.Projects,
      id,
      ProjectSubCollections.DroneProductionDays,
    ).withConverter(defaultFirestoreConverterFactory<ProjectDrone>());
    const result = await getDocs(
      query(
        productionsRef,
        orderBy("dateTime" satisfies keyof ProjectDrone, "asc"),
      ),
    );

    return result.docs.map((doc) => {
      return doc.data();
    });
  }

  async getCharactersByProjectId({
    id,
  }: ByIdParams): Promise<ProjectCharacter[]> {
    const charactersRef = collection(
      firestore,
      FirestoreCollections.Projects,
      id,
      ProjectSubCollections.Characters,
    ).withConverter(dataWithIdConverterFactory<ProjectCharacter>());
    const result = await getDocs(query(charactersRef));

    return result.docs.map((doc) => {
      return doc.data();
    });
  }

  async updateProjectDocument({
    id,
    data,
  }: {
    id: string;
    data: Partial<ProjectData>;
  }): Promise<void> {
    const docRef = doc(firestore, FirestoreCollections.Projects, id);
    await setDoc(
      docRef,
      {
        ...data,
        ["lastUpdated" satisfies keyof ProjectData]: serverTimestamp(),
      },
      { merge: true },
    );
  }

  async updateProductionDay({
    projectId,
    id,
    data,
  }: {
    projectId: string;
    id: string;
    data: UpdateProductionDayDto;
  }) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.ProductionDays,
      id,
    );
    await setDoc(
      docRef,
      {
        ...data,
      },
      { merge: true },
    );
  }

  async updateProductionStatus({
    projectId,
    productionId,
    ...args
  }: UpdateProductionStatusDto): Promise<ProjectProductionDay> {
    const updatedProduction =
      await httpClient.unwrappedHttpRequest<ProjectProductionDay>({
        config: {
          method: "PUT",
          url: `/v1/projects/${projectId}/productions/${productionId}`,
          data: args,
        },
      });

    return updatedProduction;
  }

  async updateHeroVideo({
    projectId,
    id,
    data,
  }: {
    projectId: string;
    id: string;
    data: UpdateHeroVideoDto;
  }) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.HeroVideos,
      id,
    );
    await setDoc(
      docRef,
      {
        ...data,
      },
      { merge: true },
    );
  }

  async updateDroneProductionDay(
    projectId: string,
    id: string,
    data: UpdateDroneProductionDayDto,
  ) {
    const docRef = doc(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      ProjectSubCollections.DroneProductionDays,
      id,
    );
    await setDoc(
      docRef,
      {
        ...data,
      },
      { merge: true },
    );
  }

  async getSubCollection<T>(
    projectId: string,
    subcollection: ProjectSubCollections,
  ) {
    const subcollectionRef = collection(
      firestore,
      FirestoreCollections.Projects,
      projectId,
      subcollection,
    );

    const snapshot = await getDocs(query(subcollectionRef));

    return snapshot.docs.map((doc) => {
      return { id: doc.id, ...(doc.data() as T) };
    });
  }
}

export const projectsService = new ProjectsService();
