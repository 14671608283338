import type { JsonSchemaDefinition } from "@ll-web/features/llm/types";
import { OutputJsonSchema } from "@ll-web/features/llm/utils/JsonSchema";

import type { CharacterCombinedDescriptionOutput } from "./types";

const characterCombinedDescriptionSchemaDefinition: JsonSchemaDefinition<CharacterCombinedDescriptionOutput> =
  {
    type: "object",
    properties: {
      summary: {
        type: "string",
        description: "Combined summary of the character",
      },
      keyInfo: {
        type: "string",
        description: "Extracted key information about the actor",
      },
    },
  };

export const characterCombinedDescriptionSchema = new OutputJsonSchema({
  name: "characterCombinedDescription",
  description:
    "Combined description and key information for a character across multiple videos",
  schema: characterCombinedDescriptionSchemaDefinition,
});
