import { useCallback, useMemo } from "react";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Pages } from "@ll-web/core/router/pages";
import type { ProjectFormSteps } from "@ll-web/features/internalProjects/pages/ProjectForm/consts";
import { useProjectFormNavigation } from "@ll-web/features/internalProjects/pages/ProjectForm/hooks/useProjectFormNavigation";

import { useProjectFormMode } from "./useProjectFormMode";

export const useProjectFormRouteNavigation = () => {
  const navigate = useNavigate();
  const { step, id } = useParams<{ step: ProjectFormSteps; id: string }>();
  const [searchParams] = useSearchParams();
  const mode = useProjectFormMode();
  const basePath =
    mode === "edit" && id
      ? Pages.InternalEditProject.replace(":id", id)
      : Pages.InternalNewProject;

  const currentStep = useMemo(() => {
    return step;
  }, [step]);

  const makeAbsolutePathForStep = useCallback(
    (step: ProjectFormSteps) => {
      return `${basePath}/${step}${
        searchParams ? `?${searchParams.toString()}` : ""
      }`;
    },
    [searchParams, basePath],
  );

  const goToStep = useCallback(
    (step: ProjectFormSteps) => {
      navigate(makeAbsolutePathForStep(step));
    },
    [navigate, makeAbsolutePathForStep],
  );

  const navigationValues = useProjectFormNavigation({
    currentStep,
    goToStep,
  });

  const passthroughRoutes = useMemo(() => {
    return navigationValues.steps.map(
      (step: ProjectFormSteps) => `${basePath}/${step}`,
    );
  }, [navigationValues.steps, basePath]);

  return useMemo(
    () => ({
      ...navigationValues,
      passthroughRoutes,
      makeAbsolutePathForStep,
    }),
    [navigationValues, passthroughRoutes, makeAbsolutePathForStep],
  );
};
