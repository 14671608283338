import { type Dayjs } from "dayjs";
import * as yup from "yup";

import { DroneOperatorTypeEnum } from "@ll-web/features/projects/enums";
import { type PlaceDetailsType } from "@ll-web/features/projects/types";

export const droneProductionFields = {
  productionLength: "productionLength",
  droneOperator: "droneOperator",
  tentativeCity: "tentativeCity",
  dateTime: "dateTime",
  isTimeSet: "isTimeSet",
  isInEditMode: "isInEditMode",
} as const;

export const droneProductionSchema = yup.object().shape({
  id: yup.string().optional().default(undefined),
  // In seconds
  [droneProductionFields.productionLength]: yup
    .number()
    .required()
    .default(null),
  [droneProductionFields.droneOperator]: yup
    .string()
    .oneOf(Object.values(DroneOperatorTypeEnum))
    .required()
    .default(DroneOperatorTypeEnum.STANDARD),
  [droneProductionFields.tentativeCity]: yup
    .mixed<PlaceDetailsType>()
    .optional()
    .nullable()
    .default(
      () =>
        ({
          description: "",
        }) satisfies PlaceDetailsType,
    ),
  [droneProductionFields.dateTime]: yup
    .mixed<Dayjs>()
    .dayjs()
    .nullable()
    .default(null),
  [droneProductionFields.isTimeSet]: yup.boolean().default(false),
  [droneProductionFields.isInEditMode]: yup.boolean().default(true),
});

export type DroneProductionFormValues = yup.InferType<
  typeof droneProductionSchema
>;
