export function makeUploadcareThumbnails({
  thumbnailGroupUrl,
  duration,
}: {
  thumbnailGroupUrl: string;
  duration: number; // in seconds
}) {
  // thumbnail count is always after tile in the url
  const count = parseInt(thumbnailGroupUrl.match(/~(\d+)/)?.[1] ?? "0", 10);
  const thumbnailDuration = duration / count;
  const thumbnails = Array.from({ length: count }).map((_, index) => ({
    url: `${thumbnailGroupUrl}${thumbnailGroupUrl.endsWith("/") ? "" : "/"}nth/${index}/`,
    startTime: thumbnailDuration * index,
    endTime: thumbnailDuration * (index + 1),
  }));

  return thumbnails;
}
