import type { ReactNode } from "react";

import { Navigate, useSearchParams } from "react-router-dom";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useActiveBrandUser } from "@ll-web/features/brands/hooks/useActiveBrandUser";
import { BrandRoleEnum } from "@ll-web/features/brands/types";
import { getNextParam } from "@ll-web/utils/helpers/navigation";

interface IsBrandMemberLoggedInProps {
  children: ReactNode;
  brandRoles?: BrandRoleEnum[];
}

export const IsBrandMemberLoggedInGuard = ({
  children,
  brandRoles,
}: IsBrandMemberLoggedInProps) => {
  const activeBrandUserData = useActiveBrandUser();
  const [searchParams] = useSearchParams();
  const next = getNextParam(searchParams);

  if (
    activeBrandUserData?.isLoading ||
    (activeBrandUserData && !activeBrandUserData?.isFetched)
  ) {
    return <SuspenseLoader />;
  }

  if (
    !activeBrandUserData?.activeBrandUser ||
    (!!brandRoles &&
      !brandRoles?.includes(activeBrandUserData?.activeBrandUser?.role))
  ) {
    return <Navigate to={next ?? "/"} replace />;
  }

  return children;
};

export const IsBrandOwnerLoggedInGuard = (
  props: Omit<IsBrandMemberLoggedInProps, "brandRoles">,
) => {
  return (
    <IsBrandMemberLoggedInGuard {...props} brandRoles={[BrandRoleEnum.Owner]} />
  );
};
