import { BLOCKING_PHRASES } from "@ll-web/components/NotifyForReviewModal/consts";
import { ReviewNotificationType } from "@ll-web/components/NotifyForReviewModal/types";
import { ProjectStyleEnum } from "@ll-web/config/featureFlags/consts";
import { AccountType, type User } from "@ll-web/features/auth/types";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import { ProjectSpacePages } from "@ll-web/features/projectSpace/enums";
import {
  callSheetExternalEditEmailTemplate,
  callSheetInternalEditEmailTemplate,
} from "@ll-web/features/projectSpace/pages/CallSheets/useCallSheetPageReviewFormData";
import {
  creativeDeckExternalEditEmailTemplate,
  creativeDeckInternalEditEmailTemplate,
} from "@ll-web/features/projectSpace/pages/CreativeDeck/useCreativeDeckPageReviewFormData";
import { makeProjectSpacePagePath } from "@ll-web/features/projectSpace/utils/navigation";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { makeWizardPath } from "@ll-web/features/projectWizard/utils/navigation";
import { filterUsersByType } from "@ll-web/features/users/utils/filterUsers";
import { signature } from "@ll-web/utils/helpers/emails";
import { getPersonFullName } from "@ll-web/utils/helpers/helpers";
import { defined } from "@ll-web/utils/types/types";

const notifyReviewEmailDefaults: Record<
  ReviewNotificationType,
  {
    title: ({
      projectName,
      brandName,
    }: {
      projectName: string;
      brandName?: string;
    }) => string;
    message: (props: {
      projectName: string;
      defaultReceiverFirstName: string;
      projectId: string;
      brandName?: string;
      msa: string;
      accountExecutiveName: string | null;
      producerName: string | null;
      projectStyle: ProjectStyleEnum;
      senderName: string | null;
    }) => string;
  }
> = {
  [ReviewNotificationType.CreativeBriefInternal]: {
    title: ({ brandName }) =>
      `A new project ${brandName ? `for ${brandName}` : ""} is ready for your review`,
    message: ({
      projectName,
      defaultReceiverFirstName,
      projectId,
      brandName,
      msa,
      accountExecutiveName,
    }) =>
      `${defaultReceiverFirstName},

The project details have been submitted for:

${brandName}
${msa}, ${projectName}.

Review here: ${location.origin}${makeWizardPath(projectId, WizardFlowType.CreativeBrief)}

Please review the output and prepare the documents for the client prior to the Creative Review call.

If you have any questions or need more information, feel free to reach out ${accountExecutiveName ? `to the AE, ${accountExecutiveName}` : "."}.

Best,
Lemonlight`,
  },
  [ReviewNotificationType.WizardGenerateInternal]: {
    title: ({ projectName }) =>
      `Review Your Video Project Details for ${projectName} project`,
    message: ({
      defaultReceiverFirstName,
      projectId,
      brandName,
      projectName,
      senderName,
    }) => `Hi ${defaultReceiverFirstName},

The pre-production documents ${brandName ? `for ${brandName}` : ""} are now ready for review.

Step 1: Review the project details for project ${projectName} here: ${location.origin}${makeWizardPath(projectId)}. You can make changes or leave feedback directly on our Hero platform.

Step 2: Schedule your Creative Review call - ${BLOCKING_PHRASES.hubspotLink.text} to review the documents with your Producer. On this call, we will fine-tune all production details and ensure everything aligns perfectly with your vision.

If you have any questions or need assistance, don't hesitate to reach out.

Best${signature(senderName)}`,
  },
  [ReviewNotificationType.WizardGenerateExternalLegacy]: {
    title: ({ projectName }) => {
      return `Creative brief for ${projectName} has been submitted`;
    },
    message: ({
      defaultReceiverFirstName,
      projectId,
      projectStyle,
      senderName,
    }) => {
      const callSheetLinkText =
        projectStyle === ProjectStyleEnum.DocStyle
          ? `
Call Sheet: ${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}`
          : "";

      return `Hi ${defaultReceiverFirstName},

I've completed the Creative brief for my video project. Looking forward to discussing the details with you on our upcoming call.

Creative Deck: ${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}${callSheetLinkText}

Thanks${signature(senderName)}`;
    },
  },
  [ReviewNotificationType.WizardGenerateExternal]: {
    title: ({ projectName }) => {
      return `Creative Brief edited/submitted for review for ${projectName}`;
    },
    message: ({
      defaultReceiverFirstName,
      projectId,
      projectStyle,
      projectName,
      senderName,
    }) => {
      const creativeDeckLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}`;
      const callSheetLinkText =
        projectStyle === ProjectStyleEnum.DocStyle
          ? `
Call Sheet: ${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CallSheets)}`
          : "";

      return `Hi ${defaultReceiverFirstName},

I have reviewed the Creative Brief for ${projectName} and applied some edits/comments to it.
Please take a moment to check the details.

You can view them here:
Creative Deck: ${creativeDeckLink}${callSheetLinkText}

Thanks${signature(senderName)}`;
    },
  },
  [ReviewNotificationType.CreativeDeckEditInternal]:
    creativeDeckInternalEditEmailTemplate,
  [ReviewNotificationType.CreativeDeckEditExternal]:
    creativeDeckExternalEditEmailTemplate,
  [ReviewNotificationType.CallSheetEditInternal]:
    callSheetInternalEditEmailTemplate,
  [ReviewNotificationType.CallSheetEditExternal]:
    callSheetExternalEditEmailTemplate,
};

export const getDefaultReceiverForNotificationType = {
  [ReviewNotificationType.CreativeBriefInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find((user) => user.user?.accountType === AccountType.Creative),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [ReviewNotificationType.WizardGenerateInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Creative),
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
  [ReviewNotificationType.WizardGenerateExternalLegacy]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
  [ReviewNotificationType.WizardGenerateExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
  [ReviewNotificationType.CreativeDeckEditInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [ReviewNotificationType.CreativeDeckEditExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [ReviewNotificationType.CallSheetEditInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Creative),
      users.find((user) => user.user?.accountType === AccountType.Producer),
    ].filter(defined);
  },
  [ReviewNotificationType.CallSheetEditExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
};

export const getNotifyFormDefaultValues = ({
  projectName,
  notificationType,
  defaultReceivers,
  defaultReceiversData,
  projectId,
  brandName,
  msa,
  projectStyle,
  senderName,
}: {
  projectName: string;
  notificationType: ReviewNotificationType;
  defaultReceivers: string[];
  defaultReceiversData: User[];
  projectId: string;
  brandName?: string;
  msa: string;
  projectStyle: ProjectStyleEnum;
  senderName: string | null;
}) => {
  return {
    people: defaultReceivers ?? [],
    ...notifyReviewEmailDefaults[notificationType],
    title: notifyReviewEmailDefaults[notificationType].title({
      projectName,
      brandName,
    }),
    message: notifyReviewEmailDefaults[notificationType].message({
      projectName,
      defaultReceiverFirstName:
        defaultReceiversData.find(
          (userData) => userData._id === defaultReceivers[0],
        )?.firstName ?? "",
      projectId,
      brandName,
      msa,
      accountExecutiveName: getPersonFullName(
        filterUsersByType({
          users: defaultReceiversData,
          type: AccountType.AccountExecutive,
        })?.[0],
      ),
      producerName: getPersonFullName(
        filterUsersByType({
          users: defaultReceiversData,
          type: AccountType.Producer,
        })?.[0],
      ),
      projectStyle,
      senderName,
    }),
  };
};
