import type {
  JsonExampleType,
  LlmOptions,
  MessageType,
  MockPayloadType,
  PromptDumpType,
} from "@ll-web/features/llm/types";

export type PromptModelOptions = Partial<LlmOptions>;

export abstract class Prompt {
  static readonly name: string;
  static readonly version: string;
  static readonly description?: string;
  static readonly mockPayloads?: MockPayloadType<unknown>[];
  static readonly examples?: JsonExampleType[];
  static readonly format?: "json" | "text";

  public abstract getModelOptions?(): PromptModelOptions;

  public abstract compile(additionalMessages?: MessageType[]): {
    messages: MessageType[];
  };

  public abstract dump(): PromptDumpType;
}
