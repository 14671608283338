import { useMemo } from "react";

import { isEqual } from "lodash-es";
import { useFormContext } from "react-hook-form";

import { getDefaultFormValues } from "@ll-web/features/internalProjects/projectForm/utils/yupHelpers";

const defaultValues = getDefaultFormValues();

export const useProjectEmpty = () => {
  const { getValues } = useFormContext();
  const project = getValues();

  const isProjectEmpty = useMemo(() => {
    if (!project) {
      return null;
    }

    return isEqual(project, defaultValues);
  }, [project]);

  return isProjectEmpty;
};
