import { useCallback } from "react";

import { useGetDeliverableVideoReviewComments } from "@ll-web/features/projectComments/async/useProjectCommentsQueries";
import { useFilterCommentThreads } from "@ll-web/features/projectComments/hooks/useFilterCommentThreads";
import type { ProjectComment } from "@ll-web/features/projectComments/types";
import { defined } from "@ll-web/utils/types/types";

const SHOW_BEFORE_DURATION = 1;
const SHOW_AFTER_DURATION = 1;

export function useGetOnVideoThreads({
  projectId,
  deliverableId,
  timestamp,
}: {
  projectId: string;
  deliverableId: string;
  timestamp?: number;
}) {
  const commentsQuery = useGetDeliverableVideoReviewComments({
    projectId,
    deliverableId,
  });

  const commentRelevantFilter = useCallback(
    (comment: ProjectComment) => {
      if (
        !defined(comment.target.timestamp) ||
        !defined(comment.target.position) ||
        comment.isResolved === true
      ) {
        return false;
      }

      if (!defined(timestamp)) {
        return true;
      }

      if (Number.isNaN(timestamp) || timestamp === Infinity) {
        return false;
      }

      const timeDiff = timestamp - comment.target.timestamp;

      if (
        timeDiff < -1 * SHOW_BEFORE_DURATION ||
        timeDiff > SHOW_AFTER_DURATION
      ) {
        return false;
      }

      return true;
    },
    [timestamp],
  );

  const threads =
    useFilterCommentThreads({
      comments: commentsQuery.data,
      commentRelevantFilter,
    }) ?? [];

  return {
    threads,
  };
}
