import untruncateJson from "untruncate-json";

function escapeNewlinesInJsonStrings(json: string) {
  // Match strings in JSON
  return json.replace(/"([^"\\]*(?:\\.[^"\\]*)*)"/g, (match) => {
    // Replace unescaped new lines with escaped ones
    return match.replace(/\n/g, "\\n").replace(/\r/g, "\\r");
  });
}

export function parseJsonResponse(response: string): unknown {
  if (!response) {
    return null;
  }
  let trimmedResponse = response.trim();
  // Trim markdown code block
  trimmedResponse = trimmedResponse
    .replace(/^```json/, "")
    .replace(/^```/, "")
    .replace(/```$/, "");
  trimmedResponse = trimmedResponse.trim();
  if (!trimmedResponse || trimmedResponse.length < 2) {
    return null;
  }

  let untruncated = "";
  try {
    untruncated = untruncateJson(trimmedResponse);
    try {
      const json = JSON.parse(untruncated);

      return json;
    } catch (e) {
      const escaped = escapeNewlinesInJsonStrings(untruncated);
      try {
        const json = JSON.parse(escaped);

        return json;
      } catch (_) {
        throw e;
      }
    }
  } catch (e) {
    console.debug(e, { response, trimmedResponse, untruncated });
    throw e;
  }
}
