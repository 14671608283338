import type { CharacterCombinedDescriptionPayload } from "@ll-web/features/llm/prompts/CharacterCombinedDescription/types";
import type { MockPayloadType } from "@ll-web/features/llm/types";

export const characterCombinedDescriptionMockLuggage: MockPayloadType<CharacterCombinedDescriptionPayload> =
  {
    name: "Luggage",
    payload: {
      characterSummaries: [
        {
          summary:
            "Sarah is an adventurous woman in her early 30s, embodying the thrill-seeking, outdoor enthusiast. Fit and athletic, she leads her friends confidently through rugged terrains. Her mood is energetic and determined, showcasing the luggage’s toughness and reliability for any adventure.",
        },
        {
          summary:
            "Sarah is a stylish professional in her early 30s, epitomizing the modern jetsetter. Sleek and sophisticated, she navigates airports and business meetings with grace. Her mood is polished and ambitious, highlighting the luggage’s elegant design and smart features for business travelers.",
        },
        {
          summary:
            "Sarah is a caring parent in her early 30s, representing the devoted family traveler. Warm and organized, she manages family trips with ease. Her mood is nurturing and cheerful, emphasizing the luggage’s spaciousness and convenience for hassle-free family travel.",
        },
      ],
    },
  };
