import type { ToastContents } from "@ll-web/components/NotifyForReviewModal/types";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { AccountType, type User } from "@ll-web/features/auth/types";
import {
  useGetBrandById,
  useGetBrandByProjectId,
} from "@ll-web/features/brands/async/useBrandsQueries";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import { useActiveDeliverable } from "@ll-web/features/postProductionMedia/hooks/useActiveDeliverable";
import { getNextDeliverableVersion } from "@ll-web/features/postProductionMedia/utils/getNextMajorDeliverableVersion";
import { makePostProductionUrl } from "@ll-web/features/postProductionMedia/utils/makePostProductionUrl";
import { useActiveProjectBaseData } from "@ll-web/features/projectWizard/hooks/useActiveProjectBaseData";
import { UsersQueries } from "@ll-web/features/users/async/useUsersQueries";
import { useBatchQuery } from "@ll-web/utils/hooks/useBatchQueries";
import { defined } from "@ll-web/utils/types/types";

export enum VideoReviewNotificationType {
  InternalReview = "videoReview:internal:submit",
  ClientReview = "videoReview:client:submit",
}

enum VideoReviewApprovalKey {
  InternalReview = "videoReview:internal",
  ClientReview = "videoReview:client",
}

export const VideoReviewApprovalNotificationMap: Record<
  VideoReviewNotificationType,
  VideoReviewApprovalKey
> = {
  [VideoReviewNotificationType.ClientReview]:
    VideoReviewApprovalKey.ClientReview,
  [VideoReviewNotificationType.InternalReview]:
    VideoReviewApprovalKey.InternalReview,
};

const notifyReviewEmailDefaults: Record<
  VideoReviewNotificationType,
  {
    title: (projectName: string, clientName?: string) => string;
    message: (props: {
      projectName: string;
      defaultReceiverFirstName: string;
      projectId: string;
      senderFullName?: string;
      version?: string;
      resourceKey?: string;
      nextVersion: string;
    }) => string;
  }
> = {
  [VideoReviewNotificationType.ClientReview]: {
    title: (projectName) =>
      `A new video for ${projectName} is ready for your review`,
    message: ({
      defaultReceiverFirstName,
      projectId,
      projectName,
      resourceKey,
      nextVersion,
    }) =>
      `Hi ${defaultReceiverFirstName},
  
We're excited to share that the video for ${projectName} is ready for your review! Please take some time to go through it and let us know your feedback to ensure it meets your expectations.

You can view the video here:
${location.origin}${makePostProductionUrl(projectId, resourceKey ?? "", nextVersion)}

If you have any suggestions or need adjustments, feel free to share your thoughts by commenting and submitting your feerback directly in the platform for our review. We're looking froward to your feedback and final approval!`,
  },
  [VideoReviewNotificationType.InternalReview]: {
    title: (projectName) =>
      `A new video for ${projectName} is ready for internal review`,
    message: ({
      defaultReceiverFirstName,
      projectName,
      projectId,
      version,
      resourceKey,
    }) =>
      `Hi ${defaultReceiverFirstName},
  
The latest version of the video for ${projectName} is ready for internal review.

You can view the video here: 
${location.origin}${makePostProductionUrl(projectId, resourceKey ?? "", version)}

Please take some time to review the video and provide any feedback so we can finalize it before sharing it with the client. Your input is valuable as we move forward final approval.

Thank you!`,
  },
};

const getDefaultReceiverForNotificationType = {
  [VideoReviewNotificationType.ClientReview]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Creative),
      ...users.filter(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [VideoReviewNotificationType.InternalReview]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      ...users.filter(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
};

export const useVideoReviewFormData = (
  notificationType: VideoReviewNotificationType,
  projectId: string,
  resourceKey: string,
): {
  people: string[];
  title: string;
  message: string;
  defaultAutocompleteUsers: {
    isFetching: boolean;
    data: User[];
  };
} => {
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProjectBaseData();
  const { versions } = useActiveDeliverable({ guard: false });

  const brandByProjectQuery = useGetBrandByProjectId({
    projectId,
  });

  const brandId = brandByProjectQuery.data?._id;
  const brandByIdQuery = useGetBrandById(
    {
      brandId: brandId!,
    },
    { enabled: !!brandId },
  );

  const projectTeam =
    brandByIdQuery?.data?.team?.filter((teamMember) => !!teamMember.user) ?? [];

  const defaultReceivers =
    getDefaultReceiverForNotificationType[notificationType](projectTeam);

  const defaultReceiversIds = defaultReceivers
    .map((receiverProfile) => receiverProfile.user?._id)
    .filter(defined);

  const defaultUsersQuery = useBatchQuery(
    defaultReceiversIds.map((userId) => UsersQueries.getById({ userId })),
  );

  return {
    people: defaultReceiversIds ?? [],
    message: notifyReviewEmailDefaults[notificationType].message({
      projectName: activeProject.title ?? "",
      senderFullName: `${activeUser.firstName} ${activeUser.lastName}`,
      defaultReceiverFirstName:
        defaultReceivers.find(
          (userData) => userData?.user?._id === defaultReceivers[0].user?._id,
        )?.user?.firstName ?? "",
      projectId,
      nextVersion: getNextDeliverableVersion(versions ?? []),
      resourceKey,
    }),
    title: notifyReviewEmailDefaults[notificationType].title(
      activeProject.title ?? "",
      `${activeUser.firstName} ${activeUser.lastName}`,
    ),
    defaultAutocompleteUsers: {
      isFetching: defaultUsersQuery.isFetching,
      data: defaultUsersQuery.data?.filter(defined) ?? [],
    },
  };
};

export const videoReviewToastMessagesMap = (
  reviewNotificationType: VideoReviewNotificationType,
  variables: {
    nextVersion: string;
    videoName: string;
  },
): ToastContents => {
  switch (reviewNotificationType) {
    case VideoReviewNotificationType.ClientReview:
      return {
        success: `Version ${variables.nextVersion} sent to client for review`,
        pending: `Sending version ${variables.nextVersion} to client for review`,
      };
    case VideoReviewNotificationType.InternalReview:
      return {
        success: `${variables.videoName} shared for internal review`,
        pending: `Sharing ${variables.videoName} for internal review`,
      };
  }
};
