import type { CharacterCombinedDescriptionOutput } from "@ll-web/features/llm/prompts/CharacterCombinedDescription/types";
import type { JsonExampleType } from "@ll-web/features/llm/types";

export const charactersCombinedDescriptionExamplesClinic: JsonExampleType<CharacterCombinedDescriptionOutput> =
  {
    summary:
      "Dr. Harris is a dedicated and innovative physician in his late 40s. With a calm and reassuring demeanor, he specializes in cutting-edge treatments, always seeking the best outcomes for his patients. Dr. Harris is passionate about integrating new medical technologies into his practice, making him a beacon of hope for patients looking for advanced medical solutions. His mood is confident and compassionate, showcasing his expertise and commitment to patient care.",
    keyInfo:
      "This person is etchnic Caucasian. Male of age Adult (45-50). He has grey hair. His height is above 5'. His skills include operating medical equipment.",
  };
