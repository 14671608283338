import { Pages } from "@ll-web/core/router/pages";
import type { UserSettings } from "@ll-web/features/auth/userSettings/types";

export const defaultUserSettings = {
  hasWatchedIntroVideo: false,
  internalDashboardDefaultPage: Pages.InternalProjects,
  isInternalDashboardSidebarOpen: true,
  viewedTours: [],
  disableTours: false,
  firstWizardViewAt: undefined,
} as const satisfies Partial<UserSettings>;
