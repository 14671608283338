import type { DeliverableVersion } from "@ll-web/features/postProductionMedia/types";

export const getNextDeliverableVersion = (versions: DeliverableVersion[]) => {
  const sharedVersions = versions
    ?.filter((v) => /^\d+$/.test(v.versionName))
    ?.map((v) => parseInt(v.versionName, 10));

  const topSharedVersion = sharedVersions?.length
    ? Math.max(...sharedVersions)
    : 0;

  return (topSharedVersion + 1).toString();
};
