import {
  mapBasicInfoDtoToForm,
  mapBasicInfoFormToDto,
} from "@ll-web/features/internalProjects/projectForm/basicInfo/mapBasicInfoFormToDto";
import {
  mapDroneProductionDtoToForm,
  mapDroneProductionFormToDto,
} from "@ll-web/features/internalProjects/projectForm/droneProduction/mapDroneProductionFormToDto";
import {
  mapHeroVideoDtoToForm,
  mapHeroVideoFormToDto,
} from "@ll-web/features/internalProjects/projectForm/heroVideos/mapHeroVideoFormToDto";
import {
  mapNotesDtoToForm,
  mapNotesFormToDto,
} from "@ll-web/features/internalProjects/projectForm/notes/mapNotesDtoToForm";
import {
  mapProductionDayFormToDto,
  mapProductionDaysDtoToForm,
} from "@ll-web/features/internalProjects/projectForm/productionDays/mapProductionDayFormToDto";
import type { ProjectFormValues } from "@ll-web/features/internalProjects/projectForm/projectFormSchema";
import { mapCharacterDtoToForm } from "@ll-web/features/internalProjects/projectForm/talents/mapTalentsInfoFormToDto";
import type { ProjectFormInput } from "@ll-web/features/internalProjects/types";
import type { ProjectCharacter } from "@ll-web/features/projects/types";

export const mapProjectFormToDto = ({
  droneProduction,
  heroVideos,
  notes,
  productionDays,
  basicInfo,
  characters,
}: ProjectFormValues): ProjectFormInput => {
  return {
    ...mapBasicInfoFormToDto(basicInfo),
    heroes: heroVideos?.map(mapHeroVideoFormToDto),
    productions: productionDays?.map(mapProductionDayFormToDto),
    drones: droneProduction?.map(mapDroneProductionFormToDto),
    ...(characters ? { characters } : {}),
    ...mapNotesFormToDto(notes ?? {}),
  };
};

export const mapProjectDtoToForm = ({
  id,
  heroes,
  productions,
  drones,
  notes,
  videoReferences,
  characters,
  ...basicInfo
}: Omit<ProjectFormInput, "characters"> & {
  characters?: ProjectCharacter[];
}): ProjectFormValues => {
  return {
    id,
    basicInfo: mapBasicInfoDtoToForm({ ...basicInfo }),
    heroVideos: heroes?.map(mapHeroVideoDtoToForm),
    productionDays: productions?.map(mapProductionDaysDtoToForm),
    droneProduction: drones?.map(mapDroneProductionDtoToForm),
    ...(characters ? { characters: mapCharacterDtoToForm(characters) } : {}),
    notes: mapNotesDtoToForm({ notes, videoReferences }),
  };
};
