import * as yup from "yup";

import { ProjectTalentTypeEnum } from "@ll-web/features/projects/enums";

export const talentsFields = {
  feature: ProjectTalentTypeEnum.FEATURE,
  background: ProjectTalentTypeEnum.BACKGROUND,
  speaking: ProjectTalentTypeEnum.SPEAKING,
  specialty: ProjectTalentTypeEnum.SPECIALTY,
} as const;

export const talentsSchema = yup.object().shape({
  [talentsFields.background]: yup.number().min(0).required().default(0),
  [talentsFields.feature]: yup.number().min(0).required().default(1),
  [talentsFields.speaking]: yup.number().min(0).required().default(0),
  [talentsFields.specialty]: yup.number().min(0).required().default(0),
});

export type TalentsFormValues = yup.InferType<typeof talentsSchema>;
