import { useState, type ReactNode } from "react";

import {
  alpha,
  Box,
  Popover,
  Stack,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";

import { useHoveredCommentContext } from "@ll-web/features/projectComments/contexts/HoveredCommentContext";
import VideoCommentBubbleIcon from "@ll-web/features/videoPlayer/assets/VideoCommentBubbleIcon.svg";
import { defined } from "@ll-web/utils/types/types";

type ThreadBubbleProps = {
  threadId: string | null;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  sx: SxProps<Theme>;
  children: ReactNode;
  index: number | null;
};

export const ThreadBubble = ({
  threadId,
  isOpen,
  setIsOpen,
  sx,
  children,
  index,
}: ThreadBubbleProps) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const { isHovered } = useHoveredCommentContext({
    threadId,
  });

  const hoveredStyles: SxProps<Theme> = {
    zIndex: 1,
    filter: (theme) =>
      `drop-shadow(0px 0px 3px ${alpha(theme.palette.secondary.main, 0.8)})`,
    "#bubble-outline": {
      fill: (theme) => alpha(theme.palette.secondary.main, 0.8),
    },
  };

  return (
    <>
      <Box
        position="relative"
        className="comments-thread-bubble-anchor"
        sx={sx}
        ref={(ref: HTMLDivElement) => setRef(ref)}
      >
        <Stack
          component={VideoCommentBubbleIcon}
          alignItems="center"
          justifyContent="center"
          sx={{
            cursor: "pointer",
            filter: (theme) =>
              `drop-shadow(0px 0px 3px ${alpha(theme.palette.common.white, 0.5)})`,
            "#bubble-outline": {
              fill: (theme) => theme.palette.common.white,
            },
            "#bubble-center": {
              fill: (theme) =>
                isOpen
                  ? theme.palette.primary.main
                  : theme.palette.common.black,
            },
            ...(isHovered && hoveredStyles),
            ":hover": hoveredStyles,
            transition: (theme) =>
              theme.transitions.create(["filter", "background"]),
          }}
          onClick={() => setIsOpen(!isOpen)}
        />
        <Stack
          position="absolute"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          sx={{
            top: 0,
            pointerEvents: "none",
          }}
        >
          <Typography variant="subtitle1" color="white">
            {defined(index) ? index + 1 : "＋"}
          </Typography>
        </Stack>
      </Box>

      <Popover
        anchorEl={ref}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              mt: 0.5,
              ml: 1,
            },
          },
        }}
      >
        {children}
      </Popover>
    </>
  );
};
