export enum ReviewNotificationType {
  CreativeBriefInternal = "creativeBrief:internal",
  // Creative Brief is dedicated to Internals (Account Executives) and not available for externals
  WizardGenerateInternal = "wizardGenerate:internal",
  WizardGenerateExternal = "wizardGenerate:external",
  WizardGenerateExternalLegacy = "wizardGenerate:external:legacy",
  CreativeDeckEditInternal = "creativeDeckEdit:internal",
  CreativeDeckEditExternal = "creativeDeckEdit:external",
  CallSheetEditInternal = "callSheetEdit:internal",
  CallSheetEditExternal = "callSheetEdit:external",
}

export enum CreationNotificationType {
  ContractCreated = "contractCreated",
  LegacyFlowProjectCreated = "projectCreated",
}

export type ToastContents = {
  pending?: string;
  success?: string;
  error?: string;
};
