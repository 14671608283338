import { createQueryKeys } from "@lukemorales/query-key-factory";

import { postProductionMediaService } from "@ll-web/features/postProductionMedia/async/PostProductionMediaService";
import { createQueryHook } from "@ll-web/utils/factories/createQueryHook";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

export const PostProductionMediaQueries = createQueryKeys(
  "postProductionMedia",
  {
    getDeliverableByKey: createQueryOption(
      postProductionMediaService.getDeliverableByKey.bind(
        postProductionMediaService,
      ),
    ),
    getDeliverables: createQueryOption(
      postProductionMediaService.getDeliverables.bind(
        postProductionMediaService,
      ),
    ),
  },
);

export const useGetDeliverableByKey = createQueryHook(
  PostProductionMediaQueries.getDeliverableByKey,
  {
    // prevent duplicate queries
    staleTime: 5 * 1000,
  },
);

export const useGetDeliverables = createQueryHook(
  PostProductionMediaQueries.getDeliverables,
);
