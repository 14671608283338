import { useEffect, type ReactNode } from "react";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { Pages } from "@ll-web/core/router/pages";
import { useUser } from "@ll-web/features/auth/hooks/useUser";
import { AuthRedirectController } from "@ll-web/features/auth/pages/AuthRedirectController";
import {
  ExternalAccountTypes,
  InternalAccountTypes,
  type AccountType,
} from "@ll-web/features/auth/types";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";

type IsLoggedInGuardProps = {
  accountTypes?: AccountType[];
  children?: ReactNode;
  redirectPage?: Pages.Login | Pages.Register;
  emailHint?: string;
};

export const IsLoggedInGuard = ({
  children,
  accountTypes = [],
  redirectPage = Pages.Login,
  emailHint,
}: IsLoggedInGuardProps) => {
  const location = useLocation();
  const { isLoading, isAuthorized, activeUser } = useUser();

  const isAllowed =
    isAuthorized &&
    (accountTypes?.length
      ? accountTypes.includes(activeUser.accountType)
      : true);

  useEffect(() => {
    if (isAuthorized && !isAllowed) {
      console.warn(
        `User ${activeUser.email} ${activeUser._id} tried to access ${location.pathname} which is only for ${accountTypes?.join(", ")}`,
      );
      toast.error(
        `Only ${isUserInternal(activeUser) ? "external" : "internal"} users are allowed to access the page you tried to open`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, isAllowed]);

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if (!isAllowed) {
    return (
      <AuthRedirectController
        unauthorizedRedirectPage={redirectPage}
        emailHint={emailHint}
      />
    );
  }

  return children;
};

export const IsInternalLoggedInGuard = (
  props: Omit<IsLoggedInGuardProps, "accountTypes">,
) => {
  return <IsLoggedInGuard {...props} accountTypes={InternalAccountTypes} />;
};

export const IsExternalLoggedInGuard = (
  props: Omit<IsLoggedInGuardProps, "accountTypes">,
) => {
  return <IsLoggedInGuard {...props} accountTypes={ExternalAccountTypes} />;
};
