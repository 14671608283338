import { matchPath, useLocation } from "react-router-dom";

import { Pages } from "@ll-web/core/router/pages";

export const useProjectFormMode = () => {
  const location = useLocation();

  const mode = matchPath(
    {
      path: Pages.InternalEditProject,
      end: false,
    },
    location.pathname,
  )
    ? "edit"
    : "new";

  return mode;
};
