import type { SourceType } from "@ll-web/consts/query-parameters";
import type { AccountType, UserGroup } from "@ll-web/features/auth/types";
import type { ScriptedFrame } from "@ll-web/features/llm/prompts/ScriptedScript/types";
import type {
  FootageTypeEnum,
  ProjectStyleEnum,
} from "@ll-web/features/projects/enums";
import type { ProjectAiOutputSubcollections } from "@ll-web/features/projectWizard/types";

/**
 * When adding new events here, separate name segments by dots.
 * Use [location].[verb].[object] format unless requirements specify otherwise.
 * Each sink will format names downstream, according to platform's standards
 *
 * Firstly, add the ActivityType
 * Then, if the event has metadata, add an entry in ActivityEventsMetadata
 */
export enum ActivityType {
  AssetClientAddedFile = "client.added.brand.asset.file",
  AssetClientAddedUrl = "client.added.brand.asset.url",
  AvatarClickUpload = "clicked.avatar.upload",
  AvatarUploadFinish = "upload.avatar.image",
  BrandAcceptedInvitation = "team.accepted.invitation",
  BrandDeleteInvitation = "team.delete.invitation",
  BrandLogoClickUpload = "settings.brand.upload.photo",
  BrandLogoUploadFinish = "settings.brand.finished-upload.photo",
  BrandMemberRemovedSuccessfully = "completed.remove.member",
  BrandRemoveMember = "clicked.remove.member",
  BrandResendInvitation = "team.resend.invitation",
  BrandSendInvitation = "team.send.invitation",
  BrandSettingsAddedUrl = "brand.settings.added.url",
  BrandSettingsDownloadedFile = "brand.settings.downloaded.file",
  BrandSettingsRemovedFile = "brand.settings.removed.file",
  BrandSettingsRemovedUrl = "brand.settings.removed.url",
  BrandSettingsSave = "settings.brand.clicked.save",
  BrandSettingsSelectTone = "brand.settings.selected.brand.tone",
  BrandSettingsUnselectTone = "brand.settings.unselected.brand.tone",
  BrandSettingsUploadedFile = "brand.settings.uploaded.file",
  CompanyClickDeleteProject = "company.click.delete-project",
  CompanyClickEditProject = "company.click.edit-project",
  CompanyClickFinish = "company.click.finish",
  CompanyClickNewProject = "company.click.new-project",
  CompanyCompleteAddProject = "company.complete.add-project",
  CompanyFormClickNext = "company-form.click.next",
  CompanyFormPreselectExisting = "company-form.preselect.existing",
  CompanySendKickoff = "company.click.send-kickoff",
  ContractClickedContract = "contract.clicked.contract",
  ContractClickedEditContract = "contract.clicked.edit.contract",
  ContractClickedNewProject = "contract.clicked.new.project",
  ContractClickedProject = "contract.clicked.project",
  ContractMoreClickedDelete = "contract.more.clicked.delete",
  ContractMoreClickedDetails = "contract.more.clicked.details",
  ContractMoreClickedEdit = "contract.more.clicked.edit",
  ContractMoreClickedNewProject = "contract.more.clicked.new.project",
  ContractProjectsClickedContinue = "contract-projects.clicked.continue",
  ContractProjectsClickedNewProject = "contract-projects.clicked.new.project",
  ContractReviewClickedEdit = "contract-review.clicked.edit",
  ContractReviewClickedFinish = "contract-review.clicked.finish",
  ContractReviewClickedNewProject = "contract-review.clicked.new.project",
  ContractReviewClickedToggle = "contract-review.clicked.toggle",
  DashboardClickProject = "dashboard.click.project",
  DashboardClickedContractsView = "dashboard.clicked.contracts.view",
  DashboardClickedHamburger = "dashboard.clicked.hamburger",
  DashboardClickedProjectView = "dashboard.clicked.project.view",
  DeleteProject = "delete.project",
  InternalCreateUser = "internal.create.user",
  InternalDashboardClickNewProject = "internal-dashboard.click.new-project",
  InternalDashboardClickNotifyCreativeProducer = "internal-dashboard.click.notify-creative-producer",
  InternalDashboardClickProject = "internal-dashboard.click.project",
  InternalDashboardMoreClickedEditProject = "internal-dashboard.click.edit.project",
  InternalScriptedSentEmail = "internal-scripted.sent.email",
  LoginUser = "login.user",
  LogoutUser = "logout.user",
  OnboardingBrandClickContinue = "brand.setup.click.continue",
  OnboardingBrandClickSkip = "brand.setup.click.skip",
  OnboardingBrandLogoUploadClick = "onboarding.brand.upload.photo",
  OnboardingBrandLogoUploadFinish = "onboarding.brand.finished.upload.photo",
  OnboardingBrandSelectTone = "brand.setup.selected.brand.tone",
  OnboardingBrandUnselectTone = "brand.setup.unselected.brand.tone",
  ProjectFormDetailsClickContinue = "project-details.clicked.continue",
  ProjectFormDetailsClickContinueEditMode = "project-details-edit.clicked.continue",
  ProjectFormDetailsClickNewContract = "project-details.clicked.new.contract",
  ProjectFormDetailsSelectFootageType = " project.intake.selected.footage.type",
  ProjectFormHeroVideosClickAddVideo = "project-hero.clicked.add.video",
  ProjectFormHeroVideosClickCancel = "project-hero.clicked.cancel",
  ProjectFormHeroVideosClickContinue = "project-hero.clicked.continue",
  ProjectFormHeroVideosClickSave = "project-hero.clicked.save",
  ProjectFormProductionClickAddProduction = "project-production.clicked.add.day",
  ProjectFormProductionClickAddProductionEditMode = "project-production-edit.clicked.add.day",
  ProjectFormProductionClickAddTalent = "AE.added.a.talent",
  ProjectFormProductionClickCancel = "project-production.clicked.cancel",
  ProjectFormProductionClickContinue = "project-production.clicked.continue",
  ProjectFormProductionClickRemoveProductionEditMode = "project-production-edit.clicked.remove.day",
  ProjectFormProductionClickRemoveTalent = "AE.removed.a.talent",
  ProjectFormProductionClickSave = "project-production.clicked.save",
  ProjectFormProductionSelectProps = "intake.props.selected",
  ProjectFormProductionSelectWardrobe = "intake.wardrobe.selected",
  ProjectFormTalentsClickContinue = "project.intake.talents.click.continue",
  ProjectFormReviewClickFinish = "project-review.clicked.finish",
  ProjectFormReviewClickFinishEditMode = "project-review-edit.clicked.finish",
  ProjectFormReviewClickToggle = "project-review.clicked.toggle",
  ProjectFormReviewClickToggleEditMode = "project-review-edit.clicked.toggle",
  ProjectFormReviewSendKickoff = "project-review.send.kickoff",
  ProjectFormReviewDoCreativeBriefLater = "project.created.clicked.do.it.later",
  ProjectFormReviewFillOutCreativeBrief = "project.created.clicked.fill.out.brief",
  ProjectsClickedGDrive = "projects.clicked.gdrive",
  ProjectsSearchResults = "project.click.search",
  RegisterUserAvatarClickUpload = "onboarding.user.upload.photo",
  RegisterUserAvatarUploadFinish = "onboarding.user.finished.upload.photo",
  RegisterUserClickContinue = "user.setup.click.continue",
  SignupUser = "signup.user",
  TooltipClick = "tooltip.click",
  CreativeBriefInternalVisitedFirstTime = "project.wizard.empty.input.visited.1st.time",
  WizardInternalVisitedFirstTime = "project.wizard.empty.output.visited.1st.time",
  WizardExternalVisitedFirstTime = "project.wizard.filled.out.visited.1st.time",
  WizardUserVisitedFirstTime = "project.wizard.visited.1st.time",
  WizardExitedWalkthrough = "wizard.exited.walkthrough",
  WizardFinalizeClickFinalize = "brief-finalize.click.finalize",
  WizardFinalizeScriptedSentEmailToCp = "external-scripted.sent.email",
  WizardFinishedWalkthrough = "wizard.finished.walkthrough",
  WizardFinishedPreproSteps = "wizard.finished.prepro.steps",
  WizardHistoryClickedCancelRestore = "history.clicked.cancel.restore",
  WizardHistoryClickedConfirmRestore = "history.clicked.confirm.restore",
  WizardHistoryClickedHistory = "output.clicked.history",
  WizardHistoryClickedItem = "history.clicked.past.version",
  WizardHistoryClickedRestore = "history.clicked.restore",
  WizardInterviewQuestionsAddQuestion = "output.clicked.add.question",
  WizardInterviewQuestionsClickGenerate = "interview-questions.click.generate",
  WizardInterviewQuestionsClickNext = "interview-questions.click.next",
  WizardInterviewQuestionsRemoveQuestion = "output.clicked.remove.question",
  WizardInterviewQuestionsSubmitFeedback = "interview-questions.submit.feedback",
  WizardOnboardIntroAutoShow = "onboard.intro.auto.show",
  WizardOnboardIntroClose = "onboard.intro.clicked.close",
  WizardOnboardIntroOpen = "onboard.intro.clicked.open",
  WizardOutputClickedAiPrompt = "output.clicked.ai.prompt",
  WizardOutputClickedEdit = "output.clicked.edit",
  WizardOutputClickedTextToEdit = "output.clicked.text.to.edit",
  WizardOutputReorderedAgendaBlock = "output.reordered.agenda-block",
  WizardOutputClickedEditOption = "output.clicked.edit.option",
  WizardOutputClickedLookAndFeelImage = "output.clicked.image",
  WizardRegenerateLookAndFeelImage = "look.and.feel.regenerate.image",
  WizardOutputClickedManualEdit = "output.edited.manually",
  WizardOutputClickedProvideFeedback = "output.edited.with.ai",
  WizardCharactersClickNext = "characters.clicked.next",
  WizardCharactersClickGenerate = "characters.click.generate",
  WizardCharactersClickRegenerate = "characters.regenerated.output",
  WizardCharactersSubmitFeedback = "characters.regenerated.output.with.prompt",
  WizardOutputClickedWardrobeImage = "output.clicked.wardrobe.image",
  WizardRegenerateWardrobeImage = "characters.regenerate.wardrobe.image",
  WizardRoleCastingClickNext = "talent.selection.clicked.next",
  WizardRoleCastingSelectTalent = "talent.selection.selected.a.talent",
  WizardRoleCastingUnselectTalent = "talent.selection.unselected.a.talent",
  WizardRoleCastingSelectProductionDay = "output.selected.production.day",
  WizardRoleCastingUnselectProductionDay = "output.unselected.production.day",
  WizardScheduleAddBlock = "output.clicked.add.agenda.block",
  WizardScheduleAddItem = "output.clicked.add.agenda.item",
  WizardScheduleAddSubItem = "output.clicked.add.agenda.subitem",
  WizardScheduleRemoveBlock = "output.clicked.remove.agenda.block",
  WizardScheduleRemoveItem = "output.clicked.remove.agenda.item",
  WizardSchedulesClickGenerate = "filming-agenda.click.generate",
  WizardSchedulesSubmitFeedback = "filming-agenda.submit.feedback",
  WizardScriptClickGenerate = "output.regenerated.script",
  WizardScriptClickRegenerateWithNotes = "output.regenerated.script.with.notes",
  WizardScriptClientClickedFeedback = "script.client.clicked.tooltip",
  WizardScriptClientClickedNext = "script.client.clicked.next",
  WizardScriptSubmitFeedback = "script.submit.feedback",
  WizardScriptClickNext = "script.clicked.next",
  WizardScriptEditedSection = "script.edited.section",
  WizardScriptedScriptReorderedScene = "script.reordered.scene",
  WizardScriptedScriptCanceledSceneRemoval = "script.canceled.scene.removal",
  WizardScriptedScriptConfirmedSceneRemoval = "script.confirmed.scene.removal",
  WizardScriptedScriptClickedSceneRemoval = "script.clicked.to.remove.scene",
  WizardScriptedScriptSubmittedScene = "script.submitted.scene",
  WizardScriptedScriptCancelledSceneSubmission = "script.canceled.scene.submission",
  WizardScriptedScriptClickedAddNewScene = "script.clicked.to.add.scene",
  WizardScriptOutputRemovedLine = "output.removed.script.line",
  WizardScriptOutputReorderedScriptLine = "output.reordered.script.line",
  WizardScriptOutputCalceledLineRemoval = "output.canceled.script.line.removal",
  WizardScriptOutputClickedRemoveLine = "output.clicked.remove.script.line",
  WizardScriptOutputCancelledAddingLine = "output.canceled.adding.script.line",
  WizardScriptOutputClickedAddLine = "output.clicked.add.script.line",
  WizardScriptOutputAddedScriptLine = "output.added.script.line",
  WizardScriptedClientScriptInlineEdit = "scripted-client.edited.script",
  WizardScriptedClientVideoSummaryClickNext = "scripted-client.processed.video.objective",
  WizardScriptedClientVideoSummaryClickRegenerate = "scripted-client.regenerated.video.objective",
  WizardScriptedClientVideoSummaryInlineEdit = "scripted-client.edited.video.objective",
  WizardScriptedClientVideoSummarySubmitFeedback = "scripted-client.submit.feedback.video.objective",
  WizardScriptedCpSubmittedProject = "scripted-CP.submitted.project",
  WizardScriptedCpVideoSummaryClickGenerate = "scripted-CP.generated.video.objective",
  WizardScriptedCpVideoSummaryClickNext = "scripted-CP.processed.video.objective",
  WizardScriptedCpVideoSummarySubmitFeedback = "scripted-CP.submit.feedback.video.objective",
  WizardScriptedTtsClickNext = "scripted-tts.click.next",
  WizardScriptedTtsClientClickNext = "scripted-tts.client.click.next",
  WizardScriptedInputAddedProjectFocus = "scripted.input.added.project.focus",
  WizardScriptedInputRemovedProjectFocus = "scripted.input.removed.project.focus",
  WizardStartedWalkthrough = "wizard.started.walkthrough",
  WizardStoryboardAddNote = "storyboard.add.note",
  WizardStoryboardFrameAutoGenerateMissing = "storyboard.frame.auto.generate.missing",
  WizardStoryboardFrameEdit = "storyboard.frame.clicked.edit",
  WizardStoryboardClickNext = "storyboard.clicked.next",
  WizardStoryboardClickRegenerate = "storyboard.regenerated.storyboard",
  WizardStoryboardClickRegenerateWithFeedback = "storyboard.regenerated.storyboard.with.notes",
  WizardVideoSummaryClickGenerate = "video-summary.click.generate",
  WizardVideoSummaryClickNext = "video-summary.click.next",
  WizardVideoSummarySubmitFeedback = "video-summary.submit.feedback",
  WizardVisualsAddTakeaway = "visual.description.clicked.takeaways",
  WizardVisualsClickGenerate = "clicked.generate.visual.description",
  WizardVisualsSubmitFeedback = "visual.description.clicked.feedback",
  WizardVoiceoverAddTakeaway = "script.clicked.takeaways",
  WizardVisualsAutoGenerate = "auto.generate.scene.description",
  WizardVoiceoverGenerateTts = "generate.scripted.voiceover",
  WizardVoiceoverPlayGeneratedTts = "play.scripted.voiceover",
  WizardVoiceoverTtsClickNext = "voiceover.clicked.next",
  WizardVoiceoverTtsClickPlay = "voiceover.clicked.play",
  WizardVoiceoverTtsCollapsedSidebar = "voiceover.collapsed.sidebar",
  WizardVoiceoverTtsExpandedSidebar = "voiceover.expanded.sidebar",
  WizardVoiceoverTtsFilterVoicesAccent = "voiceover.filter.voices.accent",
  WizardVoiceoverTtsFilterVoicesAge = "voiceover.filter.voices.age",
  WizardVoiceoverTtsFilterVoicesGender = "voiceover.filter.voices.gender",
  WizardVoiceoverTtsFilterVoicesTone = "voiceover.filter.voices.tone",
  WizardVoiceoverTtsSelectVoice = "voiceover.select.voice",
  WizardVoiceoverTtsAdjustedSpeed = "voiceover.settings.adjusted.speed",
  WizardVoiceoverTtsClickedAddPause = "voiceover.settings.clicked.add.pause",
  WizardVoiceoverTtsOutputClickedAddPause = "voiceover.output.clicked.add.pause",
  WizardVoiceoverTtsOutputAppliedPause = "voiceover.output.applied.pause",
  WizardVoiceoverTtsOutputCancelledApplyingPause = "voiceover.output.cancelled.applying.pause",
  WizardVoiceoverTtsOutputRemovedPause = "voiceover.output.removed.pause",
  WizardVoiceoverTtsClickedAddPronunciation = "voiceover.settings.clicked.add.pronunciation",
  WizardVoiceoverTtsOutputClickedAddPronunciation = "voiceover.output.clicked.add.pronunciation",
  WizardVoiceoverTtsOutputClickedApplyToAllOccurrences = "voiceover.output.clicked.apply.to.all.occurrences",
  WizardVoiceoverTtsOutputAppliedPronunciation = "voiceover.output.applied.pronunciation",
  WizardVoiceoverTtsOutputCancelledApplyingPronunciation = "voiceover.output.cancelled.applying.pronunciation",
  WizardOutputClickedToLeaveComment = "output.clicked.to.leave.comment",
  WizardOutputCanceledDiscardingComment = "output.canceled.discarding.comment",
  WizardOutputConfirmedDiscardingComment = "output.confirmed.discarding.comment",
  WizardOutputCanceledRemoveComment = "output.canceled.remove.comment",
  WizardOutputLeftComment = "output.left.comment",
  WizardOutputClickedEditComment = "output.clicked.edit.comment",
  WizardOutputClickedRemoveComment = "output.clicked.to.remove.comment",
  WizardOutputRemovedComment = "output.removed.comment",
  WizardOutputResolvedComment = "output.clicked.resolve.comment",
  WizardOutputReopenedComment = "output.clicked.reopen.comment",
  WizardOutputOpenedCommentSidebar = "output.clicked.all.comments",
  WizardOutputClickedShowResolvedComments = "output.clicked.show.resolved.comments",
  WizardOutputRepliedToComment = "comments.clicked.reply.comment",
  WizardSentimentScoreSubmitted = "project.wizard.sentiment.scoresubmitted",
  WizardVoiceoverTtsOutputRemovedPronunciation = "voiceover.output.removed.pronunciation",
  WizardClickedEditVideoName = "project.wizard.clicked.edit.video.name",
  WizardEditedVideoName = "project.wizard.edited.video.name",
  PostProductionMediaClickedVersionDropdown = "video.review.clicked.all.versions",
  PostProductionMediaSelectVersion = "post-production-media.review.selected.version",
  PostProductionMediaUploadVersion = "post-production-media.review.clicked.upload",
  UploadDeliverableDialogNewVersionUploaded = "video.review.uploaded.new.version",
  UploadDeliverableDialogCancel = "video.review.canceled.uploading.new.version",
  ProjectImageCancel = "project.image.modal.clicked.cancel",
  ProjectImageSave = "project.image.modal.clicked.save",
  ProjectSpaceClickedBackArrow = "project.space.sidebar.clicked.back",
  ProjectSpaceClickedMenuItem = "project.space.sidebar.clicked.pages",
  ProjectSpaceClickedBrandSection = "project.homepage.sidebar.accessed.brand.details",
  ProjectSpaceHomepageClickedWizardContinue = "project.homepage.wizard.clicks.continue",
  ProjectSpaceClickedDownload = "project.space.clicked.download",
  ProjectSpaceDocClickedEdit = "page.view.clicked.edit",
  ProjectSpaceCreativeDeckClickedEdit = "creative.deck.clicked.edit",
  ProjectSpaceDeliverableVideoReview = "deliverable.video.clicked.review",
  ProjectSpaceDeliverableUpload = "deliverable.video.clicked.upload",
  ProjectHomepageClickedReview = "project.homepage.clicked.review",
  ProjectHomepageClickedJoinKickoff = "project.homepage.clicked.join.kickoff",
  ProjectHomepageClickedSchedule = "project.homepage.clicked.schedule",
  ProjectHomepageClickedBackArrow = "project.homepage.clicked.back.arrow",
  ProjectProductionScheduleApprovedEditedDate = "production.date.approved.edited.date",
  ProjectProductionScheduleApprovedDate = "production.date.approved.date",
  ProjectProductionScheduleExternalSubmitForReview = "production.date.suggested.review",
  ProjectProductionScheduleSubmittedDate = "production.date.submitted.date",
  ProjectHomepageClickedProjectDetails = "project.homepage.clicked.project.details",
  ProjectSpaceAssetsClickAddDialogOpen = "client.assets.clicked.add",
  ProjectSpaceAssetsClickAddDialogPickAsset = "client.assets.clicked.add.assets",
  ProjectSpaceAssetsClickAddDialogPickBookmark = "client.assets.clicked.add.bookmark",
  ProjectSpaceAssetsClickAddLinkSave = "client.assets.clicked.save.link",
  ProjectSpaceAssetsClickAddLinkCancel = "client.assets.clicked.cancel.link",
  ProjectSpaceAssetsClickDownloadSingle = "client.assets.clicked.download",
  ProjectSpaceAssetsClickDownloadAll = "client.assets.clicked.download.all",
  ProjectSpaceAssetsClickPreview = "client.assets.clicked.preview",
  ProjectSpaceAssetsClickCopyBookmark = "client.assets.clicked.copy",
  ProjectSpaceAssetsClickOpenBookmark = "client.assets.clicked.open.bookmark",
  ProjectSpaceAssetsClickDelete = "client.assets.clicked.delete",
  ProjectSpaceAssetsClickConfirmDelete = "client.assets.clicked.cancel.delete",
  ProjectSpaceAssetsClickCancelDelete = "client.assets.clicked.delete.asset",
  ProjectSpaceNotesClickedUpdateSetNotes = "project.space.notes.clicked.update.set.notes",
  ProjectTimelineStepClickedContinue = "project.timeline.clicked.continue",
  ProjectTimelineDaysAdjustment = "project.timeline.adjustment.days",
  ProjectTimelineDueDateAdjustment = "project.timeline.adjustment.due.date",
  CreativeDeckClickedChangeImage = "creative.deck.clicked.change.image",
  CreativeDeckClickedUploadTab = "creative.deck.clicked.upload.tab",
  CreativeDeckClickedGenerateTab = "creative.deck.clicked.generate.tab",
  CreativeDeckSavedImage = "creative.deck.saved.image",
  CreativeDeckCanceledSavingImage = "creative.deck.canceled.saving.image",
  CreativeDeckClickedRemoveImage = "creative.deck.clicked.remove.image",
  CreativeDeckRemovedImage = "creative.deck.removed.image",
  CreativeDeckCanceledRemovingImage = "creative.deck.canceled.removing.image",
  CreativeDeckSavedSetNotesChanges = "creative.deck.saved.set.notes.changes",
  CreativeDeckSavedCameraSpecsEdits = "creative.deck.saved.camera.specs.edits",
  CreativeDeckSavedAudioSpecsEdits = "creative.deck.saved.audio.specs.edits",
  ProjectsClickedProductionDate = "projects.clicked.production.date",

  CreativeDeckClickedRegenerateImage = "creative.deck.clicked.regenerate.image",
  DeliverableSummaryClickedToEdit = "deliverable.summary.clicked.to.edit",
  DeliverableSummaryClickedDownload = "deliverable.summary.clicked.download",

  // Creative Brief
  CreativeBriefInputClickedShareForReview = "creative.brief.input.clicked.share.for.review",
  CreativeBriefInputClickedSubmitForReview = "creative.brief.input.clicked.submit.for.review",
  CreativeBriefInputCanceledSubmittingForReview = "creative.brief.input.canceled.submitting.for.review",
  CreativeBriefClickedShareForReview = "creative.brief.clicked.share.for.review",
  CreativeBriefClickedSubmitForReview = "creative.brief.clicked.submit.for.review",
  CreativeBriefCanceledSubmittingForReview = "creative.brief.canceled.submitting.for.review",

  // Documents
  PageViewClickedSubmit = "page.view.clicked.submit",
  PageViewClickedSubmitForReview = "page.view.clicked.submit.for.review",
  PageViewClickedApproveCallSheet = "page.view.clicked.approve.call.sheet",
  PageViewClickedApproveCreativeDeck = "page.view.clicked.approve.creative.deck",
  PageViewClickedApproveByClientBehalf = "page.view.clicked.approve.on.client's.behalf",
  PageViewSubmittedForReview = "page.view.submitted.for.review",
  PageViewCanceledSubmittingForReview = "page.view.canceled.submitting.for.review",
  PageViewApprovedPage = "page.view.approved.page",
  PageViewCanceledApprovingPage = "page.view.canceled.approving.page",
  PageViewApprovedPageForClient = "page.view.approved.for.client",
  PageViewCanceledApprovingPageForClient = "page.view.canceled.approving.for.client",

  // Video
  VideoReviewClickedActionsMenu = "video.review.clicked.actions.menu",
  VideoReviewClickedShareInternally = "video.review.clicked.share.internally",
  VideoReviewSharedVideoInternally = "video.review.shared.video.internally",
  VideoReviewCancelledSharingVideoInternally = "video.review.cancelled.sharing.video.internally",
  VideoReviewClickedSendToClient = "video.review.clicked.send.to.client",
  VideoReviewSentVideoToClient = "video.review.sent.video.to.client",
  VideoReviewCanceledSendingToClient = "video.review.canceled.sending.to.client",
  DeliverablesClickedDeleteVideo = "deliverables.clicked.delete.video",
  DeliverablesDeletedVideo = "deliverables.deleted.video",

  // Creator
  CreatorSignupAddedRole = "creator.signup.added.role",
  CreatorSignupAddedEquipment = "creator.signup.added.equipment",
  CreatorSignupRegisteredAccount = "creator.signup.registered.account",
  // CreatorOnboardingCreatedAccount = "crew.onboarding.created.account",
  CreatorSigninClickedGoogleLogin = "creator.signin.clicked.google.login",
  CreatorSigninClickedEmailLogin = "creator.signin.clicked.email.login",
  CreatorSigninClickedForgotPassword = "creator.signin.clicked.forgot.password",
  CreatorDashboardAppliedForJob = "creator.dashboard.applied.for.job",
  CreatorDashboardRejectedJob = "creator.dashboard.rejected.job",
  CreatorDashboardUpdatedAppliedJobsFilter = "creator.dashboard.updated.applied.jobs.filter",
}

export type ProjectMetadata = {
  projectId: string;
  projectName: string;
  videoStyle: string;
};

export type VideoMetadata = {
  videoId: string;
  videoType: string;
};

type CompanyMetadata = {
  companyName: string;
  companyId: string;
};

type UserMetadata = {
  accountType: AccountType;
  email: string;
};

type ValueMetadata<T> = {
  value: T;
};

type WithProjectMetadata<T extends ActivityType> = {
  [K in T]: ProjectMetadata;
};

type WithVideoMetadata<T extends ActivityType> = {
  [K in T]: VideoMetadata;
};

type WithOptionalVideoMetadata<T extends ActivityType> = {
  [K in T]: Partial<VideoMetadata>;
};

type WithPersonRoleMetadata<T extends ActivityType> = {
  [K in T]: {
    senderRole: "producer" | "client" | "account executive";
  };
};

type WizardTourMetadata = {
  id: string;
};

type WizardOutputMetadata = {
  page: string;
  section?: string;
};

type ProjectTimelineAdjustmentMetadata = {
  projectId: string;
  timelineItemId: string;
};

type WithProjectAdjustmentMetadata<T extends ActivityType> = {
  [K in T]: ProjectTimelineAdjustmentMetadata;
};

export type ProjectSpaceAssetsMetadata = {
  section: string;
};

type CreativeDeckMetadata = {
  section: string;
};

export type ProjectSpaceAssetsTriggerMetadata = {
  trigger: string;
};

export type ProjectSpaceAssetsTypeMetadata = {
  type: "asset" | "bookmark";
};

type ProductionScheduleFormMetadata = {
  isClientReviewRequired: boolean;
};

type AppActivityEventsMetadata = {
  [ActivityType.BrandAcceptedInvitation]: {
    acceptedBy: string; // email
    invitedBy: string; // email
  };
  [ActivityType.BrandDeleteInvitation]: { email: string };
  [ActivityType.BrandMemberRemovedSuccessfully]: {
    userId: string;
    brandId: string;
  };
  [ActivityType.BrandRemoveMember]: { userId: string; brandId: string };
  [ActivityType.BrandResendInvitation]: { email: string };
  [ActivityType.BrandSendInvitation]: { email: string };
  [ActivityType.BrandSettingsSelectTone]: ValueMetadata<string[]>;
  [ActivityType.BrandSettingsUnselectTone]: ValueMetadata<string[]>;
  [ActivityType.CompanyClickDeleteProject]: CompanyMetadata;
  [ActivityType.CompanyClickEditProject]: CompanyMetadata;
  [ActivityType.CompanyCompleteAddProject]: {
    rawFootage?: "raw-footage-only" | "raw-footage" | "no-raw-footage";
  };
  [ActivityType.CompanyFormClickNext]: CompanyMetadata & { isNew: boolean };
  [ActivityType.CompanyFormPreselectExisting]: CompanyMetadata;
  [ActivityType.CompanySendKickoff]: { numberOfKickoffSent: number };
  [ActivityType.ContractClickedContract]: {
    name: string; // contract msa
  };
  [ActivityType.ContractReviewClickedToggle]: {
    "toggle-action": "on" | "off";
  };
  [ActivityType.DashboardClickedHamburger]: { action: "open" | "close" };
  [ActivityType.InternalCreateUser]: UserMetadata;
  [ActivityType.InternalDashboardClickNotifyCreativeProducer]: {
    action: "enable" | "disable";
  };
  [ActivityType.LoginUser]: UserMetadata & { isActive?: boolean };
  [ActivityType.ProjectImageCancel]: {
    resource: string;
    mode: "ai" | "upload";
  };
  [ActivityType.ProjectImageSave]: {
    resource: string;
    mode: "ai" | "upload";
  };
  [ActivityType.ProjectHomepageClickedReview]: { pageName: string };
  [ActivityType.OnboardingBrandSelectTone]: ValueMetadata<string[]>;
  [ActivityType.OnboardingBrandUnselectTone]: ValueMetadata<string[]>;
  [ActivityType.ProjectFormDetailsSelectFootageType]: ValueMetadata<FootageTypeEnum>;
  [ActivityType.ProjectFormHeroVideosClickAddVideo]: ValueMetadata<ProjectStyleEnum>;
  [ActivityType.ProjectFormHeroVideosClickCancel]: ValueMetadata<ProjectStyleEnum>;
  [ActivityType.ProjectFormHeroVideosClickSave]: ValueMetadata<ProjectStyleEnum>;
  [ActivityType.ProjectFormProductionClickAddTalent]: ValueMetadata<string>;
  [ActivityType.ProjectFormProductionClickRemoveTalent]: ValueMetadata<string>;
  [ActivityType.ProjectFormProductionSelectProps]: ValueMetadata<string | null>;
  [ActivityType.ProjectFormProductionSelectWardrobe]: ValueMetadata<
    string | null
  >;
  [ActivityType.ProjectFormReviewClickToggle]: {
    "toggle-action": string;
  };
  [ActivityType.ProjectFormReviewClickToggleEditMode]: {
    "toggle-action": string;
  };
  [ActivityType.ProjectsSearchResults]: {
    resultNumber: number;
  };
  [ActivityType.SignupUser]: UserMetadata;
  [ActivityType.TooltipClick]: {
    id: string;
  };
  [ActivityType.WizardUserVisitedFirstTime]: {
    source: SourceType;
  };
  [ActivityType.WizardExitedWalkthrough]: WizardTourMetadata;
  [ActivityType.WizardFinishedWalkthrough]: WizardTourMetadata;
  [ActivityType.WizardFinishedPreproSteps]: {
    duration: number; // duration in seconds
  };
  [ActivityType.WizardFinalizeScriptedSentEmailToCp]: {
    to: string;
    type: "Client reviewed";
  };
  [ActivityType.WizardHistoryClickedHistory]: {
    outputType: ProjectAiOutputSubcollections;
  };
  [ActivityType.WizardInterviewQuestionsAddQuestion]: {
    page: string;
  };
  [ActivityType.WizardInterviewQuestionsRemoveQuestion]: {
    page: string;
  };
  [ActivityType.WizardOutputClickedTextToEdit]: {
    page: string;
  };
  [ActivityType.WizardInterviewQuestionsSubmitFeedback]: {
    question: string | null | undefined;
  };
  // Some of the events needs to be set as partial, due to the fact that the videoId is not always available
  [ActivityType.WizardOutputClickedAiPrompt]: Partial<VideoMetadata> &
    WizardOutputMetadata &
    ValueMetadata<string>;
  [ActivityType.WizardOutputClickedEdit]: Partial<VideoMetadata> &
    WizardOutputMetadata;
  [ActivityType.WizardSentimentScoreSubmitted]: Partial<ProjectMetadata> & {
    easyToUse: number;
    accuracyOfQuestions: number;
    recommendationLikehood: number;
    opinion?: string;
  };
  [ActivityType.WizardOutputReorderedAgendaBlock]: Partial<ProjectMetadata>;
  [ActivityType.WizardOutputClickedEditOption]: Partial<VideoMetadata> &
    WizardOutputMetadata & {
      optionName: string;
      isAiPrompt: boolean;
    };
  [ActivityType.WizardOutputClickedProvideFeedback]: Partial<VideoMetadata> &
    WizardOutputMetadata &
    ValueMetadata<string>;
  [ActivityType.WizardOutputClickedLookAndFeelImage]: WizardOutputMetadata;
  [ActivityType.WizardOutputClickedManualEdit]: WizardOutputMetadata;
  [ActivityType.WizardStoryboardFrameAutoGenerateMissing]: ProjectMetadata & {
    videoId: string;
  };
  [ActivityType.WizardScriptClientClickedFeedback]: {
    field: string;
  };
  [ActivityType.WizardScriptedClientVideoSummarySubmitFeedback]: {
    section: string | null | undefined;
  };
  [ActivityType.WizardScriptedCpVideoSummarySubmitFeedback]: {
    section: string | null | undefined;
  };
  [ActivityType.WizardCharactersClickRegenerate]: {
    section?: string | null | undefined;
  };
  [ActivityType.WizardCharactersSubmitFeedback]: {
    section?: string | null | undefined;
    value: string;
  };
  [ActivityType.WizardOutputClickedWardrobeImage]: WizardOutputMetadata;
  [ActivityType.WizardRoleCastingSelectProductionDay]: ValueMetadata<"role casting">;
  [ActivityType.WizardRoleCastingUnselectProductionDay]: ValueMetadata<"role casting">;
  [ActivityType.WizardStartedWalkthrough]: WizardTourMetadata;
  [ActivityType.WizardStoryboardAddNote]: {
    userType: UserGroup;
  };
  [ActivityType.WizardVideoSummarySubmitFeedback]: {
    section: string | null | undefined;
  };
  [ActivityType.WizardVoiceoverTtsFilterVoicesAccent]: ValueMetadata<string[]>;
  [ActivityType.WizardVoiceoverTtsFilterVoicesAge]: ValueMetadata<string>;
  [ActivityType.WizardVoiceoverTtsFilterVoicesGender]: ValueMetadata<string>;
  [ActivityType.WizardVoiceoverTtsFilterVoicesTone]: ValueMetadata<string[]>;
  [ActivityType.WizardVoiceoverTtsSelectVoice]: {
    voiceId: string;
    voiceName: string;
  };
  [ActivityType.WizardVoiceoverTtsAdjustedSpeed]: {
    value: number; // signed deviation in percent
    section: "slider" | "percentage";
  };
  [ActivityType.ProjectSpaceClickedMenuItem]: {
    page: string;
  };
  [ActivityType.ProjectSpaceClickedDownload]: {
    page: string;
  };
  [ActivityType.ProjectSpaceDocClickedEdit]: {
    page: string;
    section: string;
  };
  [ActivityType.ProjectSpaceCreativeDeckClickedEdit]: {
    section: string;
  };
  [ActivityType.WizardVoiceoverTtsOutputAppliedPause]: {
    value: number; // duration in seconds
    isCustom?: boolean;
  };
  [ActivityType.ProjectHomepageClickedProjectDetails]: {
    tab: string;
  };
  [ActivityType.WizardScriptEditedSection]: {
    prompt: string;
    sectionName: keyof ScriptedFrame;
  };
  [ActivityType.CreatorSignupRegisteredAccount]: {
    link: string;
  };
  [ActivityType.WizardScriptedInputRemovedProjectFocus]: {
    optionName: string;
  };
  [ActivityType.WizardScriptedInputAddedProjectFocus]: {
    optionName: string;
  };
  [ActivityType.DeliverableSummaryClickedToEdit]: {
    fieldName: string;
  };
  [ActivityType.PageViewApprovedPage]: {
    page: string;
  };
  [ActivityType.PageViewCanceledApprovingPage]: {
    page: string;
  };
  [ActivityType.PageViewSubmittedForReview]: {
    page: string;
  };
  [ActivityType.PageViewCanceledSubmittingForReview]: {
    page: string;
  };
  [ActivityType.PageViewClickedSubmit]: {
    page: string;
  };
  [ActivityType.PageViewClickedSubmitForReview]: {
    page: string;
  };
  [ActivityType.VideoReviewClickedActionsMenu]: {
    user: string;
  };
  [ActivityType.PageViewClickedApproveByClientBehalf]: {
    page: string;
  };
  [ActivityType.PageViewApprovedPageForClient]: {
    page: string;
  };
  [ActivityType.PageViewCanceledApprovingPageForClient]: {
    page: string;
  };
  [ActivityType.VideoReviewClickedActionsMenu]: {
    user: string;
  };
  [ActivityType.ProjectSpaceAssetsClickAddDialogOpen]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTriggerMetadata;
  [ActivityType.ProjectSpaceAssetsClickAddDialogPickAsset]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTriggerMetadata;
  [ActivityType.ProjectSpaceAssetsClickAddDialogPickBookmark]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTriggerMetadata;
  [ActivityType.ProjectSpaceAssetsClickAddLinkSave]: ProjectSpaceAssetsMetadata;
  [ActivityType.ProjectSpaceAssetsClickAddLinkCancel]: ProjectSpaceAssetsMetadata;
  [ActivityType.ProjectSpaceAssetsClickDownloadSingle]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTriggerMetadata;
  [ActivityType.ProjectSpaceAssetsClickDownloadAll]: ProjectSpaceAssetsMetadata;
  [ActivityType.ProjectSpaceAssetsClickPreview]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTriggerMetadata;
  [ActivityType.ProjectSpaceAssetsClickCopyBookmark]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTriggerMetadata;
  [ActivityType.ProjectSpaceAssetsClickOpenBookmark]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTriggerMetadata;
  [ActivityType.ProjectSpaceAssetsClickDelete]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTypeMetadata;
  [ActivityType.ProjectSpaceAssetsClickConfirmDelete]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTypeMetadata;
  [ActivityType.ProjectSpaceAssetsClickCancelDelete]: ProjectSpaceAssetsMetadata &
    ProjectSpaceAssetsTypeMetadata;
  [ActivityType.ProjectProductionScheduleExternalSubmitForReview]: ProductionScheduleFormMetadata;
  [ActivityType.ProjectProductionScheduleApprovedDate]: ProductionScheduleFormMetadata;
  [ActivityType.ProjectProductionScheduleApprovedEditedDate]: ProductionScheduleFormMetadata;
  [ActivityType.ProjectTimelineStepClickedContinue]: {
    step: string;
  };
  [ActivityType.CreativeDeckClickedChangeImage]: CreativeDeckMetadata;
  [ActivityType.WizardOutputClickedShowResolvedComments]: {
    action: "enable" | "disable";
  };
  [ActivityType.ProjectsClickedProductionDate]: {
    videoStyle: string;
  };
} & WithPersonRoleMetadata<
  | ActivityType.CreativeBriefInputClickedShareForReview
  | ActivityType.CreativeBriefInputClickedSubmitForReview
  | ActivityType.CreativeBriefInputCanceledSubmittingForReview
  | ActivityType.CreativeBriefClickedShareForReview
  | ActivityType.CreativeBriefClickedSubmitForReview
  | ActivityType.CreativeBriefCanceledSubmittingForReview
> &
  WithProjectMetadata<
    | ActivityType.CompanyCompleteAddProject
    | ActivityType.ContractClickedProject
    | ActivityType.DashboardClickProject
    | ActivityType.DeleteProject
    | ActivityType.InternalDashboardClickProject
    | ActivityType.InternalDashboardClickNotifyCreativeProducer
    | ActivityType.InternalDashboardMoreClickedEditProject
    | ActivityType.ProjectsClickedGDrive
    | ActivityType.ProjectFormReviewClickFinish
    | ActivityType.WizardExitedWalkthrough
    | ActivityType.WizardFinishedWalkthrough
    | ActivityType.WizardFinishedPreproSteps
    | ActivityType.WizardFinalizeClickFinalize
    | ActivityType.WizardFinalizeScriptedSentEmailToCp
    | ActivityType.WizardHistoryClickedCancelRestore
    | ActivityType.WizardHistoryClickedConfirmRestore
    | ActivityType.WizardHistoryClickedHistory
    | ActivityType.WizardHistoryClickedItem
    | ActivityType.WizardHistoryClickedRestore
    | ActivityType.WizardInterviewQuestionsAddQuestion
    | ActivityType.WizardInterviewQuestionsClickGenerate
    | ActivityType.WizardInterviewQuestionsClickNext
    | ActivityType.WizardInterviewQuestionsRemoveQuestion
    | ActivityType.WizardInterviewQuestionsSubmitFeedback
    | ActivityType.WizardOnboardIntroAutoShow
    | ActivityType.WizardOnboardIntroClose
    | ActivityType.WizardOnboardIntroOpen
    | ActivityType.WizardOutputClickedAiPrompt
    | ActivityType.WizardOutputClickedEdit
    | ActivityType.WizardOutputClickedTextToEdit
    | ActivityType.WizardOutputClickedEditOption
    | ActivityType.WizardOutputClickedLookAndFeelImage
    | ActivityType.WizardRegenerateLookAndFeelImage
    | ActivityType.WizardOutputClickedManualEdit
    | ActivityType.WizardOutputClickedProvideFeedback
    | ActivityType.WizardCharactersClickNext
    | ActivityType.WizardCharactersClickGenerate
    | ActivityType.WizardCharactersClickRegenerate
    | ActivityType.WizardCharactersSubmitFeedback
    | ActivityType.WizardOutputClickedWardrobeImage
    | ActivityType.WizardRegenerateWardrobeImage
    | ActivityType.WizardRoleCastingClickNext
    | ActivityType.WizardRoleCastingSelectTalent
    | ActivityType.WizardRoleCastingUnselectTalent
    | ActivityType.WizardRoleCastingSelectProductionDay
    | ActivityType.WizardRoleCastingUnselectProductionDay
    | ActivityType.WizardScheduleAddBlock
    | ActivityType.WizardScheduleAddItem
    | ActivityType.WizardScheduleAddSubItem
    | ActivityType.WizardScheduleRemoveBlock
    | ActivityType.WizardScheduleRemoveItem
    | ActivityType.WizardSchedulesClickGenerate
    | ActivityType.WizardSchedulesSubmitFeedback
    | ActivityType.WizardScriptClickGenerate
    | ActivityType.WizardScriptClickRegenerateWithNotes
    | ActivityType.WizardScriptClickNext
    | ActivityType.WizardScriptClientClickedFeedback
    | ActivityType.WizardScriptClientClickedNext
    | ActivityType.WizardScriptSubmitFeedback
    | ActivityType.WizardScriptEditedSection
    | ActivityType.WizardScriptedScriptReorderedScene
    | ActivityType.WizardScriptedScriptCanceledSceneRemoval
    | ActivityType.WizardScriptedScriptConfirmedSceneRemoval
    | ActivityType.WizardScriptedScriptClickedSceneRemoval
    | ActivityType.WizardScriptedScriptClickedAddNewScene
    | ActivityType.WizardScriptedScriptSubmittedScene
    | ActivityType.WizardScriptedScriptCancelledSceneSubmission
    | ActivityType.WizardScriptedClientScriptInlineEdit
    | ActivityType.WizardScriptedClientVideoSummaryClickNext
    | ActivityType.WizardScriptedClientVideoSummaryClickRegenerate
    | ActivityType.WizardScriptedClientVideoSummaryInlineEdit
    | ActivityType.WizardScriptedClientVideoSummarySubmitFeedback
    | ActivityType.WizardScriptedCpSubmittedProject
    | ActivityType.WizardScriptedCpVideoSummaryClickGenerate
    | ActivityType.WizardScriptedCpVideoSummaryClickNext
    | ActivityType.WizardScriptedCpVideoSummarySubmitFeedback
    | ActivityType.WizardScriptedTtsClickNext
    | ActivityType.WizardScriptedTtsClientClickNext
    | ActivityType.WizardStartedWalkthrough
    | ActivityType.WizardStoryboardAddNote
    | ActivityType.WizardStoryboardClickNext
    | ActivityType.WizardStoryboardClickRegenerate
    | ActivityType.WizardStoryboardClickRegenerateWithFeedback
    | ActivityType.WizardVideoSummaryClickGenerate
    | ActivityType.WizardVideoSummaryClickNext
    | ActivityType.WizardVideoSummarySubmitFeedback
    | ActivityType.WizardVisualsAddTakeaway
    | ActivityType.WizardVisualsAutoGenerate
    | ActivityType.WizardVisualsClickGenerate
    | ActivityType.WizardVisualsSubmitFeedback
    | ActivityType.WizardVoiceoverAddTakeaway
    | ActivityType.WizardVoiceoverGenerateTts
    | ActivityType.WizardVoiceoverPlayGeneratedTts
    | ActivityType.WizardVoiceoverTtsClickNext
    | ActivityType.WizardVoiceoverTtsClickPlay
    | ActivityType.WizardVoiceoverTtsCollapsedSidebar
    | ActivityType.WizardVoiceoverTtsExpandedSidebar
    | ActivityType.WizardVoiceoverTtsFilterVoicesAccent
    | ActivityType.WizardVoiceoverTtsFilterVoicesAge
    | ActivityType.WizardVoiceoverTtsFilterVoicesGender
    | ActivityType.WizardVoiceoverTtsFilterVoicesTone
    | ActivityType.WizardVoiceoverTtsSelectVoice
    | ActivityType.WizardVoiceoverTtsAdjustedSpeed
    | ActivityType.WizardVoiceoverTtsClickedAddPause
    | ActivityType.WizardVoiceoverTtsOutputClickedAddPause
    | ActivityType.WizardVoiceoverTtsOutputAppliedPause
    | ActivityType.WizardVoiceoverTtsOutputCancelledApplyingPause
    | ActivityType.WizardVoiceoverTtsOutputRemovedPause
    | ActivityType.WizardVoiceoverTtsClickedAddPronunciation
    | ActivityType.WizardVoiceoverTtsOutputClickedAddPronunciation
    | ActivityType.WizardVoiceoverTtsOutputClickedApplyToAllOccurrences
    | ActivityType.WizardVoiceoverTtsOutputAppliedPronunciation
    | ActivityType.WizardVoiceoverTtsOutputCancelledApplyingPronunciation
    | ActivityType.WizardVoiceoverTtsOutputRemovedPronunciation
    | ActivityType.ProjectSpaceClickedBackArrow
    | ActivityType.ProjectSpaceClickedMenuItem
    | ActivityType.ProjectSpaceClickedBrandSection
    | ActivityType.ProjectSpaceHomepageClickedWizardContinue
    | ActivityType.ProjectSpaceClickedDownload
    | ActivityType.ProjectSpaceDocClickedEdit
    | ActivityType.ProjectSpaceCreativeDeckClickedEdit
    | ActivityType.ProjectHomepageClickedSchedule
    | ActivityType.ProjectHomepageClickedProjectDetails
    | ActivityType.ProjectHomepageClickedBackArrow
    | ActivityType.ProjectProductionScheduleApprovedDate
    | ActivityType.ProjectProductionScheduleExternalSubmitForReview
    | ActivityType.ProjectProductionScheduleApprovedEditedDate
    | ActivityType.ProjectSpaceAssetsClickAddDialogOpen
    | ActivityType.ProjectProductionScheduleSubmittedDate
    | ActivityType.ProjectSpaceAssetsClickAddDialogPickAsset
    | ActivityType.ProjectSpaceAssetsClickAddDialogPickBookmark
    | ActivityType.ProjectSpaceAssetsClickAddLinkSave
    | ActivityType.ProjectSpaceAssetsClickAddLinkCancel
    | ActivityType.ProjectSpaceAssetsClickDownloadSingle
    | ActivityType.ProjectSpaceAssetsClickDownloadAll
    | ActivityType.ProjectSpaceAssetsClickPreview
    | ActivityType.ProjectSpaceAssetsClickCopyBookmark
    | ActivityType.ProjectSpaceAssetsClickOpenBookmark
    | ActivityType.ProjectSpaceAssetsClickDelete
    | ActivityType.ProjectSpaceAssetsClickConfirmDelete
    | ActivityType.ProjectSpaceAssetsClickCancelDelete
    | ActivityType.ProjectSpaceNotesClickedUpdateSetNotes
    | ActivityType.WizardScriptedInputRemovedProjectFocus
    | ActivityType.WizardScriptedInputAddedProjectFocus
    | ActivityType.ProjectTimelineStepClickedContinue
    | ActivityType.WizardOutputClickedToLeaveComment
    | ActivityType.WizardOutputCanceledDiscardingComment
    | ActivityType.WizardOutputConfirmedDiscardingComment
    | ActivityType.WizardOutputCanceledRemoveComment
    | ActivityType.WizardOutputLeftComment
    | ActivityType.WizardOutputClickedEditComment
    | ActivityType.WizardOutputClickedRemoveComment
    | ActivityType.WizardOutputRemovedComment
    | ActivityType.WizardOutputResolvedComment
    | ActivityType.WizardOutputReopenedComment
    | ActivityType.WizardOutputOpenedCommentSidebar
    | ActivityType.WizardOutputClickedShowResolvedComments
    | ActivityType.WizardOutputRepliedToComment
    | ActivityType.CreativeDeckSavedSetNotesChanges
    | ActivityType.CreativeDeckSavedAudioSpecsEdits
    | ActivityType.CreativeDeckSavedCameraSpecsEdits
    | ActivityType.DeliverableSummaryClickedToEdit
    | ActivityType.DeliverableSummaryClickedDownload
    | ActivityType.CreativeBriefInputClickedShareForReview
    | ActivityType.CreativeBriefInputClickedSubmitForReview
    | ActivityType.CreativeBriefInputCanceledSubmittingForReview
    | ActivityType.CreativeBriefClickedShareForReview
    | ActivityType.CreativeBriefClickedSubmitForReview
    | ActivityType.CreativeBriefCanceledSubmittingForReview
    | ActivityType.PageViewClickedSubmit
    | ActivityType.PageViewClickedSubmitForReview
    | ActivityType.PageViewClickedApproveCallSheet
    | ActivityType.PageViewClickedApproveCreativeDeck
    | ActivityType.PageViewSubmittedForReview
    | ActivityType.PageViewCanceledSubmittingForReview
    | ActivityType.PageViewApprovedPage
    | ActivityType.PageViewCanceledApprovingPage
    | ActivityType.CreativeBriefInternalVisitedFirstTime
    | ActivityType.WizardInternalVisitedFirstTime
    | ActivityType.WizardExternalVisitedFirstTime
    | ActivityType.ProjectFormReviewDoCreativeBriefLater
    | ActivityType.ProjectFormReviewFillOutCreativeBrief
    | ActivityType.PageViewClickedApproveByClientBehalf
    | ActivityType.PageViewApprovedPageForClient
    | ActivityType.PageViewCanceledApprovingPageForClient
    | ActivityType.WizardClickedEditVideoName
    | ActivityType.WizardEditedVideoName
    | ActivityType.VideoReviewClickedActionsMenu
    | ActivityType.VideoReviewClickedShareInternally
    | ActivityType.VideoReviewSharedVideoInternally
    | ActivityType.VideoReviewCancelledSharingVideoInternally
    | ActivityType.VideoReviewClickedSendToClient
    | ActivityType.VideoReviewSentVideoToClient
    | ActivityType.VideoReviewCanceledSendingToClient
  > &
  WithVideoMetadata<
    | ActivityType.WizardOutputClickedLookAndFeelImage
    | ActivityType.WizardRegenerateLookAndFeelImage
    | ActivityType.WizardScriptClickGenerate
    | ActivityType.WizardScriptClickRegenerateWithNotes
    | ActivityType.WizardScriptSubmitFeedback
    | ActivityType.WizardScriptEditedSection
    | ActivityType.WizardScriptedScriptReorderedScene
    | ActivityType.WizardScriptedScriptCanceledSceneRemoval
    | ActivityType.WizardScriptedScriptConfirmedSceneRemoval
    | ActivityType.WizardScriptedScriptClickedSceneRemoval
    | ActivityType.WizardScriptedScriptClickedAddNewScene
    | ActivityType.WizardScriptedScriptSubmittedScene
    | ActivityType.WizardScriptedScriptCancelledSceneSubmission
    | ActivityType.WizardScriptOutputRemovedLine
    | ActivityType.WizardScriptOutputReorderedScriptLine
    | ActivityType.WizardScriptOutputCalceledLineRemoval
    | ActivityType.WizardScriptOutputClickedRemoveLine
    | ActivityType.WizardScriptOutputCancelledAddingLine
    | ActivityType.WizardScriptOutputClickedAddLine
    | ActivityType.WizardScriptOutputAddedScriptLine
    | ActivityType.WizardScriptedClientVideoSummaryClickRegenerate
    | ActivityType.WizardScriptedClientVideoSummaryInlineEdit
    | ActivityType.WizardScriptedClientVideoSummarySubmitFeedback
    | ActivityType.WizardScriptedCpVideoSummaryClickGenerate
    | ActivityType.WizardScriptedCpVideoSummarySubmitFeedback
    | ActivityType.WizardStoryboardAddNote
    | ActivityType.WizardStoryboardClickRegenerate
    | ActivityType.WizardStoryboardClickRegenerateWithFeedback
    | ActivityType.WizardVideoSummaryClickGenerate
    | ActivityType.WizardVideoSummarySubmitFeedback
    | ActivityType.WizardVisualsAddTakeaway
    | ActivityType.WizardVisualsAutoGenerate
    | ActivityType.WizardVisualsClickGenerate
    | ActivityType.WizardVisualsSubmitFeedback
    | ActivityType.WizardVoiceoverAddTakeaway
    | ActivityType.WizardVoiceoverGenerateTts
    | ActivityType.WizardVoiceoverPlayGeneratedTts
    | ActivityType.WizardVoiceoverTtsClickPlay
    | ActivityType.WizardVoiceoverTtsCollapsedSidebar
    | ActivityType.WizardVoiceoverTtsExpandedSidebar
    | ActivityType.WizardVoiceoverTtsAdjustedSpeed
    | ActivityType.WizardVoiceoverTtsClickedAddPause
    | ActivityType.WizardVoiceoverTtsOutputClickedAddPause
    | ActivityType.WizardVoiceoverTtsOutputAppliedPause
    | ActivityType.WizardVoiceoverTtsOutputCancelledApplyingPause
    | ActivityType.WizardVoiceoverTtsOutputRemovedPause
    | ActivityType.WizardVoiceoverTtsClickedAddPronunciation
    | ActivityType.WizardVoiceoverTtsOutputClickedAddPronunciation
    | ActivityType.WizardVoiceoverTtsOutputClickedApplyToAllOccurrences
    | ActivityType.WizardVoiceoverTtsOutputAppliedPronunciation
    | ActivityType.WizardVoiceoverTtsOutputCancelledApplyingPronunciation
    | ActivityType.WizardVoiceoverTtsOutputRemovedPronunciation
    | ActivityType.WizardOutputClickedWardrobeImage
    | ActivityType.WizardRegenerateWardrobeImage
    | ActivityType.WizardClickedEditVideoName
    | ActivityType.WizardEditedVideoName
  > &
  WithOptionalVideoMetadata<
    | ActivityType.WizardOutputClickedToLeaveComment
    | ActivityType.WizardOutputCanceledDiscardingComment
    | ActivityType.WizardOutputConfirmedDiscardingComment
    | ActivityType.WizardOutputCanceledRemoveComment
    | ActivityType.WizardOutputLeftComment
    | ActivityType.WizardOutputClickedEditComment
    | ActivityType.WizardOutputClickedRemoveComment
    | ActivityType.WizardOutputRemovedComment
    | ActivityType.WizardOutputResolvedComment
    | ActivityType.WizardOutputReopenedComment
    | ActivityType.WizardOutputOpenedCommentSidebar
    | ActivityType.WizardOutputClickedShowResolvedComments
    | ActivityType.WizardOutputRepliedToComment
  > &
  WithProjectAdjustmentMetadata<
    | ActivityType.ProjectTimelineDaysAdjustment
    | ActivityType.ProjectTimelineDueDateAdjustment
  >;

type CreatorActivityEventsMetadata = {
  [ActivityType.CreatorDashboardUpdatedAppliedJobsFilter]: {
    enabled: boolean;
  };
  [ActivityType.CreatorSignupAddedRole]: ValueMetadata<string>;
  [ActivityType.CreatorSignupAddedEquipment]: ValueMetadata<string>;
  [ActivityType.CreatorDashboardRejectedJob]: ValueMetadata<string>;
};

export type ActivityEventsMetadata = AppActivityEventsMetadata &
  CreatorActivityEventsMetadata;
