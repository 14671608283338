import { useFormContext } from "react-hook-form";

import { basicInfoFields } from "@ll-web/features/internalProjects/projectForm/basicInfo/basicInfoSchema";
import { makeBasicInfoName } from "@ll-web/features/internalProjects/projectForm/basicInfo/makeBasicInfoName";
import type { ProjectDeliverablesEnum } from "@ll-web/features/projects/enums";

export function useProjectDeliverables(): ProjectDeliverablesEnum {
  const { watch } = useFormContext();

  const deliverables = watch(
    makeBasicInfoName({ field: basicInfoFields.deliverables }),
  );

  return deliverables;
}
