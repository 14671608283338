import type { PropsWithChildren } from "react";

import { NavigationBarLayout } from "@ll-web/features/internalDashboard/components/layout/NavigationBarLayout";
import { ProjectFormLayout } from "@ll-web/features/internalProjects/pages/ProjectForm/components/ProjectFormLayout/ProjectFormLayout";
import { useProjectFormMode } from "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/hooks/useProjectFormMode";
import { ProjectFormRouteStepper } from "@ll-web/features/internalProjects/pages/ProjectForm/routeStepper/ProjectFormRouteStepper/ProjectFormRouteStepper";

export const ProjectFormRouteLayout = ({ children }: PropsWithChildren) => {
  const mode = useProjectFormMode();

  return (
    <NavigationBarLayout>
      <ProjectFormLayout
        stepperComponent={<ProjectFormRouteStepper />}
        mode={mode}
      >
        {children}
      </ProjectFormLayout>
    </NavigationBarLayout>
  );
};
