import type { Path } from "react-hook-form";

import {
  PROJECT_FIELDS,
  type ProjectFormValues,
} from "@ll-web/features/internalProjects/projectForm/projectFormSchema";

interface Args {
  field: string;
}

export const makeBasicInfoName = ({
  field,
}: Args): Path<Pick<ProjectFormValues, "basicInfo">> =>
  `${PROJECT_FIELDS.basicInfo}.${
    field as keyof ProjectFormValues["basicInfo"]
  }`;
