import { initializeApp, type FirebaseOptions } from "firebase/app";
import {
  collection,
  doc,
  getDocs,
  initializeFirestore,
  memoryLocalCache,
  memoryLruGarbageCollector,
  writeBatch,
} from "firebase/firestore";

import { FIREBASE_CONFIG } from "./firebase.config";

const firebaseConfig: FirebaseOptions = {
  apiKey: FIREBASE_CONFIG.REACT_APP_FIRESTORE_APIKEY,
  authDomain: FIREBASE_CONFIG.REACT_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: FIREBASE_CONFIG.REACT_APP_FIRESTORE_PROJECT_ID,
  storageBucket: FIREBASE_CONFIG.REACT_APP_FIRESTORE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_CONFIG.REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
  appId: FIREBASE_CONFIG.REACT_APP_FIRESTORE_APP_ID,
  measurementId: FIREBASE_CONFIG.REACT_APP_FIRESTORE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

// this garbageCollector solves the issue as in: https://github.com/firebase/firebase-js-sdk/issues/6739
const garbageCollector = memoryLruGarbageCollector();
const localCache = memoryLocalCache({ garbageCollector });

export const firestore = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
  localCache,
});

export const createRandomId = () => {
  return doc(collection(firestore, "random")).id;
};

export async function clearCollection(root: string, ...path: string[]) {
  const collectionRef = collection(firestore, root, ...path);
  const docs = await getDocs(collectionRef);
  const batch = writeBatch(firestore);
  docs.forEach((doc) => batch.delete(doc.ref));
  await batch.commit();
}
