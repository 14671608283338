import { boolean, mixed, object, string } from "yup";

import { makeConfig } from "@ll-web/config/env-config";
import type { FeatureFlags } from "@ll-web/config/featureFlags/featureFlags";

export enum AppEnvEnum {
  Production = "production",
  Staging = "staging",
  Development = "development",
  E2E = "e2e",
}

const appConfigSchema = object({
  REACT_APP_VERSION: string().required(), // Defined automatically by the bundler from package.json
  REACT_APP_API_URL: string().required(),
  REACT_APP_AMPLITUDE_KEY: string(),
  REACT_APP_SMARTLOOK_KEY: string(),
  REACT_APP_GOOGLE_MAPS_API_KEY: string().required(),
  REACT_APP_SENTRY_DSN: string(),
  REACT_APP_SENTRY_DEBUG: boolean().notRequired().default(false),
  REACT_APP_ENV: string().oneOf(Object.values(AppEnvEnum)).required(),
  REACT_APP_UPLOADCARE_PUBLIC_KEY: string().required(),
  REACT_APP_MUI_LICENSE_KEY: string().required(),
  REACT_APP_ANALYTICS_DEBUG: boolean().notRequired().default(false),
  REACT_APP_PLAYER_DEBUG: boolean().notRequired().default(false),
  REACT_APP_NEW_RELIC_LICENSE_KEY: string(),
  REACT_APP_NEW_RELIC_APPLICATION_ID: string(),
  REACT_APP_NEW_RELIC_AGENT_ID: string(),
  REACT_APP_NEW_RELIC_ACCOUNT_ID: string(),
  REACT_APP_NEW_RELIC_TRUST_KEY: string(),
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID: string(),
  REACT_APP_FEATURE_FLAG_OVERRIDES: mixed<Partial<FeatureFlags>>().transform(
    (value) => JSON.parse(value.replace(/^\s*#.*$/gm, "")), // Ignore commented lines
  ),
});

export const APP_CONFIG = makeConfig({
  schema: appConfigSchema,
  name: "APP",
});
