import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
  type ReactNode,
} from "react";

import { CssBaseline, ThemeProvider, type Theme } from "@mui/material";

import { lemonlightMuiDarkTheme } from "@ll-web/core/theme/themes/LemonlightMui/LemonlightMuiDarkTheme";
import { lemonlightMuiLightTheme } from "@ll-web/core/theme/themes/LemonlightMui/LemonlightMuiLightTheme";

const themeVariantMap: Record<ThemeVariant, Theme> = {
  light: lemonlightMuiLightTheme,
  dark: lemonlightMuiDarkTheme,
};

type ThemeVariant = "light" | "dark";

type SelectedThemeContextType = {
  variant: ThemeVariant;
  setVariant: (variant: ThemeVariant) => void;
};
const SelectedThemeContext = createContext<SelectedThemeContextType>({
  variant: "light",
  setVariant: () => {},
});
SelectedThemeContext.displayName = "SelectedThemeContext";

export function useSelectedTheme() {
  return useContext(SelectedThemeContext);
}

type GlobalThemeProviderProps = {
  children: ReactNode;
};

export const GlobalThemeProvider = ({ children }: GlobalThemeProviderProps) => {
  const [variant, setVariant] = useState<ThemeVariant>("light");

  const contextValue = useMemo(
    () => ({
      variant,
      setVariant,
    }),
    [variant, setVariant],
  );

  return (
    <SelectedThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={themeVariantMap[variant]}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </SelectedThemeContext.Provider>
  );
};

export const EnsureDarkTheme = ({ children }: PropsWithChildren) => {
  const selectedTheme = useSelectedTheme();

  useEffect(() => {
    if (selectedTheme.variant === "dark") {
      return;
    }

    const previous = selectedTheme.variant;
    selectedTheme.setVariant("dark");

    return () => {
      selectedTheme.setVariant(previous);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (selectedTheme.variant === "dark") {
    return children;
  }

  return null;
};

type ScopedThemeProviderProps = PropsWithChildren<{
  variant: ThemeVariant;
}>;

export const ScopedThemeProvider = ({
  children,
  variant,
}: ScopedThemeProviderProps) => {
  return (
    <ThemeProvider theme={themeVariantMap[variant]}>{children}</ThemeProvider>
  );
};
