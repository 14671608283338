import { useEffect } from "react";

import { Timestamp } from "firebase/firestore";
import { useSearchParams } from "react-router-dom";

import {
  QueryParameters,
  type SourceType,
} from "@ll-web/consts/query-parameters";
import { activityTracker } from "@ll-web/core/analytics/activityTracker";
import { ActivityType } from "@ll-web/core/analytics/events";
import { mapProjectToAnalytics } from "@ll-web/core/analytics/eventUtils";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { useUpdateActiveUserSettings } from "@ll-web/features/auth/userSettings/async/useUserSettingsMutations";
import { useGetActiveUserSettings } from "@ll-web/features/auth/userSettings/async/useUserSettingsQueries";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import { useUpdateProjectFields } from "@ll-web/features/projects/async/useProjectsMutations";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { defined } from "@ll-web/utils/types/types";

import { useActiveProject } from "./useActiveProject";

type UseWizardUserAnalyticsArgs = {
  flowType: WizardFlowType;
};

export function useWizardUserAnalytics({
  flowType,
}: UseWizardUserAnalyticsArgs) {
  const { activeUser } = useActiveUser();
  const { activeProject } = useActiveProject();
  const { data: userSettings } = useGetActiveUserSettings();
  const {
    mutateAsync: updateUserSettings,
    isPending: isUpdateUserSettingsPending,
  } = useUpdateActiveUserSettings();
  const [params] = useSearchParams();
  const source = (params.get(QueryParameters.Source) ??
    "dashboard") as SourceType;
  const {
    mutateAsync: mutateUpdateProjectFieldsAsync,
    isPending: isUpdateProjectFieldsPending,
  } = useUpdateProjectFields();

  const shouldSendAnalyticsForInternalCreativeBrief =
    !isUpdateProjectFieldsPending &&
    isUserInternal(activeUser) &&
    !defined(activeProject.internalCreativeBriefFirstViewedAt) &&
    flowType === WizardFlowType.CreativeBrief;
  const shouldSendAnalyticsForInternalWizard =
    !isUpdateProjectFieldsPending &&
    isUserInternal(activeUser) &&
    !defined(activeProject.internalWizardFirstViewedAt) &&
    flowType === WizardFlowType.Generate;
  const shouldSendAnalyticsForExternalWizard =
    !isUpdateProjectFieldsPending &&
    !isUserInternal(activeUser) &&
    !defined(activeProject.externalWizardFirstViewedAt) &&
    flowType === WizardFlowType.Generate;

  useEffect(() => {
    if (
      !isUserInternal(activeUser) &&
      !defined(userSettings.firstWizardViewAt) &&
      !isUpdateUserSettingsPending
    ) {
      activityTracker.log({
        type: ActivityType.WizardUserVisitedFirstTime,
        metadata: {
          source,
        },
      });
      // eslint-disable-next-line no-void
      void updateUserSettings({
        firstWizardViewAt: new Date(),
      });
    }
  }, [
    activeUser,
    isUpdateUserSettingsPending,
    source,
    updateUserSettings,
    userSettings,
  ]);

  useEffect(() => {
    if (!shouldSendAnalyticsForInternalCreativeBrief) {
      return;
    }

    activityTracker.log({
      type: ActivityType.CreativeBriefInternalVisitedFirstTime,
      metadata: {
        ...mapProjectToAnalytics(activeProject),
      },
    });
    mutateUpdateProjectFieldsAsync({
      id: activeProject.id,
      data: {
        internalCreativeBriefFirstViewedAt: Timestamp.fromDate(new Date()),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeProject.id,
    shouldSendAnalyticsForInternalCreativeBrief,
    mutateUpdateProjectFieldsAsync,
  ]);

  useEffect(() => {
    if (!shouldSendAnalyticsForInternalWizard) {
      return;
    }

    activityTracker.log({
      type: ActivityType.WizardInternalVisitedFirstTime,
      metadata: {
        ...mapProjectToAnalytics(activeProject),
      },
    });
    mutateUpdateProjectFieldsAsync({
      id: activeProject.id,
      data: {
        internalWizardFirstViewedAt: Timestamp.fromDate(new Date()),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeProject.id,
    shouldSendAnalyticsForInternalWizard,
    mutateUpdateProjectFieldsAsync,
  ]);

  useEffect(() => {
    if (!shouldSendAnalyticsForExternalWizard) {
      return;
    }

    activityTracker.log({
      type: ActivityType.WizardExternalVisitedFirstTime,
      metadata: {
        ...mapProjectToAnalytics(activeProject),
      },
    });
    mutateUpdateProjectFieldsAsync({
      id: activeProject.id,
      data: {
        externalWizardFirstViewedAt: Timestamp.fromDate(new Date()),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeProject.id,
    shouldSendAnalyticsForExternalWizard,
    mutateUpdateProjectFieldsAsync,
  ]);
}
