import { createQueryKeys } from "@lukemorales/query-key-factory";

import { projectTimelineService } from "@ll-web/features/projectTimeline/async/ProjectTimelineService";
import { createQueryHook } from "@ll-web/utils/factories/createQueryHook";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

export const ProjectTimelineQueries = createQueryKeys("projectTimeline", {
  getTimeline: createQueryOption(
    projectTimelineService.getTimeline.bind(projectTimelineService),
  ),
});

export const useGetProjectTimeline = createQueryHook(
  ProjectTimelineQueries.getTimeline,
);
