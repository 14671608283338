import { useEffect, useRef, useState, type ReactNode } from "react";

import { Tooltip, Typography, type TypographyProps } from "@mui/material";

import type { $TSFixMe } from "@ll-web/utils/types/types";

type TextWithEllipsisProps = {
  children: ReactNode;
  text?: string;
} & Omit<Partial<TypographyProps>, "children">;

export const TextWithEllipsis = ({
  children,
  text,
  ...props
}: TextWithEllipsisProps) => {
  const ref = useRef<null | HTMLSpanElement>();
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (ref.current.scrollWidth > ref.current.clientWidth) {
      setIsOverflowing(true);
    }
  }, [children]);

  return (
    <Tooltip title={text ?? children} disableHoverListener={!isOverflowing}>
      <Typography noWrap {...props} ref={ref as $TSFixMe}>
        {children}
      </Typography>
    </Tooltip>
  );
};
