import { styled, Typography } from "@mui/material";
import { isString } from "lodash-es";

import { extractErrorMessage } from "@ll-web/utils/helpers/errors";

interface ErrorMessageProps {
  error?: string | unknown;
}

const ErrorMessageComponent = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

export function ErrorMessage({
  error = "Something went wrong",
}: ErrorMessageProps) {
  if (isString(error)) {
    return <ErrorMessageComponent>{error}</ErrorMessageComponent>;
  }

  const message = extractErrorMessage({ error });

  if (message) {
    return <ErrorMessageComponent>{message}</ErrorMessageComponent>;
  }

  return <ErrorMessageComponent>Something went wrong</ErrorMessageComponent>;
}
