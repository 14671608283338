import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { useUser } from "@ll-web/features/auth/hooks/useUser";
import { userSettingsService } from "@ll-web/features/auth/userSettings/async/UserSettingsService";
import { defaultUserSettings } from "@ll-web/features/auth/userSettings/config/defaultUserSettings";
import { createQueryOption } from "@ll-web/utils/factories/createQueryOption";

export const UserSettingsQueries = createQueryKeys("userSettings", {
  getActiveUserSettings: createQueryOption(
    userSettingsService.getActiveUserSettingsByUserId.bind(userSettingsService),
  ),
});

export function useGetActiveUserSettings() {
  const { activeUser } = useUser();

  return useQuery({
    ...UserSettingsQueries.getActiveUserSettings({
      userId: activeUser?._id ?? "",
    }),
    enabled: !!activeUser,
    refetchOnMount: false,
    retry: 1,
    initialData: defaultUserSettings,
    initialDataUpdatedAt: Date.now() - 60_000, // already stale - refetch on use
  });
}
