import { Pages } from "@ll-web/core/router/pages";

export function makePostProductionUrl(
  projectId: string,
  deliverableKey: string,
  versionName?: string,
) {
  const postProductionMediaUrl =
    Pages.PostProductionVideoReviewLatest.replaceAll(
      ":id",
      projectId,
    ).replaceAll(":deliverableKey", deliverableKey);

  if (versionName) {
    return `${postProductionMediaUrl}/${versionName}`;
  }

  return postProductionMediaUrl;
}
