import { AxiosError } from "axios";
import dayjs, { type Dayjs } from "dayjs";
import type { Duration } from "dayjs/plugin/duration";
import { set } from "lodash-es";

import { isDev } from "@ll-web/config/isDev";
import { CHARS } from "@ll-web/utils/helpers/specialCharacters";
import { assertDefined, defined, truthy } from "@ll-web/utils/types/types";

import { mapUnknownToDayjs } from "./date";

export const titleCase = (text: string): string =>
  text
    .replace(/^[_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[_]+(.)/g, (_, c) => " " + c.toUpperCase())
    .replace(/-/g, CHARS.nonBreakingHyphen);

const englishOrdinalRules = new Intl.PluralRules("en", { type: "ordinal" });

export const formatNumberToOrdinal = (number: number) => {
  const group = englishOrdinalRules.select(number);

  switch (group) {
    case "one": {
      return `${number}st`;
    }

    case "two": {
      return `${number}nd`;
    }

    case "few": {
      return `${number}rd`;
    }

    default: {
      return `${number}th`;
    }
  }
};

export async function wait(duration: number): Promise<void> {
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export function nextTick(callback: (...args: unknown[]) => void) {
  return setTimeout(callback, 0);
}

export function formatDateOrNever({
  date,
}: {
  date: Date | undefined | undefined;
}): string {
  if (!date) {
    return "Never";
  }

  return dayjs(date).format("LL");
}

export function getPersonFullName(
  person: { firstName?: string; lastName?: string } | null | undefined,
): string | null {
  if (!defined(person)) {
    return null;
  }

  const fullName = [person.firstName, person.lastName]
    .filter(defined)
    .join(" ");

  if (!fullName) {
    return null;
  }

  return fullName;
}

export function getPersonInitials(
  person: { firstName?: string; lastName?: string } | null | undefined,
): string | null {
  if (!person) {
    return null;
  }

  return (
    `${person.firstName?.[0] || ""}${person.lastName?.[0] || ""}`.toUpperCase() ||
    null
  );
}

export function formatSecondsToHumanReadable(
  durationInSeconds: number | null | undefined,
): string {
  if (
    durationInSeconds === undefined ||
    durationInSeconds === null ||
    Number.isNaN(Number(durationInSeconds))
  ) {
    return CHARS.middleDash;
  }

  return formatDurationToHumanReadable(
    dayjs.duration(durationInSeconds, "seconds"),
  );
}

export function formatDurationToHumanReadable(duration: Duration): string {
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const formattedDuration: string[] = [];

  if (hours > 0) {
    formattedDuration.push(`${hours} ${hours === 1 ? "hour" : "hours"}`);
  }

  if (minutes > 0) {
    formattedDuration.push(
      `${minutes} ${minutes === 1 ? "minute" : "minutes"}`,
    );
  }

  if (seconds > 0 || !formattedDuration.length) {
    formattedDuration.push(
      `${seconds} ${seconds === 1 ? "second" : "seconds"}`,
    );
  }

  return formattedDuration.join(", ");
}

export const formatDateDurationToHumanReadable = (
  dateInUnknownFormat: string | Date | Dayjs | null,
  duration: number,
  timezone?: string,
) => {
  const date = mapUnknownToDayjs(dateInUnknownFormat)?.tz(timezone);

  if (!date) {
    return null;
  }

  const dateStart = date.format(`dddd, MMMM D`);
  const timeStart = date.format(`h:mm A`);

  const dateEnd = date.add(duration, "second").format(`dddd, MMMM D`);
  const timeEnd = date.add(duration, "second").format(`h:mm A`);

  const endsNextDay = dateStart !== dateEnd;

  return `${dateStart} • ${timeStart} to ${endsNextDay ? `${dateEnd} • ` : ""}${timeEnd}`;
};

export function insertPropertyAfter<
  T extends Record<string, unknown>,
  K extends keyof T = keyof T,
>(obj: Omit<T, K>, previousKey: K, newEntry: [K, T[K]]): T {
  let insertIndex: number | null = null;
  for (const [index, [key]] of Object.entries(obj).entries()) {
    if (key === previousKey) {
      insertIndex = index + 1;
      break;
    }
  }

  if (insertIndex === null) {
    throw new Error(
      `Previous key '${String(previousKey)}' not found in the object.`,
    );
  }

  const newEntries = Object.entries(obj);
  newEntries.splice(insertIndex, 0, newEntry as [string, unknown]);

  return Object.fromEntries(newEntries) as T;
}

export async function ignoreErrors(fn: () => unknown | Promise<unknown>) {
  try {
    await fn();
  } catch (error) {
    if (isDev()) {
      console.warn("[ignoreErrors]", error);
    }
  }
}

export function hasDuplicates<T>(arr: T[]): boolean {
  const uniqueSet = new Set(arr);

  return uniqueSet.size !== arr.length;
}

export function getRandomInt(min: number, max: number) {
  const minLimit = Math.ceil(min);
  const maxLimit = Math.floor(max);

  return Math.floor(Math.random() * (maxLimit - minLimit + 1)) + minLimit;
}

async function readableStreamToBlob(stream: ReadableStream): Promise<Blob> {
  const blob = await new Response(stream).blob();

  return blob;
}

export async function readableStreamToArrayBuffer(
  stream: ReadableStream,
): Promise<ArrayBuffer> {
  const blob = await readableStreamToBlob(stream);
  const buffer = await blob.arrayBuffer();

  return buffer;
}

export async function downloadFile(
  source: string | Blob | ReadableStream,
  fileName: string,
) {
  let href: string | null = null;

  if (typeof source === "string") {
    const response = await fetch(source);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    href = blobUrl;
  } else if (source instanceof Blob) {
    href = URL.createObjectURL(source);
  } else if (source instanceof ReadableStream) {
    const blob = await readableStreamToBlob(source);
    href = URL.createObjectURL(blob);
  }

  assertDefined(href, "href");

  const a = document.createElement("a");
  a.style.display = "none";
  a.href = href;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(a.href);
  setTimeout(() => {
    document.body.removeChild(a);
  }, 1000);
}

export function isBadRequestError(error: unknown) {
  if (error instanceof AxiosError && error.response?.status) {
    return error.response.status < 500;
  }

  if (
    typeof error === "object" &&
    error &&
    "status" in error &&
    error.status &&
    typeof error.status === "number" &&
    error.status < 500
  ) {
    return true;
  }

  return false;
}

export function pluralize(text: string, count: number, showCount = false) {
  return `${showCount ? `${count} ` : ""}${text}${count === 1 ? "" : "s"}`;
}

export function greatestCommonDivisor(a: number, b: number): number {
  if (!b) {
    return a;
  }

  return greatestCommonDivisor(b, a % b);
}

export function getAspectRatio({
  width,
  height,
}: {
  width: number;
  height: number;
}): {
  aspectRatio: string;
  width: number;
  height: number;
  orientation: "portrait" | "landscape" | "square";
} {
  const gcd = greatestCommonDivisor(width, height);
  const w = width / gcd;
  const h = height / gcd;
  const orientation =
    // eslint-disable-next-line no-nested-ternary
    w > h ? "landscape" : w < h ? "portrait" : "square";

  return {
    aspectRatio: `${w}x${h}`,
    width: w,
    height: h,
    orientation,
  };
}

export function objectFromNestedEntries<T extends object>(
  entries: [string, unknown][] | (readonly [string, unknown])[],
) {
  const temp = {};
  entries.forEach(([path, value]) => set(temp, path, value));

  return temp as T;
}

// Check if a flag is truthy in any object recursively
export function deepRecursiveCheck(
  obj: unknown,
  property: string,
  checker: (value: unknown) => boolean = truthy,
): boolean {
  if (!obj || typeof obj !== "object") {
    return false;
  }

  if (checker(obj[property as keyof typeof obj])) {
    return true;
  }

  return Object.values(obj).some((value) =>
    deepRecursiveCheck(value, property, checker),
  );
}

export async function asyncNoop() {}
