export function collectionDiff<T extends { id: string | undefined }>(
  previous: T[] = [],
  next: T[] = [],
): { added: T[]; removed: T[]; rest: T[] } {
  const previousIds = previous.map((previousItem) => previousItem.id);
  const nextIds = next.map((nextItem) => nextItem.id);
  const addedIds = nextIds.filter(
    (nextId) => !nextId || !previousIds.includes(nextId),
  );
  const removedIds = previousIds.filter(
    (previousId) => !nextIds.includes(previousId),
  );

  const added = next.filter((nextItem) => addedIds.includes(nextItem.id));
  const removed = previous.filter((previousItem) =>
    removedIds.includes(previousItem.id),
  );
  const rest = next.filter((nextItem) => !addedIds.includes(nextItem.id));

  return { added, removed, rest };
}
