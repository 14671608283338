import { BLOCKING_PHRASES } from "@ll-web/components/NotifyForReviewModal/consts";
import type { ProjectStyleEnum } from "@ll-web/config/featureFlags/consts";
import { useActiveUser } from "@ll-web/features/auth/hooks/useActiveUser";
import { AccountType, type User } from "@ll-web/features/auth/types";
import {
  useGetBrandById,
  useGetBrandByProjectId,
} from "@ll-web/features/brands/async/useBrandsQueries";
import {
  BrandRoleEnum,
  type BrandUserWithProfile,
} from "@ll-web/features/brands/types";
import { ProjectSpacePages } from "@ll-web/features/projectSpace/enums";
import { makeProjectSpacePagePath } from "@ll-web/features/projectSpace/utils/navigation";
import { useActiveProject } from "@ll-web/features/projectWizard/hooks/useActiveProject";
import { UsersQueries } from "@ll-web/features/users/async/useUsersQueries";
import { signature } from "@ll-web/utils/helpers/emails";
import { getPersonFullName } from "@ll-web/utils/helpers/helpers";
import { useBatchQuery } from "@ll-web/utils/hooks/useBatchQueries";
import { defined } from "@ll-web/utils/types/types";

import { CreativeDeckReviewNotificationType } from "./creativeDeckReviewConsts";

enum CreativeDeckReviewApprovalKey {
  NotifyInternal = "creativeDeck:notifyInternal",
  Approve = "creativeDeck:approve",
}

export const CreativeDeckApprovaNotificationMap: Record<
  CreativeDeckReviewNotificationType,
  CreativeDeckReviewApprovalKey
> = {
  [CreativeDeckReviewNotificationType.ApproveExternal]:
    CreativeDeckReviewApprovalKey.Approve,
  [CreativeDeckReviewNotificationType.ApproveInternal]:
    CreativeDeckReviewApprovalKey.Approve,
  [CreativeDeckReviewNotificationType.SubmitForReviewExternal]:
    CreativeDeckReviewApprovalKey.NotifyInternal,
  [CreativeDeckReviewNotificationType.SubmitForReviewInternal]:
    CreativeDeckReviewApprovalKey.Approve,
};

export const creativeDeckExternalEditEmailTemplate = {
  title: ({ projectName }: { projectName: string }) =>
    `Creative Deck edited/submitted for review for ${projectName}`,
  message: ({
    defaultReceiverFirstName,
    projectId,
    projectName,
    senderName,
  }: {
    defaultReceiverFirstName: string;
    projectId: string;
    projectName: string;
    senderName: string | null;
  }): string => {
    const creativeDeckLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}`;

    return `Hi ${defaultReceiverFirstName},

I have reviewed the Creative Deck for ${projectName} and applied some edits/comments to it.
Please take a moment to check the details.

You can view the Creative Deck here: ${creativeDeckLink}

Thanks${signature(senderName)}`;
  },
};

export const creativeDeckInternalEditEmailTemplate = {
  title: ({ projectName }: { projectName: string }) =>
    `Great news! Creative Deck for ${projectName} is ready for final approval`,
  message: ({
    defaultReceiverFirstName,
    projectId,
    projectName,
  }: {
    defaultReceiverFirstName: string;
    projectId: string;
    projectName: string;
  }) => {
    const creativeDeckLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}`;

    return `${defaultReceiverFirstName},

I'm excited to let you know that the Creative Deck for your ${projectName} project is now finalized. Please take a moment to review:

Creative Deck - ${creativeDeckLink}: Outlines all of the creative details for the videos.

You can approve the documents by ${BLOCKING_PHRASES.date.text}. If you have any additional feedback before you approve, you may also leave comments directly in the platform.

Let us know if you have any questions. Looking forward to your feedback!

Best,
Lemonlight`;
  },
};

const notifyReviewEmailDefaults: Record<
  CreativeDeckReviewNotificationType,
  {
    title: (props: { projectName: string }) => string;
    message: (props: {
      projectName: string;
      projectStyle: ProjectStyleEnum;
      defaultReceiverFirstName: string;
      projectId: string;
      senderName: string | null;
    }) => string;
  }
> = {
  [CreativeDeckReviewNotificationType.ApproveExternal]: {
    title: ({ projectName }) => `Creative Deck approved for ${projectName}`,
    message: ({
      defaultReceiverFirstName,
      projectId,
      senderName,
      projectName,
    }) => {
      const creativeDeckLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}`;

      return `Hi ${defaultReceiverFirstName},

I've approved the Creative Deck for ${projectName}.
You can view it here: ${creativeDeckLink}.

We can proceed with the next steps as planned.

Thank you!
${senderName}`;
    },
  },
  [CreativeDeckReviewNotificationType.SubmitForReviewExternal]:
    creativeDeckExternalEditEmailTemplate,
  [CreativeDeckReviewNotificationType.ApproveInternal]: {
    title: ({ projectName }) =>
      `Creative Deck for ${projectName} project is Approved on Your Behalf`,
    message: ({
      defaultReceiverFirstName,
      projectId,
      senderName,
      projectName,
    }) => {
      const creativeDeckLink = `${location.origin}${makeProjectSpacePagePath(projectId, ProjectSpacePages.CreativeDeck)}`;

      return `Hi ${defaultReceiverFirstName},

The Creative Deck for the ${projectName} project has been approved on your behalf.

You can now view the final version of the Creative Deck here: ${creativeDeckLink}

Thanks,
${senderName}`;
    },
  },
  [CreativeDeckReviewNotificationType.SubmitForReviewInternal]:
    creativeDeckInternalEditEmailTemplate,
};

const getDefaultReceiverForNotificationType = {
  [CreativeDeckReviewNotificationType.ApproveExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [CreativeDeckReviewNotificationType.SubmitForReviewExternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [CreativeDeckReviewNotificationType.ApproveInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
  [CreativeDeckReviewNotificationType.SubmitForReviewInternal]: (
    users: BrandUserWithProfile[],
  ) => {
    return [
      users.find((user) => user.role === BrandRoleEnum.Owner),
      users.find((user) => user.user?.accountType === AccountType.Producer),
      users.find(
        (user) => user.user?.accountType === AccountType.AccountExecutive,
      ),
    ].filter(defined);
  },
};

export const useCreativeDeckPageReviewFormData = (
  notificationType: CreativeDeckReviewNotificationType,
): {
  people: string[];
  title: string;
  message: string;
  defaultAutocompleteUsers: {
    isFetching: boolean;
    data: User[];
  };
} => {
  const { activeProject } = useActiveProject();
  const { activeUser } = useActiveUser();

  const brandByProjectQuery = useGetBrandByProjectId({
    projectId: activeProject.id,
  });

  const brandId = brandByProjectQuery.data?._id;
  const brandByIdQuery = useGetBrandById(
    {
      brandId: brandId!,
    },
    { enabled: !!brandId },
  );

  const projectTeam =
    brandByIdQuery?.data?.team?.filter((teamMember) => !!teamMember.user) ?? [];

  const defaultReceivers =
    getDefaultReceiverForNotificationType[notificationType](projectTeam);

  const defaultReceiversIds = defaultReceivers
    .map((receiverProfile) => receiverProfile.user?._id)
    .filter(defined);

  const defaultUsersQuery = useBatchQuery(
    defaultReceiversIds.map((userId) => UsersQueries.getById({ userId })),
  );

  return {
    people: defaultReceiversIds ?? [],
    message: notifyReviewEmailDefaults[notificationType].message({
      projectName: activeProject.title,
      senderName: getPersonFullName(activeUser),
      defaultReceiverFirstName:
        defaultReceivers.find(
          (userData) => userData?.user?._id === defaultReceivers[0].user?._id,
        )?.user?.firstName ?? "",
      projectId: activeProject.id,
      projectStyle: activeProject.style,
    }),
    title: notifyReviewEmailDefaults[notificationType].title({
      projectName: activeProject.title,
    }),
    defaultAutocompleteUsers: {
      isFetching: defaultUsersQuery.isFetching,
      data: defaultUsersQuery.data?.filter(defined) ?? [],
    },
  };
};
