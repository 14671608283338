import { useParams } from "react-router-dom";

import { assertDefined } from "@ll-web/utils/types/types";

export function useDeliverablePathParams() {
  const params = useParams<{
    id: string;
    deliverableKey: string;
    versionName: string;
  }>();

  assertDefined(params.id, ":id");
  assertDefined(params.deliverableKey, ":deliverableKey");
  // versionName is optional, defaults to latest

  return {
    projectId: params.id,
    deliverableKey: params.deliverableKey,
    versionName: params.versionName,
  };
}
