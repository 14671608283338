import { charactersCombinedDescriptionExamplesClinic } from "@ll-web/features/llm/prompts/CharacterCombinedDescription/examples/clinic";
import { characterCombinedDescriptionMockLuggage } from "@ll-web/features/llm/prompts/CharacterCombinedDescription/mocks/luggage";
import { characterCombinedDescriptionSchema } from "@ll-web/features/llm/prompts/CharacterCombinedDescription/schema";
import type {
  CharacterCombinedDescriptionOutput,
  CharacterCombinedDescriptionPayload,
} from "@ll-web/features/llm/prompts/CharacterCombinedDescription/types";
import { JsonPrompt } from "@ll-web/features/llm/prompts/JsonPrompt";
import type {
  Prompt,
  PromptModelOptions,
} from "@ll-web/features/llm/prompts/Prompt";
import type { MessageType, PromptDumpType } from "@ll-web/features/llm/types";

export class CharacterCombinedDescriptionPrompt extends JsonPrompt<
  CharacterCombinedDescriptionOutput,
  CharacterCombinedDescriptionPayload
> {
  static readonly name = "CharacterCombinedDescription";
  static readonly version = "1.0.0";
  static readonly description =
    "Character combined description and key info for multiple videos";

  static readonly mockPayloads = [characterCombinedDescriptionMockLuggage];
  static readonly examples = [charactersCombinedDescriptionExamplesClinic];

  constructor(protected payload: CharacterCombinedDescriptionPayload) {
    super(
      characterCombinedDescriptionSchema,
      CharacterCombinedDescriptionPrompt.examples,
    );
  }

  public getModelOptions(): PromptModelOptions {
    return {
      ...super.getModelOptions(),
      temperature: 0.2,
    };
  }

  protected getSystemPrompt(): string {
    return `
You are casting assistant in a video production company. You help prepare a casting call.
`.trim();
  }

  protected getMainUserPrompt(): string {
    return `
For cost optimization, our company tries to use the same actor for multiple related videos.
Based on the provided role descriptions, generate a single combined summary and extract common key information.
Preserve original name. If there is conflicting information across videos, try to create a description that fits best to all videos.
${this.getKeyInfoInstructions()}

${this.getCharacterSummaries()}
`.trim();
  }

  protected getCharacterSummaries() {
    const { characterSummaries } = this.payload;

    return characterSummaries
      .map(({ summary }, i) => `Video #${i + 1}:${summary}`)
      .join("\n\n");
  }

  protected getKeyInfoInstructions() {
    return `
For key information, include race, gender, age, and optionally eye color, hair color, height and special skills like in the example. Write full sentences like "This person is...", "His height is below...", "Her skills include".
You must always include gender and age in the following format: "{gender} of age {age range}". You must include specific properties like eye color, hair color or height only if they were explicitly mentioned for any video. Omit it entirely if unspecified.

${this.getKeyInfoExampleValues}
`.trim();
  }

  protected getKeyInfoExampleValues() {
    return `
Example values (not limited to):
Ethnicity: African American or Black, Asian, White / Caucasian, American Indian, Hispanic / Latin, Other, Any...
Gender: Man, Woman, Non-binary, Any...
Age:  Young Adult (19-29), Adult (29-55), Senior (55+), Child (3-12), Young Adult (20-30), Baby (0-3), Any...
Skills: Fit, Riding a bike, Football, Soccer, Sports, Driver, Yoga, Comedy, Plays an instrument, Singing, Dancing, Swimming...
        `.trim();
  }

  public compile(additionalMessages: MessageType[] = []) {
    const messages: MessageType[] = [
      {
        role: "system",
        content: this.getSystemPrompt(),
      },
      {
        role: "user",
        content: this.getMainUserPrompt(),
      },
    ];

    messages.push(...additionalMessages);

    return super.compile(messages);
  }

  public dump(): PromptDumpType {
    const constructor = this.constructor as typeof Prompt;

    return {
      metadata: {
        name: constructor.name,
        version: constructor.version,
        description: constructor.description,
        format: constructor.format,
      },
    };
  }
}
