import type { AvatarProps } from "@mui/material";

import { PersonAvatar } from "@ll-web/components/PersonAvatar/PersonAvatar";
import type { User } from "@ll-web/features/auth/types";
import { getPersonInitials } from "@ll-web/utils/helpers/helpers";

export type UserAvatarProps = AvatarProps & {
  user: User | null | undefined;
  size?: number;
};

export const UserAvatar = ({ user, size = 32, ...props }: UserAvatarProps) => {
  return (
    <PersonAvatar src={user?.avatar?.url} size={size} {...props}>
      {getPersonInitials(user)}
    </PersonAvatar>
  );
};
