import { useCallback, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import { FeatureFlagName } from "@ll-web/config/featureFlags/featureFlags";
import { useAppFlags } from "@ll-web/core/featureFlags/useAppFlags";
import {
  useHomepagePagePath,
  useProjectHomepagePath,
} from "@ll-web/core/router/hooks";
import type { WizardSteps } from "@ll-web/features/projectWizard/consts/wizardSteps";
import { WizardFlowType } from "@ll-web/features/projectWizard/contexts/WizardNavigationContext";
import { makeWizardPath } from "@ll-web/features/projectWizard/utils/navigation";
import {
  useFromState,
  useMakeFromState,
} from "@ll-web/utils/hooks/useNavigation";

import { useActiveProject } from "./useActiveProject";

export function useNavigateBackFromWizard() {
  const navigate = useNavigate();

  const { activeProject } = useActiveProject();

  const homepagePath = useHomepagePagePath();
  const projectHomepagePath = useProjectHomepagePath();
  const flags = useAppFlags();
  const fromState = useFromState();

  const backPath =
    fromState ??
    (flags[FeatureFlagName.ProjectSpace] &&
    activeProject.isCreativeBriefInputFilled !== false
      ? projectHomepagePath(activeProject.id)
      : homepagePath);

  const isFromState = !!fromState;

  const navigateBack = useCallback(
    () =>
      navigate(backPath, {
        replace: isFromState,
      }),
    [backPath, navigate, isFromState],
  );

  return useMemo(
    () => ({ navigateBack, isFromState }),
    [navigateBack, isFromState],
  );
}

function useNavigateToWizardFlow(flowType: WizardFlowType) {
  const navigate = useNavigate();
  const makeFromState = useMakeFromState();

  const { activeProject } = useActiveProject();

  return useCallback(
    (
      step: WizardSteps,
      {
        backAnchor,
        queryParams,
      }: { backAnchor?: string; queryParams?: Record<string, string> } = {},
    ) => {
      navigate(
        {
          pathname: makeWizardPath(activeProject.id, flowType, step),
          search: queryParams
            ? new URLSearchParams(queryParams).toString()
            : undefined,
        },
        { state: { ...makeFromState(backAnchor) } },
      );
    },
    [activeProject.id, navigate, makeFromState, flowType],
  );
}

export function useNavigateToEditWizardForCreativeDeck() {
  return useNavigateToWizardFlow(WizardFlowType.EditCreativeDeck);
}

export function useNavigateToEditWizardForCallSheet() {
  return useNavigateToWizardFlow(WizardFlowType.EditCallSheet);
}
