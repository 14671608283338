import { useMemo } from "react";

import { defaultLayoutIcons } from "@vidstack/react/player/layouts/default";
import { merge } from "lodash-es";

import { APP_CONFIG } from "@ll-web/config/app.config";
import { withVideoCommentsPlugin } from "@ll-web/features/videoPlayer/comments/config";
import { withControlsBelowPlayerPlugin } from "@ll-web/features/videoPlayer/controlsBelowPlayer/config";
import {
  VideoPlayerPlugins,
  type BaseVideoPlayerConfig,
  type VideoPlayerConfig,
} from "@ll-web/features/videoPlayer/types";
import { typedEntries } from "@ll-web/utils/types/types";

const videoPlayerDefaultConfig: BaseVideoPlayerConfig = {
  playerConfig: {
    autoFocus: false,
    autoplay: false,
    controlsDelay: 1000,
    hideControlsOnMouseLeave: true,
    keyDisabled: false,
    keyTarget: "player",
    load: "idle",
    logLevel: APP_CONFIG.REACT_APP_PLAYER_DEBUG ? "debug" : "warn",
    muted: false,
    playsInline: true,
    crossOrigin: true,
    preload: "auto",
    artist: "Lemonlight",
    aspectRatio: "16/9",
    loop: false,
    onContextMenu: (event) => {
      // disable context menu with download
      event.preventDefault();

      return false;
    },
  },
  layoutConfig: {
    colorScheme: "dark",
    icons: defaultLayoutIcons,
    download: false,
    showTooltipDelay: 500,
    showMenuDelay: 0,
    hideQualityBitrate: true,
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.5, 3],
    menuGroup: "bottom",
    noAudioGain: false,
    noKeyboardAnimations: false,
    seekStep: 5,
    // small layout may not be compatible with comments
    smallLayoutWhen: false,
    slots: {
      downloadButton: null,
    },
    children: [],
  },
  styleOverrides: [],
  pluginsConfig: {},
};

const pluginsConfigMap = {
  [VideoPlayerPlugins.Comments]: withVideoCommentsPlugin,
  [VideoPlayerPlugins.ControlsBelowPlayer]: withControlsBelowPlayerPlugin,
} satisfies Record<VideoPlayerPlugins, unknown>;

export function useVideoPlayerConfig(
  config: VideoPlayerConfig,
): BaseVideoPlayerConfig {
  const mergedConfig = useMemo<BaseVideoPlayerConfig>(() => {
    let temp = merge(
      {},
      videoPlayerDefaultConfig,
      config,
    ) as BaseVideoPlayerConfig;

    if (config.pluginsConfig) {
      typedEntries(config.pluginsConfig).forEach(([plugin, pluginConfig]) => {
        if (!pluginConfig) {
          return;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        temp = pluginsConfigMap[plugin](temp, pluginConfig as any);
      });
    }

    return temp;
  }, [config]);

  return mergedConfig;
}
