import type { PropsWithChildren } from "react";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useGetActiveUserSettings } from "@ll-web/features/auth/userSettings/async/useUserSettingsQueries";

// This guard ensures that user settings are loaded on first render if possible
// Default values are used when there is no active user or in case of an error
export const UserSettingsGuard = ({ children }: PropsWithChildren) => {
  const query = useGetActiveUserSettings();

  // Wait for the query to be enabled and loaded
  if (query.isPending && query.fetchStatus !== "idle") {
    return <SuspenseLoader />;
  }

  return children;
};
