import { ActivityType } from "@ll-web/core/analytics/events";
import { VideoReviewNotificationType } from "@ll-web/features/postProductionMedia/pages/VideoReview/components/VideoReviewApprovalMenu/useVideoReviewApproveFormData";
import { CallSheetReviewNotificationType } from "@ll-web/features/projectSpace/pages/CallSheets/callSheetReviewConsts";
import { CreativeDeckReviewNotificationType } from "@ll-web/features/projectSpace/pages/CreativeDeck/creativeDeckReviewConsts";

import {
  CreationNotificationType,
  ReviewNotificationType,
  type ToastContents,
} from "./types";

// eslint-disable-next-line complexity
export const notifyReviewToastMessagesMap = (
  reviewNotificationType:
    | CreationNotificationType
    | ReviewNotificationType
    | CreativeDeckReviewNotificationType
    | CallSheetReviewNotificationType
    | VideoReviewNotificationType,
): ToastContents => {
  switch (reviewNotificationType) {
    case CreationNotificationType.ContractCreated:
    case CreationNotificationType.LegacyFlowProjectCreated:
      return {
        pending: "Sending email notification...",
        success: "Email notification sent!",
      };
    case ReviewNotificationType.CreativeBriefInternal:
    case ReviewNotificationType.WizardGenerateExternalLegacy:
    case ReviewNotificationType.WizardGenerateExternal:
    case ReviewNotificationType.WizardGenerateInternal:
      return {
        pending: "Submitting Creative Brief...",
        success: "Creative Brief submitted!",
      };
    case ReviewNotificationType.CreativeDeckEditExternal:
    case ReviewNotificationType.CreativeDeckEditInternal:
      return {
        pending: "Submitting changes to Creative Deck...",
      };
    case ReviewNotificationType.CallSheetEditExternal:
    case ReviewNotificationType.CallSheetEditInternal:
      return {
        pending: "Submitting changes to Call Sheet...",
      };
    case CreativeDeckReviewNotificationType.ApproveExternal:
      return {
        pending: "Approving Creative Deck...",
      };
    case CreativeDeckReviewNotificationType.SubmitForReviewExternal:
      return {
        pending: "Submitting Creative Deck...",
        success: "Creative Deck Submitted!",
      };
    case CreativeDeckReviewNotificationType.ApproveInternal:
      return {
        pending: "Approving Creative Deck...",
      };
    case CreativeDeckReviewNotificationType.SubmitForReviewInternal:
      return {
        pending: "Notifying Client for review...",
        success: "Review notification has been sent!",
      };
    case CallSheetReviewNotificationType.ApproveExternal:
      return {
        pending: "Approving Call Sheet...",
      };
    case CallSheetReviewNotificationType.SubmitForReviewExternal:
      return {
        pending: "Submitting Call Sheet...",
        success: "Call Sheet Submitted!",
      };
    case CallSheetReviewNotificationType.ApproveInternal:
      return {
        pending: "Approving Call Sheet...",
      };
    case CallSheetReviewNotificationType.SubmitForReviewInternal:
      return {
        pending: "Notifying Client for review...",
        success: "Review notification has been sent!",
      };
    case VideoReviewNotificationType.ClientReview:
      // Toasts are handled internally in the video review functionality due to variables used there
      return {};
    case VideoReviewNotificationType.InternalReview:
      // Toasts are handled internally in the video review functionality due to variables used there
      return {};
  }
};

export const notifyReviewAnalyticsEventsMap: Record<
  | CreationNotificationType
  | ReviewNotificationType
  | CreativeDeckReviewNotificationType
  | CallSheetReviewNotificationType
  | VideoReviewNotificationType,
  { submitting: ActivityType; cancel: ActivityType } | undefined
> = {
  [CreationNotificationType.ContractCreated]: undefined,
  [CreationNotificationType.LegacyFlowProjectCreated]: undefined,
  [ReviewNotificationType.CreativeBriefInternal]: {
    submitting: ActivityType.CreativeBriefInputClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefInputCanceledSubmittingForReview,
  },
  [ReviewNotificationType.WizardGenerateInternal]: {
    submitting: ActivityType.CreativeBriefClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefCanceledSubmittingForReview,
  },
  [ReviewNotificationType.WizardGenerateExternalLegacy]: {
    submitting: ActivityType.CreativeBriefClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefCanceledSubmittingForReview,
  },
  [ReviewNotificationType.WizardGenerateExternal]: {
    submitting: ActivityType.CreativeBriefClickedSubmitForReview,
    cancel: ActivityType.CreativeBriefCanceledSubmittingForReview,
  },
  [ReviewNotificationType.CreativeDeckEditInternal]: undefined,
  [ReviewNotificationType.CreativeDeckEditExternal]: undefined,
  [ReviewNotificationType.CallSheetEditInternal]: undefined,
  [ReviewNotificationType.CallSheetEditExternal]: undefined,
  [CreativeDeckReviewNotificationType.ApproveExternal]: {
    submitting: ActivityType.PageViewApprovedPage,
    cancel: ActivityType.PageViewCanceledApprovingPage,
  },
  [CreativeDeckReviewNotificationType.SubmitForReviewExternal]: {
    submitting: ActivityType.PageViewSubmittedForReview,
    cancel: ActivityType.PageViewCanceledSubmittingForReview,
  },
  [CreativeDeckReviewNotificationType.ApproveInternal]: {
    submitting: ActivityType.PageViewApprovedPage,
    cancel: ActivityType.PageViewCanceledApprovingPage,
  },
  [CreativeDeckReviewNotificationType.SubmitForReviewInternal]: {
    submitting: ActivityType.PageViewSubmittedForReview,
    cancel: ActivityType.PageViewCanceledSubmittingForReview,
  },
  [CallSheetReviewNotificationType.ApproveExternal]: {
    submitting: ActivityType.PageViewApprovedPage,
    cancel: ActivityType.PageViewCanceledApprovingPage,
  },
  [CallSheetReviewNotificationType.SubmitForReviewExternal]: {
    submitting: ActivityType.PageViewSubmittedForReview,
    cancel: ActivityType.PageViewCanceledSubmittingForReview,
  },
  [CallSheetReviewNotificationType.ApproveInternal]: {
    submitting: ActivityType.PageViewApprovedPageForClient,
    cancel: ActivityType.PageViewCanceledApprovingPageForClient,
  },
  [CallSheetReviewNotificationType.SubmitForReviewInternal]: {
    submitting: ActivityType.PageViewSubmittedForReview,
    cancel: ActivityType.PageViewCanceledSubmittingForReview,
  },
  [VideoReviewNotificationType.ClientReview]: {
    submitting: ActivityType.VideoReviewSentVideoToClient,
    cancel: ActivityType.VideoReviewCanceledSendingToClient,
  },
  [VideoReviewNotificationType.InternalReview]: {
    submitting: ActivityType.VideoReviewSharedVideoInternally,
    cancel: ActivityType.VideoReviewCancelledSharingVideoInternally,
  },
};

export const notifyReviewModalTitleMap: Record<
  | CreationNotificationType
  | ReviewNotificationType
  | CreativeDeckReviewNotificationType
  | CallSheetReviewNotificationType
  | VideoReviewNotificationType,
  string
> = {
  [CreationNotificationType.ContractCreated]: "Notify Client Setup",
  [CreationNotificationType.LegacyFlowProjectCreated]: "Notify Client Setup",
  [ReviewNotificationType.CreativeBriefInternal]: "Notify Producer for Review",
  [ReviewNotificationType.WizardGenerateInternal]: "Notify Client for Review",
  [ReviewNotificationType.WizardGenerateExternalLegacy]:
    "Notify Producer for Review",
  [ReviewNotificationType.WizardGenerateExternal]: "Notify Producer for Review",
  [ReviewNotificationType.CreativeDeckEditInternal]: "Notify Client for Review",
  [ReviewNotificationType.CreativeDeckEditExternal]:
    "Notify Producer for Review",
  [CreativeDeckReviewNotificationType.ApproveExternal]: "Confirm Creative Deck",
  [CreativeDeckReviewNotificationType.SubmitForReviewExternal]:
    "Submit for Review",
  [ReviewNotificationType.CallSheetEditInternal]: "Notify Client for Review",
  [ReviewNotificationType.CallSheetEditExternal]: "Notify Producer for Review",
  [CreativeDeckReviewNotificationType.ApproveInternal]: "Approve for Client",
  [CreativeDeckReviewNotificationType.SubmitForReviewInternal]:
    "Notify Client for Review",
  [CallSheetReviewNotificationType.ApproveExternal]: "Confirm Call Sheet",
  [CallSheetReviewNotificationType.SubmitForReviewExternal]:
    "Submit for Review",
  [CallSheetReviewNotificationType.ApproveInternal]: "Approve for client",
  [CallSheetReviewNotificationType.SubmitForReviewInternal]:
    "Notify Client for Review",
  [VideoReviewNotificationType.ClientReview]: "Send Video to Client",
  [VideoReviewNotificationType.InternalReview]: "Notify for Internal Review",
};
const WIZARD_BASIC_NOTIFICATION_MODAL_TEXT =
  "You're about to submit for review. Please confirm that all necessary edits have been made.";

export const notifyReviewModalContentMap: Record<
  | ReviewNotificationType
  | CreativeDeckReviewNotificationType
  | CallSheetReviewNotificationType
  | VideoReviewNotificationType
  | CreationNotificationType,
  string
> = {
  [CreationNotificationType.ContractCreated]:
    "You're about to submit this contract for the client to get started. Please confirm that all necessary edits have been made.",
  [CreationNotificationType.LegacyFlowProjectCreated]:
    "You're about to submit this project for the client to get started. Please confirm that all necessary edits have been made.",
  [ReviewNotificationType.CreativeBriefInternal]: "",
  [ReviewNotificationType.WizardGenerateInternal]:
    WIZARD_BASIC_NOTIFICATION_MODAL_TEXT,
  [ReviewNotificationType.WizardGenerateExternalLegacy]:
    WIZARD_BASIC_NOTIFICATION_MODAL_TEXT,
  [ReviewNotificationType.WizardGenerateExternal]: "",
  [ReviewNotificationType.CreativeDeckEditInternal]:
    WIZARD_BASIC_NOTIFICATION_MODAL_TEXT,
  [ReviewNotificationType.CreativeDeckEditExternal]:
    WIZARD_BASIC_NOTIFICATION_MODAL_TEXT,
  [ReviewNotificationType.CallSheetEditExternal]:
    WIZARD_BASIC_NOTIFICATION_MODAL_TEXT,
  [ReviewNotificationType.CallSheetEditInternal]:
    WIZARD_BASIC_NOTIFICATION_MODAL_TEXT,
  [CreativeDeckReviewNotificationType.ApproveExternal]:
    "Are you sure you want to approve the creative deck? Once approved, no further changes can be made. Please confirm to proceed.",
  [CreativeDeckReviewNotificationType.SubmitForReviewExternal]:
    "You're about to submit for review. Please confirm that all necessary edits have been made.",
  [CreativeDeckReviewNotificationType.ApproveInternal]:
    "You are about to approve the project on behalf of the client. Please confirm that the client has given verbal or written approval via email or during a meeting.",
  [CreativeDeckReviewNotificationType.SubmitForReviewInternal]:
    "You're about to submit the client for a review. Please confirm that all necessary edits have been made.",
  [CallSheetReviewNotificationType.ApproveExternal]:
    "Are you sure you want to approve the call sheet? Once approved, no further changes can be made. Please confirm to proceed.",
  [CallSheetReviewNotificationType.SubmitForReviewExternal]:
    "You're about to submit for review. Please confirm that all necessary edits have been made.",
  [CallSheetReviewNotificationType.ApproveInternal]:
    "You are about to approve the project on behalf of the client. Please confirm that the client has given verbal or written approval via email or during a meeting.",
  [CallSheetReviewNotificationType.SubmitForReviewInternal]:
    "You're about to submit the client for a review. Please confirm that all necessary edits have been made.",
  [VideoReviewNotificationType.ClientReview]:
    "You're about to send the Video to the client for their review and feedback. Ensure all details are finalized before sending.",
  [VideoReviewNotificationType.InternalReview]:
    "You're about to submit for a review. Plaese confirm that all necessary edits have been made.",
};

export const BLOCKING_PHRASES = {
  hubspotLink: {
    text: "[ADD YOUR HUBSPOT LINK]",
    errorMessage:
      "Please replace the placeholder [ADD YOUR HUBSPOT LINK] with a valid link or remove it before submitting.",
  },
  date: {
    text: "[DATE]",
    errorMessage:
      "Please replace the placeholder [DATE] with a valid date or remove the text before submitting.",
  },
} as const;
