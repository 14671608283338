import { useActiveCreator } from "@ll-web/creator/auth/hooks/useActiveCreator";
import {
  CreatorAvatar,
  type CreatorAvatarProps,
} from "@ll-web/creator/components/CreatorAvatar";

export type ActiveCreatorAvatarProps = Omit<CreatorAvatarProps, "creator">;

export const ActiveCreatorAvatar = ({ ...props }: ActiveCreatorAvatarProps) => {
  const { activeCreator } = useActiveCreator();

  if (!activeCreator) {
    return null;
  }

  return <CreatorAvatar {...props} creator={activeCreator} />;
};
