import { INTERNAL_EMAIL_DOMAIN } from "@ll-web/consts/lemonlight";
import { httpClient } from "@ll-web/core/api/HttpClient";
import type { User } from "@ll-web/features/auth/types";
import { isUserInternal } from "@ll-web/features/auth/utils/isInternal";
import type {
  CreateUser,
  GetUserByIdArgs,
  UpdateUserDto,
  UsersFindAllQueryDto,
} from "@ll-web/features/users/types";
import type { ByIdParams } from "@ll-web/utils/types/types";

class UsersService {
  async list(args: UsersFindAllQueryDto): Promise<User[]> {
    return await httpClient.unwrappedHttpRequest<User[]>({
      config: {
        method: "GET",
        url: "/v1/users",
        params: args,
      },
    });
  }

  async create(args: CreateUser): Promise<User> {
    if (args.email.endsWith(INTERNAL_EMAIL_DOMAIN) && !isUserInternal(args)) {
      throw new Error(
        `External users cannot be created with a @${INTERNAL_EMAIL_DOMAIN} email address`,
      );
    }

    return await httpClient.unwrappedHttpRequest<User>({
      config: {
        method: "POST",
        url: "/v1/users",
        data: {
          ...args,
          phoneNumber: args.phoneNumber || undefined,
        },
      },
    });
  }

  async getById(args: GetUserByIdArgs): Promise<User> {
    return await httpClient.unwrappedHttpRequest<User>({
      config: {
        method: "GET",
        url: `/v1/users/${args.userId}`,
      },
    });
  }

  async updateById({
    id,
    ...payload
  }: ByIdParams & UpdateUserDto): Promise<User> {
    return await httpClient.unwrappedHttpRequest<User>({
      config: {
        method: "PATCH",
        url: `/v1/users/${id}`,
        data: payload,
      },
    });
  }
}

export const usersService = new UsersService();
