import { MAX_BACKEND_TIMEOUT } from "@ll-web/core/api/consts";
import { httpClient } from "@ll-web/core/api/HttpClient";
import type { GetCreativeDeckPdfDto } from "@ll-web/features/productionDocs/creativeDeck.types";
import type { GetDeliverableSummaryPdfDto } from "@ll-web/features/productionDocs/deliverable-summary-document.data";
import type {
  BlobWithFilename,
  GetProjectCallSheetsPdfDto,
  NotifyProjectFinalizationDto,
} from "@ll-web/features/productionDocs/types";

class ProductionDocsService {
  async notifyProjectFinalization(args: NotifyProjectFinalizationDto) {
    await httpClient.unwrappedHttpRequest<void>({
      config: {
        method: "POST",
        url: `/v1/projects/${args.projectId}/notifications/finalize`,
      },
    });
  }

  async getProjectCallSheetsPdf(
    args: GetProjectCallSheetsPdfDto,
  ): Promise<BlobWithFilename> {
    const response = await httpClient.httpRequest<Blob>({
      config: {
        method: "GET",
        url: `/v1/projects/${args.projectId}/productions/${args.productionId}/call-sheets`,
        responseType: "blob",
        timeout: 14000,
      },
    });

    return {
      data: response.data,
      filename: httpClient.getFilenameFromResponse(response),
    };
  }

  async getProjectCreativeDeckPdf(
    args: GetCreativeDeckPdfDto,
  ): Promise<BlobWithFilename> {
    const response = await httpClient.httpRequest<Blob>({
      config: {
        data: args,
        method: "POST",
        url: "/v1/projects/creative-deck",
        responseType: "blob",
        timeout: MAX_BACKEND_TIMEOUT,
      },
    });

    return {
      data: response.data,
      filename: httpClient.getFilenameFromResponse(response),
    };
  }

  async getProjectDeliverableSummaryPdf(
    args: GetDeliverableSummaryPdfDto,
  ): Promise<BlobWithFilename> {
    const response = await httpClient.httpRequest<Blob>({
      config: {
        data: args,
        method: "POST",
        url: "/v1/projects/deliverable-summary",
        responseType: "blob",
        timeout: MAX_BACKEND_TIMEOUT,
      },
    });

    return {
      data: response.data,
      filename: httpClient.getFilenameFromResponse(response),
    };
  }
}

export const productionDocsService = new ProductionDocsService();
