import { useEffect } from "react";

import Smartlook from "smartlook-client";

import { APP_CONFIG } from "@ll-web/config/app.config";
import { analyticsDebugLog } from "@ll-web/core/analytics/activityTracker";
import { useAnyUser } from "@ll-web/core/hooks/useAnyUser";

export const useSmartlook = () => {
  const activeUser = useAnyUser();

  useEffect(() => {
    const key = APP_CONFIG.REACT_APP_SMARTLOOK_KEY;
    if (!key) {
      return;
    }
    analyticsDebugLog(`Initializing Smartlook`);
    Smartlook.init(key);
  }, []);

  useEffect(() => {
    if (!Smartlook.initialized()) {
      return;
    }

    if (activeUser) {
      const { id: _id, email, accountType, isActive } = activeUser;
      analyticsDebugLog(`Identifying user ${_id} ${email} in Smartlook`);
      Smartlook.identify(_id, { email, accountType, isActive });
    } else {
      analyticsDebugLog(`Deidentifying user in Smartlook`);
      Smartlook.anonymize();
    }
  }, [activeUser]);
};
