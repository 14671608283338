import { useMemo } from "react";

import type { ProjectComment } from "@ll-web/features/projectComments/types";
import { isCommentFoundingThread } from "@ll-web/features/projectComments/utils/isCommentFoundingThread";

type UseFilterCommentThreadsArgs = {
  comments: ProjectComment[] | null | undefined;
  commentRelevantFilter?: (comment: ProjectComment) => boolean;
};

export const useFilterCommentThreads = ({
  comments,
  commentRelevantFilter,
}: UseFilterCommentThreadsArgs) => {
  const threads = useMemo(() => {
    return comments?.filter(
      (comment) =>
        !comment.isDeleted &&
        isCommentFoundingThread(comment) &&
        (!commentRelevantFilter || commentRelevantFilter(comment)),
    );
  }, [comments, commentRelevantFilter]);

  return threads;
};
